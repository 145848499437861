<template>
  <div id="goods-list">
    <van-nav-bar :title="activityDetail.title" left-arrow @click-left="onClickLeft" />
    <van-overlay :z-index="2" :show="shareShow" @click="shareShow = false">
      <canvas :height="shareSet.height" :width="shareSet.width" id="shareCanvas"></canvas>
      <div class="share_box">
        <img :src="shareSet.imgData" :height="shareSet.height" :width="shareSet.width" />
        <div class="save_tips">长按图片保存到手机</div>
      </div>
    </van-overlay>

    <div class="list_content">
      <div v-if="!isActivityStart" class="activityEnd">活动已结束</div>
      <div v-else class="activityNoEnd">
        <div class="time_title">{{ isActivityStart ? '距活动结束仅剩' : '距活动开始还有' }}</div>
        <div class="countdown">
          <div class="countdown_hour">{{ countdown.h }}</div>
          <span>:</span>
          <div class="countdown_min">{{ countdown.m }}</div>
          <span>:</span>
          <div class="countdown_sec">{{ countdown.s }}</div>
        </div>
      </div>
      <div class="banner_box">
        <div @click="shareActivity" class="share_img">
          <img src="@/assets/shareActivity.png" alt />
        </div>
        <img class="banner_img" @load="loadBannerImg" :src="activityDetail.banner_image_url" alt />
      </div>

      <div v-if="activityDetail.description" class="content_descirpt">{{ activityDetail.description }}</div>
      <div v-if="categoryList.length > 0" class="category_list">
        <div
          :class="item.isClick ? 'item_click category_item' : 'category_item'"
          v-for="item in categoryList"
          :key="item.id"
          @click="clickCategory(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="products_list">
        <div class="product_item" v-for="item in activityDetail.products" :key="item.id" @click="goGoodDetail(item)">
          <div
            :class="
              item.skus[0].time_limit_discount && item.skus[0].time_limit_discount.stock == 0
                ? 'sellOut imgBox'
                : 'imgBox'
            "
          >
            <img :src="item.image_url + '?x-oss-process=image/resize,w_320,h_320'" alt />
          </div>
          <div class="product_msg">
            <div class="product_title">{{ item.title }}</div>
            <div class="product_price">
              ¥ {{ item.time_limit_discount.price }}
              <span v-if="activityDetail.label" class="s-triangle">{{ activityDetail.label }}</span>
            </div>
            <div>
              <s>￥{{ item.price }}</s>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { timeLimitDetail } from '@/services/activity'
import { categoriesOwnGoods } from '@/services/goods'
import { backOrHome } from '@/services/navigator'
import { checkAuthentication } from '@/services/auth'
import { shareFlag, getNewUserId, setShareUserId } from '@/services/share'
import { getInfo } from '@/services/me'
import wechat from '../../utils/wechat'
import { Toast } from 'vant'
import { Encoder } from '@nuintun/qrcode'
import { createTime } from '@/utils/convert.js'

export default {
  data: function () {
    return {
      company_id: 1,
      id: null,
      activityDetail: {
        title: '',
        banner_image_url: '',
        description: '',
        products: [],
        ended_at: '',
      },
      categoryList: [],
      shareShow: false,
      shareSet: {
        width: 0,
        height: 0,
        imgData: '',
      },
      currentUser: {
        id: null,
      },
      countdown: {},
    }
  },
  mounted: function () {
    document.title = '活动详情'
    const { company_id, id } = this.$route.query
    this.company_id = company_id
    this.id = id
    // 获取活动详情
    this.getTimeLimitDetail()
    // 获取用户信息（用于分享
    if (checkAuthentication()) {
      this.requestUserInfo()
    }
    // 有分享者标识，则进行设置
    // let shareUserId = getNewUserId(document.URL);
    // if (shareUserId) {
    //   setShareUserId(shareUserId, document.URL);
    // }
  },
  methods: {
    onClickLeft() {
      backOrHome(this.company_id)
    },
    loadBannerImg() {
      // 分享设置
      const shareWidth = document.body.scrollWidth * 0.8
      const bannerImg_width = document.getElementsByClassName('banner_img')[0].width
      const bannerImg_height = document.getElementsByClassName('banner_img')[0].height
      const bgImageHeight = (bannerImg_height / bannerImg_width) * shareWidth
      console.log(document.getElementsByClassName('banner_img')[0])
      this.shareSet = {
        width: shareWidth,
        height: bgImageHeight + 215,
      }
    },
    getTimeLimitDetail() {
      timeLimitDetail(this.id).then(({ data }) => {
        this.activityDetail = data
        const product_ids = data.products.map((item) => item.id)
        this.getCategoryList(this.company_id, product_ids)
        this.configJSSDK()
        this.countTime(data)
      })
    },
    countTime(msg) {
      const { started_at, ended_at } = msg
      const startTime = createTime(started_at)
      const endTime = createTime(ended_at)
      let interval = setInterval(() => {
        // 判断活动是否开始
        const now = createTime()
        let difference = 0
        if (endTime - now > 0) {
          this.isDiscountBegin = true
          difference = endTime - now
        }
        if (startTime - now > 0) {
          this.isDiscountBegin = false
          difference = startTime - now
        }
        if (difference) {
          const hour = Math.floor(difference / 1000 / 60 / 60)
          const minute = Math.floor((difference / 1000 / 60) % 60)
          const second = Math.floor((difference / 1000) % 60)
          const obj = {
            h: hour < 10 ? '0' + hour : hour,
            m: minute < 10 ? '0' + minute : minute,
            s: second < 10 ? '0' + second : second,
          }
          this.countdown = obj
        } else {
          const obj = {
            h: '00',
            m: '00',
            s: '00',
          }
          this.countdown = obj
          clearInterval(interval)
        }
      }, 1000)
    },
    getCategoryList(company_id, product_ids) {
      categoriesOwnGoods(company_id, product_ids).then(({ data }) => {
        this.categoryList = [
          { id: null, name: '全部', isClick: true },
          ...data.map((item) => {
            return (item.isClick = false), item
          }),
        ]
      })
    },
    clickCategory(id) {
      this.categoryList = [
        ...this.categoryList.map((item) => {
          return (item.isClick = item.id == id ? true : false), item
        }),
      ]
      timeLimitDetail(this.id, id).then(({ data }) => {
        this.activityDetail = data
      })
    },
    requestUserInfo: async function () {
      let resp = await getInfo()
      this.currentUser = resp.data
    },
    goGoodDetail(product) {
      this.$router.push(
        `/goods/${product.id}?company_id=${this.$route.query.company_id}&isCard=${
          product.type == 'card' ? 1 : 0
        }&isLimit=${
          (product.skus[0].time_limit_discount && product.skus[0].time_limit_discount.stock == 0) ||
          this.isActivityEnd ||
          !this.isActivityStart
            ? 0
            : 1
        }`
      )
    },
    shareActivity() {
      Toast.loading({
        message: '卡片加载中...',
        forbidClick: true,
      })
      // 容器
      const containerWidth = this.shareSet.width
      const containerHeight = this.shareSet.height
      // 背景图片宽度不等
      const bgImageWidth = this.shareSet.width
      const bgImageHeight = containerHeight - 215
      //   二维码
      const qrCodeWidth = containerWidth * 0.4
      const qrCodeHeight = qrCodeWidth

      const paddingLeft = 18
      const { banner_image_url, title, description } = this.activityDetail
      let shareImage = document.querySelector('#shareCanvas')
      if (shareImage.getContext) {
        var ctx = shareImage.getContext('2d')
        // 获得并设置缩放比例，解决高清屏幕绘制图像模糊的问题.
        var getPixelRatio = function (context) {
          var backingStore =
            context.backingStorePixelRatio ||
            context.webkitBackingStorePixelRatio ||
            context.mozBackingStorePixelRatio ||
            context.msBackingStorePixelRatio ||
            context.oBackingStorePixelRatio ||
            context.backingStorePixelRatio ||
            1
          return (window.devicePixelRatio || 1) / backingStore
        }
        var ratio = getPixelRatio(ctx)
        shareImage.style.width = containerWidth + 'px'
        shareImage.style.height = containerHeight + 'px'
        shareImage.width = containerWidth * ratio
        shareImage.height = containerHeight * ratio

        ctx.scale(ratio, ratio)

        // 绘制背景圆角矩形
        let x = 0
        let y = 0
        let w = containerWidth
        let h = containerHeight
        let r = 5

        ctx.fillStyle = 'white'
        ctx.beginPath()
        ctx.moveTo(x + r, y)
        ctx.arcTo(x + w, y, x + w, y + h, r)
        ctx.arcTo(x + w, y + h, x, y + h, r)
        ctx.arcTo(x, y + h, x, y, r)
        ctx.arcTo(x, y, x + w, y, r)
        ctx.closePath()
        ctx.fill()

        const sliceName = (name) => (name.length > 20 ? `${name.slice(0, 20)}...` : name)
        // 标题
        ctx.fillStyle = '#000'
        ctx.font = '13px Arial'
        ctx.fillText(sliceName(title), paddingLeft, bgImageHeight + 20)
        // 描述
        if (description) {
          ctx.fillStyle = '#4A4A4A'
          ctx.font = '12px Arial'
          ctx.fillText(sliceName(description), paddingLeft, bgImageHeight + 20 + 14 + 8)
        }
        // QRCode
        const _qrcode = new Encoder()
        const { protocol, host, pathname } = location
        let shareUserId = this.currentUser.id
        const url = shareUserId
          ? `${protocol}//${host + pathname}#/activity?company_id=${this.company_id}&id=${
              this.id
            }&${shareFlag}=${shareUserId}`
          : `${protocol}//${host + pathname}#/activity?company_id=${this.company_id}&id=${this.id}`
        _qrcode.write(url)
        _qrcode.make()

        let qrCode = new Image()
        qrCode.crossOrigin = 'Anonymous'
        qrCode.src = _qrcode.toDataURL(5, 0)
        qrCode.onload = () => {
          ctx.drawImage(qrCode, paddingLeft, bgImageHeight + 20 + 14 + 8 + 20, qrCodeWidth, qrCodeHeight)
        }

        // QrCode 右侧文字
        ctx.font = '14px Arial '
        ctx.fillText('长按识别二维码', paddingLeft + qrCodeWidth + 10, bgImageHeight + 20 + 14 + 8 + 20 + 50)
        ctx.font = '12px Arial '
        ctx.fillStyle = '#999'
        ctx.fillText('超值好货等你来', paddingLeft + qrCodeWidth + 10, bgImageHeight + 20 + 14 + 8 + 20 + 75)

        // 背景图
        let bgImage = new Image()
        bgImage.crossOrigin = 'Anonymous'
        bgImage.src = banner_image_url.replace('https', 'http')
        bgImage.onload = () => {
          ctx.drawImage(bgImage, 0, 0, bgImageWidth, bgImageHeight)
          setTimeout(() => {
            Toast.clear()
            this.shareSet.imgData = shareImage.toDataURL('image/png')
            this.shareShow = true
          }, 100)
        }
      }
    },
    configJSSDK: function () {
      if (typeof wx != 'undefined') {
        let base = `${document.location.protocol}//${document.location.host}`
        let url = `${base}/#/activity?company_id=${this.company_id}&id=${this.id}`
        let shareUserId = this.currentUser.id
        if (shareUserId) {
          url = `${base}/#/activity?company_id=${this.company_id}&id=${this.id}&${shareFlag}=${shareUserId}`
        }
        wechat.configJSSDK(document.URL).then(() => {
          wx.ready(() => {
            wechat.configAppMessageShareData(
              this.activityDetail.title,
              this.activityDetail.description,
              url,
              this.activityDetail.banner_image_url
            )
            wechat.configTimelineShareData(this.activityDetail.title, url, this.activityDetail.banner_image_url)
          })
        })
      }
    },
  },
  computed: {
    isActivityStart: function () {
      const data = this.activityDetail
      return data.started_at ? (createTime() > createTime(data.started_at) ? true : false) : true
    },
    isActivityEnd: function () {
      const data = this.activityDetail
      return createTime(data.ended_at) > createTime() ? false : true
    },
  },
}
</script>
<style scoped>
#goods-list {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
}
.banner_box {
  position: relative;
  margin-bottom: 21px;
}
.list_content {
  height: calc(100% - 46px);
  padding: 0 15px;
  overflow-y: auto;
}
.banner_img {
  width: 100%;
  border-radius: 5px;
}
.content_descirpt {
  font-size: 14px;
  color: rgba(74, 74, 74, 1);
  margin-bottom: 15px;
}
/* 分类列表 */
.category_list {
  display: flex;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 15px;
}
.category_item {
  padding: 2px 16px;
  background: rgba(247, 247, 247, 1);
  border-radius: 13px;
  color: #333333;
  font-size: 12px;
  margin-right: 10px;
  flex-shrink: 0;
}
.item_click {
  background: rgba(255, 238, 238, 1);
  border: 1px solid rgba(255, 68, 68, 1);
  font-size: 12px;
  color: rgba(255, 68, 68, 1);
}
/* 商品列表 */
.products_list {
  /* height: calc(100% - 255px);
  overflow-y: auto; */
}
.product_item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.product_item > .imgBox {
  position: relative;
  margin-right: 20px;
}
.product_item > .imgBox > img {
  width: 100px;
  height: 100px;
}
.product_item > .product_msg {
  flex: 1;
}
.product_item > .product_msg > .product_title {
  font-size: 14px;
  color: #333;
}
.product_item > .product_msg > .product_price {
  color: #ff373f;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
}
.product_item > .product_msg > div:last-child {
  color: #aaaaaa;
  font-size: 12px;
}

/* 三角标签 */
.s-triangle {
  display: inline-block;
  font-size: 10px;
  position: relative;
  color: #ff373f;
  border: 1px solid rgba(255, 55, 63, 1);
  border-radius: 2px;
  padding: 0 2px;
  margin-left: 9px;
}

/* .triangelTag {
  display: block;
  background-color: #ff2727;
  height: 16px;
  line-height: 16px;
  text-align: center;
  min-width: 32px;
  position: relative;
  margin-left: 5px;
}

.triangelTag:before {
  content: " ";
  width: 0px;
  height: 0px;
  position: absolute;
  top: 0;
  left: -16px;
  border: 8px solid transparent;
  border-right-color: #ff2727;
} */
/* 分享 */
.share_img {
  position: absolute;
  top: 10px;
  background: #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  right: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.3);
}
.share_img > img {
  width: 20px;
  height: 20px;
}
#shareCanvas {
  display: none;
}
.share_box {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.save_tips {
  font-size: 16px;
  color: rgba(220, 220, 220, 1);
  margin-top: 12px;
  text-align: center;
}
/* 活动结束 */
.activityEnd {
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
/* .activityEnd::before {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  background: #a2a2a2;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "活动已结束";
  z-index: 2;
}
.activityEnd::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  z-index: 1;
} */
/* 活动未结束 */
.activityNoEnd {
  color: #ff373f;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
}
.activityNoEnd > .time_title {
  margin-right: 8px;
  font-size: 16px;
  font-weight: 500;
}
.activityNoEnd > .countdown {
  display: flex;
  font-size: 12px;
}
.activityNoEnd > .countdown > div {
  padding: 1px;
  background: rgba(255, 55, 63, 1);
  border-radius: 2px;
  min-width: 17px;
  text-align: center;
  color: #fff;
}
.activityNoEnd > .countdown > span {
  margin: 0 3px;
}
/* 商品已抢完 */
.sellOut::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  border-radius: 50px;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  content: '已抢完';
}
</style>
