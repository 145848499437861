import http from '../utils/http'
import api from '../utils/api'

export function getInfo() {
  return http.get(api.me.info)
}

export function getMyAccounts(type = '', card_type = 'all') {
  return http.get(api.me.accounts(type, card_type))
}
