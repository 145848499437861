<template>
  <div id="goods_box">
    <div id="good-list" :style="{ height: isMiniProgram ? '100%' : 'calc(100% - 50px)' }">
      <van-nav-bar v-if="isMiniProgram" title="商品列表" />
      <van-nav-bar v-else title="商品列表" left-arrow @click-left="onClickLeft" />
      <van-search
        class="good-search"
        disabled
        placeholder="搜索店铺内商品"
        @click="showPopup"
        shape="round"
        :style="[{ width: isMiniProgram ? '100%' : '90%' }, { left: isMiniProgram ? '0' : '44px' }]"
      />
      <van-popup class="index_popup" v-model="searchPopupShow" position="top">
        <div class="popup_box">
          <div class="popup_header">
            <van-icon class="popup_icon" @click="closePopup" size="24" color="#333" name="arrow-left" />
            <van-search class="popup_search" placeholder="搜索店铺内商品" v-model="keywords" shape="round" show-action>
              <div slot="action" @click="onSearch">搜索</div>
            </van-search>
          </div>
          <div class="popup_content">
            <div @click="toGood(item)" class="content_item" v-for="item in search_goods" :key="item.id">
              <img :src="item.image_url" :alt="item.title" />
              <div class="good_msg">
                <div>{{ item.title }}</div>
                <span>¥{{ item.price }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-popup>
      <header>
        <div id="category" class="row">
          <div v-if="!collapseActive" class="goods-top">
            <div class="bubble-container">
              <!-- <ul>
              <li
                v-on:click="changeCategory(item.id)"
                class="bubble"
                :class="{'active-bubble': category_id == item.id}"
                v-for="item in categories"
                :key="item.id"
              >{{ item.name }}</li>
              </ul>-->
              <van-tabs :border="false" line-width="14" @click="tabClick" title-inactive-color="#333">
                <van-tab name="overall" title="综合"></van-tab>
                <van-tab name="created_at" title="新品"></van-tab>
                <van-tab name="sold_count_asc" title="销量"></van-tab>
                <van-tab name="price_asc">
                  <template #title>
                    <div :class="price_top ? 'price_tab price_top' : 'price_tab price_bottom'">价格</div>
                  </template>
                </van-tab>
              </van-tabs>
            </div>
          </div>
          <div @click="filterClick" :class="category_name ? 'goods-filter active' : 'goods-filter'">
            <div>{{ category_name || '筛选' }}</div>
            <img :src="category_name ? imgs.filter_activeImg : imgs.filterImg" alt />
          </div>
          <van-popup get-container="body" v-model="popupShow" position="right" class="filter_popup">
            <div class="filter_content">
              <div class="content_title">价格区间</div>
              <div class="categories_filter">
                <input type="number" placeholder="最低价" v-model="minPrice" />
                <span></span>
                <input type="number" placeholder="最高价" v-model="maxPrice" />
              </div>
              <div class="content_title" style="margin-top: 39px">种类</div>
              <div class="categories_filter">
                <div
                  :class="{ item_active: category_ids.includes(item.id) }"
                  v-on:click="changeCategory(item.id)"
                  v-for="item in categories"
                  :key="item.id"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="content_title" style="margin-top: 39px">品牌</div>
              <div class="categories_filter">
                <div
                  :class="{ item_active: brand_ids.includes(item.id) }"
                  @click="changeBrand(item.id)"
                  v-for="item in brands"
                  :key="item.id"
                >
                  {{ item.name_zh }}
                </div>
              </div>
            </div>
            <div class="filter_footer">
              <van-button class="clear_btn" type="default" @click="clear">清空</van-button>
              <van-button class="submit_btn" type="danger" @click="submit_filter">确认</van-button>
            </div>
          </van-popup>

          <!-- <van-collapse class="good-select" v-model="activeNames" :border="false" accordion>
          <van-collapse-item title="全部类目" name="1">
            <ul class="collapse-ul">
              <li
                v-on:click="changeCategory(item.id)"
                class="collapse-bubble"
                :class="{'active-bubble': category_id == item.id}"
                v-for="item in categories"
                :key="item.id"
              >{{ item.name }}</li>
            </ul>
          </van-collapse-item>
          </van-collapse>-->
        </div>

        <!--
      <div v-on:click="onClickBrand" id="brand" class="row wrap" v-if="singleBrandMode">
        <label class="left">品牌</label>
        <label class="right">
          <img id="brand-logo" :src="brand.logo_url" :alt="brand.name_zh" />
          <span id="brand-name">{{ brand.name_zh }}</span>
          <i id="brand-navigator" class="van-icon van-icon-arrow van-cell__right-icon"></i>
        </label>
      </div>

      <div class="company-container" v-else>
        <ul>
          <li
            @click="changeBrand(item.id)"
            :class="{'active-company': brand_ids.includes(item.id)}"
            v-for="item in brands"
            :key="item.id"
          >
            <img
              :src="item.logo_url+ '?x-oss-process=image/resize,w_100,h_100'"
              :alt="item.name_zh"
            />
            <h6>{{ item.name_zh }}</h6>
          </li>
        </ul>
        </div>-->
      </header>
      <div id="goods-container">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="已全部加载"
          @load="onLoad"
          :offset="100"
          :immediate-check="false"
        >
          <div class="good-container" v-for="(item, index) in goods" :key="item.id">
            <div class="left">
              <img v-on:click="toGood(item)" :src="item.image_url" :alt="item.title" />
            </div>
            <div class="right">
              <h2 v-on:click="toGood(item)">{{ item.title }}</h2>
              <h3 v-on:click="toGood(item)">{{ item.memo }}</h3>
              <div class="tags">
                <span class="tag" v-for="tag in item.tags" :key="tag">{{ tag }}</span>
              </div>
              <div class="price_cart_row">
                <h4 v-on:click="toGood(item)">
                  <span>
                    ¥ {{ item.price }}
                    <span v-if="item.time_limit_discount && item.time_limit_discount.label" class="s-triangle">{{
                      item.time_limit_discount.label
                    }}</span>
                  </span>
                </h4>
                <van-button
                  v-if="isCard == 0"
                  class="addcart"
                  @click="addCart(index)"
                  plain
                  hairline
                  size="small"
                  type="danger"
                  >加入购物车</van-button
                >
              </div>
            </div>
            <div class="clear"></div>
            <van-divider />
          </div>
        </van-list>
      </div>
    </div>
    <Footer active="goods" :company_id="parseInt(company_id)" />
  </div>
</template>
<script>
import filter_activeImg from '@/assets/filter_active.png'
import filterImg from '@/assets/filter.png'
import { brandList, brandDetail, categoriesOwnGoods, goodsList, getCards } from '../../services/goods'
import { addCart } from '../../services/cart'
import { toastHttpUnKnownError } from '../../utils/errors'
import { Toast } from 'vant'
import { backOrHome } from '../../services/navigator'
import { createTime } from '@/utils/convert'
import wechat from '@/utils/wechat'

import Footer from '../Footer'
export default {
  data: function () {
    return {
      price_top: true,
      loading: false,
      finished: false,
      brand_id: null, // 用于标识只显示单一品牌
      brand_ids: [],
      category_ids: [],
      page: 1,
      lastPage: 1,
      brands: [],
      categories: [],
      goods: [],
      company_id: 1,
      brand: {},
      activeNames: 2,
      collapseActive: false,
      keywords: '',
      searchPopupShow: false,
      popupShow: false,
      search_goods: [],
      isCard: 0,
      imgs: {
        filterImg: '',
        filter_activeImg: '',
      },
      category_name: '',
      order: '',
      isMiniProgram: false,
      minPrice: null,
      maxPrice: null,
    }
  },
  components: {
    Footer,
  },
  mounted: function () {
    this.imgs = {
      filterImg,
      filter_activeImg,
    }
    this.company_id = this.$route.query.company_id

    if (this.company_id == null) {
      this.$router.push('/errors/404')
      return
    }

    this.isCard = this.$route.query.isCard
    this.requestForCategory()
    this.brand_id = this.$route.query.brand_id || null
    this.brand_ids = this.singleBrandMode
      ? [this.brand_id]
      : this.$route.query.brand_id
      ? [...this.$route.query.brand_id]
      : []

    if (this.singleBrandMode) {
      brandDetail(this.brand_id)
        .then((resp) => {
          this.brand = resp.data
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    } else {
      this.requestForBrands()
    }

    this.requestForList()
    wechat.checkIsMiniApp().then(
      (res) => (this.isMiniProgram = res),
      (rej) => ''
    )
  },
  methods: {
    onClickLeft() {
      backOrHome(this.company_id)
    },
    requestForBrands: function () {
      brandList(this.company_id)
        .then((resp) => {
          this.brands = [...this.brands, ...resp.data]
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    },
    requestForCategory: function () {
      categoriesOwnGoods(this.company_id)
        .then((resp) => {
          let items = []
          for (let [key, value] of Object.entries(resp.data)) {
            items.push(value)
          }
          this.categories = [...this.categories, ...items]
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    },
    requestForList: function (search = '') {
      if (this.isCard == 1) {
        getCards({
          company_id: this.company_id,
          brand_id: this.brand_ids,
          category_id: this.category_ids,
          page: this.page,
          order: this.order,
          search,
        }).then((res) => {
          this.getList(res, search)
        })
      } else {
        goodsList(this.company_id, this.brand_ids, this.category_ids, this.page, this.order, search)
          .then((res) => {
            this.getList(res, search)
          })
          .catch((error) => {
            toastHttpUnKnownError(error)
          })
      }
    },
    getList: function (resp, search) {
      this.loading = false
      resp.data.data.forEach((item) => {
        item.image_url = item.image_url + '?x-oss-process=image/resize,w_320,h_320'
        item.price =
          item.time_limit_discount && createTime(item.time_limit_discount.ended_at) > createTime()
            ? item.time_limit_discount.price
            : item.price
      })
      const goods = resp.data.data.filter((item) => {
        if (this.minPrice !== null) {
          if (this.maxPrice !== null) {
            return item.price <= parseInt(this.maxPrice) && item.price >= parseInt(this.minPrice)
          }
          return item.price > parseInt(this.minPrice)
        }
        return true
      })
      if (search == '') {
        this.goods = [...this.goods, ...goods]
        this.lastPage = resp.data.meta.last_page
      } else {
        this.search_goods = goods
      }
    },
    changeCategory: function (id) {
      // if (id === this.category_id) {
      //   this.category_id = null;
      // } else {
      //   this.category_id = id;
      // }
      let category_ids = this.category_ids
      if (category_ids.includes(id)) {
        let idx = category_ids.indexOf(id)
        category_ids.splice(idx, 1)
        this.category_ids = category_ids
      } else {
        this.category_ids.push(id)
      }
      // this.page = 1;
      // this.goods = [];
      this.activeNames = 2
      // this.requestForList();
    },
    changeBrand(id) {
      let brands_ids = this.brand_ids
      if (brands_ids.includes(id)) {
        let idx = brands_ids.indexOf(id)
        brands_ids.splice(idx, 1)
        this.brand_ids = brands_ids
      } else {
        this.brand_ids.push(id)
      }
      // this.requestForList();
    },
    toGood: function ({ id, type, time_limit_discount }) {
      const isLimit = time_limit_discount && createTime(time_limit_discount.ended_at) > createTime() ? 1 : 0
      let path =
        this.isCard == 1 || type == 'card'
          ? `/goods/${id}?company_id=${this.company_id}&isCard=1&isLimit=${isLimit}`
          : `/goods/${id}?company_id=${this.company_id}&isLimit=${isLimit}`
      this.$router.push(path)
    },
    onLoad: function () {
      this.loading = true
      if (this.page >= this.lastPage) {
        this.page = 1
        this.loading = false
        this.finished = true
        return
      }
      this.page++
      this.requestForList()
    },
    addCart(index) {
      let item = this.goods[index]
      addCart(item.skus[0].id, 1)
        .then((resp) => {
          Toast(resp.data.message)
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    },
    onClickBrand: function () {
      this.$router.push(`/goods/brand/${this.brand_id}`)
    },
    onSearch: function (e) {
      this.page = 1
      this.requestForList(this.keywords)
    },
    showPopup: function () {
      this.keywords = ''
      this.search_goods = []
      this.searchPopupShow = true
    },
    closePopup: function () {
      this.searchPopupShow = false
    },
    tabClick(name, title) {
      const resetGood = () => {
        this.page = 1
        this.finished = false
        this.goods = []
        this.activeNames = 2
        this.requestForList()
      }
      if (name == 'price_asc') {
        this.order = this.price_top ? 'price_desc' : name
        this.price_top = !this.price_top
        resetGood()
        return
      }
      this.price_top = true
      if (name == 'created_at') {
        this.order = 'id_desc'
        resetGood()
        return
      }
      if (name == 'overall') {
        this.order = ''
        resetGood()
        return
      }
      if (this.order !== name) {
        this.order = name
        resetGood()
      }
    },
    filterClick() {
      this.popupShow = true
    },
    submit_filter() {
      this.page = 1
      this.goods = []
      this.popupShow = false
      this.category_name =
        this.category_ids.length > 0 ? this.categories.filter((c) => this.category_ids.includes(c.id))[0].name : ''
      if (this.minPrice > this.maxPrice) {
        const maxPrice = this.minPrice
        this.minPrice = this.maxPrice
        this.maxPrice = maxPrice
      }
      this.requestForList()
    },
    clear() {
      this.category_ids = []
      this.brand_ids = []
      this.order = 'price_asc'
      this.minPrice = null
      this.maxPrice = null
      //  this.popupShow = false;
      // this.requestForList();
    },
  },
  computed: {
    singleBrandMode: function () {
      return this.brand_id > 0
    },
  },
}
</script>
<style scoped>
#goods_box {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
#good-list {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.good-search {
  background: rgb(255, 255, 255);
  position: absolute;
  top: 0;
  z-index: 999;
  padding: 7px 12px 0 12px;
}
.gutter {
  background-color: #f5f5f9;
  height: 20px;
}
/*
header {
  margin-top: 10px;
} */

header .row {
  height: 50px;
  width: 100%;
  align-content: center;
  margin-top: 10px;
}

header .row:first-child {
  margin-top: 0;
  position: relative;
}

#category {
  display: flex;
  position: relative;
  height: 45px;
  border-bottom: 1px solid #ebedf0;
}

header #category label {
  color: #363636;
  margin: 0 20px;
  height: 50px;
  line-height: 50px;
  float: left;
}
.goods-top {
  width: 70%;
  background: #fff;
  padding-left: 10px;
}
.good-select {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.goods-filter {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}
.goods-filter > img {
  width: 14px;
  height: 14px;
  margin-right: 22px;
  margin-left: 2px;
}
.filter_popup {
  height: 100vh;
  width: 80vw;
}
.filter_content {
  height: calc(100vh - 44px);
  padding: 20px 22px 0 22px;
  box-sizing: border-box;
  overflow-y: auto;
}

.content_title {
  font-size: 14px;
  color: #333;
  padding-left: 8px;
  margin-bottom: 21px;
  font-weight: 500;
}
.categories_filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.categories_filter > input {
  width: 40%;
  height: 30px;
  outline: none;
  border: 1px solid #f3f2f2;
  color: #aaa;
  font-size: 12px;
  text-align: center;
}
.categories_filter > span {
  display: block;
  width: 12px;
  height: 1px;
  background: #aaa;
  margin: 0 10px;
}
.categories_filter > div {
  min-width: 20vw;
  margin-right: 2vw;
  margin-bottom: 8px;
  height: 30px;
  background: rgba(243, 242, 242, 1);
  border-radius: 2px;
  border: 1px solid rgba(243, 242, 242, 1);
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #363636;
}
.categories_filter > .item_active {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(224, 32, 32, 1);
  color: #e02020;
}
.clear_btn {
  width: 35%;
}
.submit_btn {
  width: 65%;
}
.active {
  color: #df2f36;
}
header .wrap {
  margin: 0 20px;
  width: unset;
}

.row label {
  height: 50px;
  line-height: 50px;
  font-size: 12px;
}

.row .left {
  color: #363636;
  margin-right: 15px;
  float: left;
}

.row .right {
  color: #333333;
}

#brand-logo {
  margin-top: 7.5px;
  height: 35px;
  float: left;
}

#brand-name {
  float: left;
  margin-left: 10px;
}

#brand-navigator {
  float: right;
  height: 50px;
  line-height: 50px;
}

.bubble-container,
.company-container {
  overflow-x: auto;
  scrollbar-width: none;
}

.bubble-container ul {
  white-space: nowrap;
  margin-top: 10px;
}

.company-container {
  height: 120px;
  background: #f9f9f9;
}

.company-container ul {
  margin-top: 15px;
  white-space: nowrap;
}

.company-container li {
  height: 90px;
  width: 100px;
  background: white;
  display: inline-block;
  margin-right: 10px;
  overflow: hidden;
}

.company-container img {
  height: 50px;
  width: 50px;
  display: block;
  margin: 10px auto;
}

.company-container h6 {
  text-align: center;
  font-weight: lighter;
}

.active-company {
  border-bottom: 1px black solid;
  height: 89px !important;
}

.bubble {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  background-color: #f4f4f4;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 20px;
  font-weight: lighter;
}
.collapse-bubble {
  min-width: 20%;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  border-radius: 15px;
  border: 1px solid rgba(211, 211, 211, 1);
  color: #363636;
  margin-left: 4%;
  margin-bottom: 12px;
}
.collapse-ul {
  display: flex;
  flex-wrap: wrap;
}

.active-bubble {
  color: #fff;
  background-color: #ee0a24;
  border: none;
}

/* 商品列表 */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

#goods-container {
  margin-top: 20px;
}

.good-container {
  width: 90%;
  margin: 15px auto 0;
  clear: both;
}

.good-container img {
  width: 100px;
  height: 100px;
}

.good-container .left {
  float: left;
}

.good-container .right {
  margin-left: 10px;
  float: left;
  width: calc(100% - 110px);
}

.good-container h2 {
  color: #363636;
  font-size: 14px;
}

.good-container h3 {
  margin: 5px 0 0 0;
  color: #888888;
  font-size: 10px;
}

.good-container h4 {
  margin-top: 10px;
  color: #ee0a24;
  font-size: 20px;
  display: inline-block;
}

.good-container .tags {
  margin-top: 10px;
}

.good-container .tag {
  font-size: 10px;
  padding: 5px;
  color: #fff;
  background-color: #ee0a24;
  margin-right: 10px;
  border-radius: 3px;
}

.clear {
  clear: both;
}

.van-divider {
  margin: 10px 0;
}

.price_cart_row {
  margin-top: 10px;
}

.addcart {
  float: right;
}
/* 弹出框样式 */
.index_popup {
  height: 100%;
}
.popup_header {
  height: 60px;
  display: flex;
  align-items: center;
}
.popup_icon {
  width: 35px;
  text-align: center;
  height: 60px;
  line-height: 60px;
}
.popup_search {
  flex: 1;
}
.popup_search > .van-search__content {
  background: #f7f7f9;
}
.popup_search > .van-search__action {
  width: 48px;
  text-align: center;
  background: #df2f36;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 8px;
}
.popup_content {
  height: calc(100% - 60px);
  overflow-y: auto;
}
.popup_content > .content_item {
  display: flex;
  height: 110px;
  align-items: center;
  padding: 0 10px;
  border-top: 1px solid #e8e8e8;
}
.popup_content > .content_item > img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
.popup_content > .content_item > .good_msg {
  flex: 1;
  height: 110px;
  color: #000000;
  font-size: 13px;
  padding: 15px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.good_msg > span {
  color: #e80000;
  font-size: 15px;
  margin-bottom: 10px;
}

/* 价格箭头 */
.price_tab {
  position: relative;
}
.price_tab::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  top: 16px;
  right: 6px;
}
.price_tab::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  bottom: 16px;
  right: 6px;
}
.price_top::after {
  border-bottom: 4px solid #333;
}
.price_top::before {
  border-top: 4px solid #dedede;
}
.price_bottom::after {
  border-bottom: 4px solid #dedede;
}
.price_bottom::before {
  border-top: 4px solid #333;
}

/* 滑动 */
.popup_box {
  height: 100%;
}
/* 标签 */
.s-triangle {
  display: inline-block;
  font-size: 10px;
  position: relative;
  color: #ff373f;
  border: 1px solid rgba(255, 55, 63, 1);
  border-radius: 2px;
  padding: 0 2px;
  font-weight: 400;
  margin-left: 9px;
}
.good-container h4 > span {
  display: flex;
  align-items: center;
}
</style>
