<template>
  <div id="address-form">
    <van-nav-bar title="管理收货地址" left-arrow @click-left="onClickLeft" />
    <van-address-edit
      :area-list="areaList"
      show-search-result
      :show-delete="this.id > 0"
      :address-info="addressInfo"
      :area-columns-placeholder="['省', '市', '区']"
      :postal-validator="validatePostal"
      @save="onSave"
      @delete="onDelete"
    />
  </div>
</template>
<script>
import areaList from '../../utils/area'
import { location, address } from '../../utils/convert'
import { list, addAdress, updateAddress, removeAddress } from '../../services/address'
import { getInfo } from '@/services/me'
import { Toast } from 'vant'

export default {
  props: ['id'],
  data() {
    return {
      areaList,
      searchResult: [],
      addressInfo: {
        id: 110101,
        name: null,
        tel: null,
        province: null,
        city: null,
        county: null,
        addressDetail: null,
        areaCode: null,
        postalCode: null,
        isDefault: null,
        latitude: 0,
        longitude: 0,
      },
    }
  },
  mounted: function () {
    const { latitude = 0, longitude = 0 } = this.$route.query
    this.latitude = latitude
    this.longitude = longitude
    if (!this.isNew) {
      this.requestForData()
    } else {
      this.getLocation()
      this.getInfo()
    }
  },
  methods: {
    getInfo() {
      getInfo().then((resp) => {
        let result = resp.data
        this.addressInfo.tel = result.user.phone
        // this.initAdressInfo(result)
      })
    },
    // getAreaCode(district) {
    //   let countyCodes = Object.keys(areaList.county_list);
    //   return countyCodes.find(c => {
    //     return areaList.county_list[c].includes(district);
    //   });
    //   if (city) {
    //     let cityCodes = Object.keys(areaList.city_list);
    //     let a = cityCodes.filter(c => {
    //       return areaList.city_list[c].includes(city);
    //     });
    //     return a[0] || "";
    //   } else if (province) {
    //     let provinceCodes = Object.keys(areaList.province_list);
    //     let a = provinceCodes.filter(c => {
    //       return areaList.province_list[c].includes(province);
    //     });
    //     return a[0] || "";
    //   }
    // },
    onSave(form) {
      let formData = {
        province: form.province,
        city: form.city,
        district: form.county,
        address: form.addressDetail,
        contact_name: form.name,
        contact_phone: form.tel,
        city_code: form.areaCode,
      }
      if (this.isNew) {
        addAdress(formData)
          .then((resp) => {
            Toast('提交成功')
            this.$router.go(-1)
          })
          .catch((error) => {
            Toast(error.response.data.errors.district[0])
          })
      } else {
        updateAddress(this.id, formData)
          .then(() => {
            Toast('更新成功')
            this.$router.go(-1)
          })
          .catch((error) => {
            Toast(`未知错误，状态码 ${error.response.status}`)
          })
      }
    },
    onDelete() {
      removeAddress(this.id)
        .then(() => {
          Toast('已删除')
          this.$router.go(-1)
        })
        .catch((erorr) => {
          Toast(`未知错误，状态码 ${error.response.status}`)
        })
    },
    requestForData() {
      list().then((resp) => {
        let items = resp.data.filter((item) => {
          return item.id == this.id
        })
        if (items.length == 0) {
          Toast('当前的地址已删除，请重试.')
        } else {
          let item = items[0]
          this.addressInfo = {
            name: item.contact_name,
            tel: item.contact_phone,
            province: item.province,
            city: item.city,
            county: item.district,
            addressDetail: item.address,
            postalCode: item.zip,
            isDefault: false,
            areaCode: String(item.city_code),
          }
        }
      })
    },
    onClickLeft: function () {
      this.$router.go(-1)
    },
    validatePostal(tel) {
      return true
    },
    initAdressInfo({ addressComponent, formattedAddress }) {
      const { province, city, district, adcode, township } = addressComponent || {}
      const detail = formattedAddress.replace(new RegExp(province + city + district, 'g'), '')
      this.addressInfo = {
        ...this.addressInfo,
        province,
        city,
        county: district,
        areaCode: adcode,
        addressDetail: detail,
      }
    },
    /**获取地图定位*/
    getLocation() {
      const loading = Toast.loading({
        message: '定位识别中...',
        forbidClick: true,
        duration: 0,
      })
      let _that = this
      address.initMap(
        'map-container',
        [this.longitude, this.latitude],
        (result) => {
          loading.clear()
          _that.initAdressInfo(result.regeocode)
        },
        (error) => {
          loading.clear()
          Toast.loading({
            message: '获取定位失败',
            forbidClick: true,
            duration: 1000,
          })
          console.log('获取定位失败', error)
        }
      )
    },
  },
  computed: {
    isNew: function () {
      return Boolean(this.id) == false
    },
  },
}
</script>
<style scoped>
#address-form {
  height: 100%;
  background: #f5f5f9;
}
</style>
