import http from '../../utils/http'
import api from '../../utils/api'
import { manyGoodsDetail, getCards } from '../../services/goods'
import { fetchMyCoupons, autoReceiveCoupon } from '../../services/coupon'
import { localizedTimeToTimestamp, getTimer, createTime } from '../../utils/convert'
import { createOrder, wechatPay, getStores, getOrderNumbers } from '../../services/order'
import { getInfo, getMyAccounts } from '@/services/me'
import { toastHttpUnKnownError } from '@/utils/errors'
import { Toast } from 'vant'
import { getShareUserId, getForwardingData } from '../../services/share'
import { fetchCompanyExpressFare } from '../../services/express_fare'
import { fetchCompanyDetail, fetchCompanyPointRule } from '@/services/company'
import wechat from '@/utils/wechat'
import { address } from './../../utils/convert'

export default {
  data() {
    return {
      latitude: 0,
      longitude: 0,
      isCard: 0,
      switchDisable: false,
      company_id: null,
      addressInfo: {
        full_address: '请添加地址',
      },
      goods: [],
      availableCoupons: [],
      disabledCoupons: [],
      chosenCoupon: -1,
      showCouponsList: false,
      showDeductPointList: false,
      submitLoading: false,
      isMiniApp: false,
      remark: null,
      switchChecked: false,
      selectStores: {
        name: '',
        full_address: '',
      },
      userInfo: {},
      selectStores_user: {
        name: '',
        phone: '',
        points: 0,
        amounts: 0.0,
      },
      expressSetting: [],
      isPointConsumption: false,
      companyPointRule: {},
      selectedRule: {
        showText: '积分抵扣',
        showValue: '未选择',
        value: 0,
        points: 0,
      },
      payWay: {
        active: 'wechat', // <'wechat','account','timesCard','chargedCard'>
        discount: 0,
        showUnableCollapse: false,
        wechat: {},
        account: {
          display: true,
          available: false,
          value: { total_amounts: 0 },
        },
        timesCard: {
          display: true,
          available: false,
          showList: false,
          active: 0,
          value: [],
        },
        chargedCard: {
          display: true,
          available: false,
          showList: false,
          active: 0,
          totalAmount: 0.0,
          lowestDiscount: 100,
          value: [],
        },
      },
    }
  },
  mounted: function () {
    const {
      product_ids = [],
      sku_ids = [],
      amount = [],
      company_id = null,
      isCard = 0,
      is_point_consumption = 0,
      latitude = 0,
      longitude = 0,
    } = this.$route.query

    this.isCard = isCard
    this.company_id = company_id
    this.isPointConsumption = is_point_consumption == 1 ? true : false
    this.switchChecked = isCard == 1 ? true : this.switchChecked
    this.switchDisable = isCard == 1 ? true : this.switchDisable
    this.latitude = latitude
    this.longitude = longitude
    this.salesroom = JSON.parse(localStorage.getItem('salesroom')) || {
      id: null,
    }

    !this.company_id ? this.$router.push('/errors/404') : ''
    // 验证手机号
    getOrderNumbers(company_id)

    if (this.salesroom.id) {
      this.switchChecked = true
      this.selectStores_user = {
        name: this.salesroom.name,
        phone: this.salesroom.phone,
      }
    } else {
      getInfo().then((res) => {
        let { nick_name = null } = res.data
        this.userInfo = res.data
        console.log(this.userInfo)
        let { phone = null, points = 0, amounts = 0, amounts_of_gift = 0 } = res.data.user

        this.selectedRule.showText = `积分抵扣 (${points}积分可用)`
        this.selectStores_user = {
          name: nick_name,
          amounts: (Number(amounts) + Number(amounts_of_gift)).toFixed(2),
          phone,
          points,
        }
      })
    }

    this.fetchCompanyInfo()
    this.fetchCompanyPointRule()
    this.fetchAddresse(localStorage.getItem('addressId'))
    this.autoCoupon()
    let productIds = Array.isArray(product_ids) ? product_ids : [Number(product_ids)]
    let skuIds = Array.isArray(sku_ids) ? sku_ids.map(Number) : [Number(sku_ids)]
    let Amount = Array.isArray(amount) ? amount : [amount]
    this.validateProduct(productIds, skuIds).then(
      () => {
        this.fetchGoods(productIds, skuIds, Amount)
        this.fetchCompanyExpressFare()
      },
      (rej) => Toast(rej.message)
    )
    this.initPayWays(skuIds).then((payWay) => this.disablePayWays(payWay))
    this.checkIsMiniApp()
  },
  computed: {
    name_and_phone: function () {
      if (!this.addressInfo.id) {
        return '请添加地址'
      } else {
        return `${this.addressInfo.contact_name} ${this.addressInfo.contact_phone}`
      }
    },
    original_price: function () {
      let price = this.goods.reduce((total, good) => {
        return total + good.price * good.amount
      }, 0)
      return price.toFixed(2)
    },
    coupon_discount: function () {
      let coupon = this.availableCoupons[this.chosenCoupon]
      if (coupon) {
        return coupon.value / 100
      } else {
        return 0
      }
    },
    amounts: function () {
      return Math.max(
        0.0,
        (
          this.original_price -
          this.coupon_discount -
          this.payWay.discount +
          Number(this.express_fare) -
          this.selectedRule.value
        ).toFixed(2)
      )
    },
    total: function () {
      return (
        this.goods
          .map((map) => {
            return {
              value: map.price,
              amount: map.amount,
            }
          })
          .reduce((total, item) => {
            return total + item.item.value * item.amount
          }, 0) * 100
      )
    },
    addressPickerPageUrl: function () {
      // 预留
      return `/address?is_select_address=true&selectedId=${this.addressInfo.id}&company_id=${this.company_id}&latitude=${this.latitude}&longitude=${this.longitude}`
    },
    express_fare: function () {
      const fare = `0.00`
      const expressSetting = this.expressSetting
      const isSelfCollect = this.switchChecked

      // 到店自提不需要运费
      if (isSelfCollect) {
        return fare
      }

      switch (expressSetting.rule) {
        case 'all_same':
          if (this.checkIsSameCity(this.address) && this.checkIsSameProvince(this.address)) {
            return `${expressSetting.fare_inside}`
          } else if (this.checkIsSameProvince(this.address) && !this.checkIsSameCity(this.address)) {
            return `${expressSetting.fare_provinces}`
          } else if (this.checkIsExcludeProvince(this.address)) {
            return this.checkIsExcludeProvince(this.address)
          } else {
            return `${expressSetting.fare_outside}`
          }
        case 'limit_until':
          if (this.original_price <= Number(expressSetting.free_until)) {
            return `${expressSetting.fare}`
          }
      }

      return fare
    },

    address: function () {
      const isSelfCollect = this.switchChecked

      return isSelfCollect
        ? Object.assign(this.selectStores, { isSelfCollect })
        : Object.assign(this.addressInfo, { isSelfCollect })
    },
    points: function () {
      if (!this.isPointConsumption) return

      return this.goods
        .map((map) => ({
          cost_points: map.point_consumption.cost_points,
          deduct_amount: map.point_consumption.deduct_amount || 0,
          amount: map.amount,
        }))
        .reduce(
          (acc, cur) => ({
            cost: Number((acc.cost + cur.cost_points) * cur.amount),
            amounts: Number((acc.amounts + cur.deduct_amount) * cur.amount).toFixed(2),
          }),
          {
            cost: '0',
            amounts: '0',
          }
        )
    },
    point_rules: function () {
      let { cost_points = 0, deduct_amount = 0, deduct_limit = 100 } = this.companyPointRule
      let { points } = this.selectStores_user
      let defaultRules = [{ text: '不抵扣', deduct_amount: 0, cost_points }]
      let length = parseInt(((this.original_price * deduct_limit) / 100).toFixed(2) / deduct_amount)

      if (cost_points && deduct_amount) {
        return defaultRules.concat(
          Array.from({ length }, (v, i) => {
            let factor = i + 1
            let amount = Number(factor * deduct_amount).toFixed(2)
            let costPoint = factor * cost_points

            return {
              text: costPoint + '积分抵扣' + amount + '元',
              deduct_amount: amount,
              disabled: points < costPoint,
              cost_points: costPoint,
            }
          })
        )
      }

      return defaultRules
    },
  },
  watch: {
    original_price: function () {
      this.useCoupon().then(
        () => this.fetchMyCoupons(this.company_id),
        () => ''
      )
    },
    availableCoupons: function () {
      this.useCoupon().then(
        () => this.chooseMaxDiscountCoupon(),
        () => ''
      )
    },
    'payWay.active': function (newVal, oldVal) {
      switch (newVal) {
        default:
          this.payWay.discount = 0
          this.payWay.timesCard.active = 0
          this.payWay.chargedCard.active = 0
          break

        case 'account':
          this.payWay.discount = this.original_price
          break
      }
    },
    'payWay.showUnableCollapse': function (newVal, oldVal) {
      if (oldVal == true && newVal == false) {
        this.payWay.account.display = true
        this.payWay.timesCard.display = true
        this.payWay.chargedCard.display = true
      }
    },
  },
  methods: {
    initPayWays: async function (skuIds) {
      let {
          data: { data },
        } = await getMyAccounts(),
        { user_accounts, card_accounts } = data,
        timesCard = [],
        chargedCard = []

      card_accounts.forEach((card) => {
        switch (card.type) {
          default:
            break

          case 'times':
            if (this.cardIsAvailable(card, skuIds, 'times')) {
              timesCard.push(card)
            }
            break

          case 'charged':
            if (this.cardIsAvailable(card, skuIds, 'charged')) {
              chargedCard.push(card)
            }
            break
        }
      })

      this.payWay.account = {
        ...this.payWay.account,
        ...{
          value: user_accounts,
          available: this.amounts < user_accounts.total_amounts,
        },
      }

      this.payWay.timesCard = {
        ...this.payWay.timesCard,
        ...{ value: timesCard, available: timesCard.length > 0 },
      }

      this.payWay.chargedCard = {
        ...this.payWay.chargedCard,
        ...{
          value: chargedCard,
          available: chargedCard.length > 0,
          totalAmount: chargedCard.reduce((acc, cur) => Number(acc) + Number(cur.total_amount), 0) || 0.0,
          lowestDiscount:
            chargedCard.length > 0
              ? chargedCard.sort((prev, next) => prev.discount < next.discount)[0].discount || 100
              : 100,
        },
      }

      return Promise.resolve(this.payWay)
    },
    disablePayWays(payWay) {
      for (const [key, value] of Object.entries(payWay)) {
        if (value.value != undefined && !value.available) {
          this.payWay.showUnableCollapse = true
          this.payWay[key].display = false
        }
      }
    },
    calculateTotalTimes(card) {
      if (card.user_card.benefits.length == 0) return card

      if (card.type == 'times' && card.card_type == 'limited') {
        card.benefit_of_buys_total_times =
          card.user_card.benefits
            .filter((benefit) => benefit.type.indexOf('benefits_of_buy') != -1)
            .reduce((acc, cur) => {
              return acc + Number(cur.time_of_available)
            }, 0) || 0

        card.benefit_of_gifts_total_times =
          card.user_card.benefits
            .filter((benefit) => benefit.type.indexOf('benefits_of_gift') != -1)
            .reduce((acc, cur) => {
              return acc + Number(cur.time_of_available)
            }, 0) || 0
      }

      return card
    },
    calculateCardTotalAmounts(card) {
      if (card.type == 'charged') {
        card.total_amount = (Number(card.user_card.amount) + Number(card.user_card.amount_of_gift)).toFixed(2)
      }

      return card
    },
    calculateCardDiscount(card, skuIds) {
      let benefit

      if (card.type == 'charged') {
        benefit =
          card.user_card.benefits
            .filter((benefit) => benefit.type.indexOf('benefits_of_buy') != -1)
            .find((benefit) => benefit.sku_id == skuIds) || {}

        card.discount = benefit.id != undefined ? benefit.discount : 100
      }

      return card
    },
    calculateActiveCardDiscount() {
      let { active } = this.payWay,
        benefit,
        discount = 0,
        skuId = this.goods[0].id

      if (this.goods.length > 1) return 0

      switch (active) {
        case 'timesCard':
          discount = 1
          break

        case 'chargedCard':
          benefit = this.payWay[active].active.user_card.benefits.find((benefit) => benefit.sku_id == skuId)

          if (benefit.id != undefined) {
            discount = benefit.discount ? benefit.discount : this.amounts
          }
          break
      }

      return discount
    },
    validateProduct(...params) {
      let [productIds = [], skuIds = []] = params

      if (this.isPointConsumption && productIds.length > 1) {
        return Promise.reject({ message: '仅能兑换单个商品', hasError: true })
      }

      return Promise.resolve({ message: '', hasError: false })
    },
    useCoupon() {
      return this.isPointConsumption ? Promise.reject() : Promise.resolve()
    },
    onDeductPointConfirm(value, i) {
      let { deduct_amount = 0, cost_points = 0 } = value

      switch (deduct_amount) {
        case 0:
          this.selectedRule = {
            showValue: '未选择',
            showText: `积分抵扣 (${this.selectStores_user.points}积分可用)`,
            value: 0,
            points: 0,
          }
          break
        default:
          this.selectedRule = {
            showValue: '-' + deduct_amount + '元',
            showText: `积分抵扣`,
            value: deduct_amount,
            points: cost_points,
          }
          break
      }

      this.showDeductPointList = !this.showDeductPointList
    },
    onClickLeft() {
      localStorage.removeItem('salesroom')
      this.$router.go(-1)
    },
    onClickPayway(active) {
      switch (active) {
        default:
          this.payWay.active = 'wechat'
          break

        case 'account':
          if (this.payWay[active].available) {
            this.payWay.active = active
          }
          break

        case 'timesCard':
        case 'chargedCard':
          if (this.payWay[active].available) {
            this.payWay.active = active
            this.payWay[active].showList = true
          }
          break
      }
    },
    onCardSelect(card) {
      let { active = 'wechat' } = this.payWay

      this.payWay[active].active = card
      this.payWay[active].showList = false
      this.payWay.discount = this.calculateActiveCardDiscount() * this.amounts
    },
    fetchAddresse(addressId = null) {
      let that = this
      http.get(api.address.list).then((resp) => {
        let res = resp.data
        if (res.length > 0 && addressId) {
          let _address = res.filter((item) => {
            return item.id == addressId
          })
          if (_address.length == 0) {
            console.warn(`invaid address id: ${addressId}`)
            this.addressInfo = res[0]
          } else {
            this.addressInfo = _address[0]
          }
        } else if (res.length > 0) {
          this.addressInfo = res[0]
        }
        this.getStores()
      })
    },
    fetchGoods: function (productIds, skuIds, amount) {
      if (this.isCard == 1) {
        getCards({
          company_id: this.company_id,
          ids: productIds,
        }).then((res) => {
          this.getGoodDetail(res, skuIds, amount)
        })
      } else {
        manyGoodsDetail(productIds).then((res) => {
          this.getGoodDetail(res, skuIds, amount)
        })
      }
    },
    fetchCompanyExpressFare: function () {
      fetchCompanyExpressFare(this.company_id).then(
        (resp) => {
          this.expressSetting = resp.data
        },
        (rej) => console.log('商家未设置运费')
      )
    },
    getGoodDetail: function (res, skuIds, amount) {
      let resp = res.data
      // 处理 SKU
      let goods = []
      resp.data.forEach((item) => {
        let _selectedSku = item.skus.filter((sku) => {
          return skuIds.includes(sku.id)
        })

        if (_selectedSku.length == 0) {
          console.warn('unmatched sku id')
          _selectedSku = [item.skus[0]]
        }
        item.selectedSku = _selectedSku
        _selectedSku.forEach((c, i) => {
          // 处理数量
          let _index = skuIds.indexOf(c.id)
          c.amount = amount[_index] >= 0 ? amount[_index] : 1
          c.product_id = item.id
          c.product_image_url = item.image_url
          c.product_title = item.title
          // stock库存
          c.price =
            c.time_limit_discount &&
            c.time_limit_discount.stock > 0 &&
            createTime(item.time_limit_discount.ended_at) - createTime() > 0 &&
            createTime() - createTime(item.time_limit_discount.started_at) > 0
              ? c.time_limit_discount.price
              : c.price
        })
        goods = [...goods, ..._selectedSku]
      })

      this.goods = goods
    },
    cardIsAvailable: function (card, skuIds, cardType) {
      if (skuIds.length <= 0) return false

      if (card.type != cardType) return false

      switch (cardType) {
        case 'times':
          card = this.calculateTotalTimes(card)
          if (card.benefit_of_buys_total_times == 0) return false
          break

        case 'charged':
          card = this.calculateCardTotalAmounts(card)

          card = this.calculateCardDiscount(card, skuIds)
          break
      }

      return (
        card.user_card.benefits.findIndex((benefit) => {
          if (benefit.type.indexOf('benefits_of_buy') != -1) {
            return skuIds.indexOf(benefit.sku_id) != -1
          }
        }) != -1
      )
    },
    isAvailable: function (coupon) {
      console.log(coupon)
      console.log(this.userInfo)
      if (!coupon.enabled) {
        return {
          type: false,
          reason: '该优惠券未启用',
        }
      }

      if (coupon.total - coupon.used <= 0) {
        return {
          type: false,
          reason: '该优惠券已用完',
        }
      }
      if (coupon.target == 'new_customer' && this.userInfo.user.consumption_count > 0) {
        return {
          type: false,
          reason: '该优惠劵适用于新用户,您已不是新用户',
        }
      }
      if (coupon.target == 'old_customers' && this.userInfo.user.consumption_count == 0) {
        return {
          type: false,
          reason: '该优惠劵适用于老客户，请您消费一单后再使用',
        }
      }
      if (coupon.available_products.length > 0) {
        let allow = {
          type: true,
          reason: ``,
        }
        let productIds = this.goods.map((good) => {
          return good.product_id
        })
        // 如果每个商品都不在该优惠券可用商品范围内
        if (productIds.filter((productId) => coupon.available_products.includes(productId)).length == 0) {
          allow = {
            type: false,
            reason: `仅适用于指定商品`,
          }
        }
        if (!allow.type) {
          return allow
        }
      }

      let now = new Date().getTime()
      let not_before = new Date(coupon.not_before).getTime()
      if (coupon.not_before && not_before > now) {
        return {
          type: false,
          reason: `${getTimer('dd天HH小时MM分钟', parseInt(not_before - now))}后可用`,
        }
      }

      if (coupon.not_after && new Date(coupon.not_after).getTime() < now) {
        return {
          type: false,
          reason: `已超过有效期`,
        }
      }
      if (this.original_price && Number(this.original_price) < Number(coupon.min_amount)) {
        return {
          type: false,
          reason: `还差￥${(Number(coupon.min_amount) - this.original_price).toFixed(2)}元可用`,
        }
      }
      if (this.original_price && this.original_price - coupon.min_amount < 0) {
        return {
          type: false,
          reason: `优惠券金额大于商品总额`,
        }
      }
      return {
        type: true,
        reason: ``,
      }
    },
    fetchMyCoupons: function () {
      fetchMyCoupons(this.company_id).then((res) => {
        let resp = res.data
        let availableCoupons = []
        let disabledCoupons = []
        resp.data.forEach((item) => {
          const { type, reason } = this.isAvailable(item)
          let thisCoupon = {
            available: 1,
            id: item.id,
            name: item.name,
            startAt: item.not_before ? localizedTimeToTimestamp(item.not_before) : 1489104000,
            endAt: item.not_after ? localizedTimeToTimestamp(item.not_after) : 1514592000,
            value: item.type === 'fixed' ? Number(item.value) * 100 : this.original_price * (100 - item.value),
            valueDesc: item.description,
            originCondition: item.min_amount * 100,
            code: item.code,
            type: item.type,
          }
          if (type) {
            availableCoupons.push(thisCoupon)
          } else {
            disabledCoupons.push(Object.assign(thisCoupon, { reason }))
          }
        })
        this.availableCoupons = availableCoupons
        this.disabledCoupons = disabledCoupons
      })
    },
    chooseMaxDiscountCoupon() {
      let availableCoupons = JSON.parse(JSON.stringify(this.availableCoupons))
      availableCoupons.sort((prev, next) => {
        return next.value - prev.value
      })

      let index = this.availableCoupons.findIndex((element) => {
        return element.code == availableCoupons[0].code
      })
      this.chosenCoupon = index
    },
    validateOrder() {
      if (!this.switchChecked && !this.addressInfo.id) {
        return Promise.reject({ message: '请选择或添加地址', hasError: true })
      }
      if (this.switchChecked && (this.selectStores_user.name == '' || this.selectStores_user.phone == '')) {
        return Promise.reject({
          message: '请填写门店相关信息',
          hasError: true,
        })
      }

      if (this.isPointConsumption && this.companyPointRule.id === undefined) {
        return Promise.reject({
          message: '该商家未配置积分兑换规则，请联系管理员',
          hasError: true,
        })
      }

      let { active } = this.payWay

      if (active == 'timesCard' || active == 'chargedCard') {
        if (this.payWay[active].active.id == undefined) {
          return Promise.reject({
            message: '请选择卡项',
            hasError: true,
          })
        }

        if (active == 'timesCard' && this.payWay.timesCard.active.benefit_of_buys_total_times == 0) {
          return Promise.reject({
            message: '卡项购卡权益可用次数不足',
            hasError: true,
          })
        }
      }

      return Promise.resolve({ message: '', hasError: false })
    },
    prepareSubmitData() {
      this.submitLoading = true

      let items = this.goods.map((item) => {
        return {
          sku_id: item.id,
          amount: item.amount,
        }
      })
      localStorage.removeItem('salesroom')
      let couponCode = this.availableCoupons[this.chosenCoupon] ? this.availableCoupons[this.chosenCoupon].code : ''
      let value = this.switchChecked
        ? {
            store_address: {
              id: this.selectStores.id,
              contact_name: this.selectStores_user.name,
              contact_phone: this.selectStores_user.phone,
            },
          }
        : { address_id: this.addressInfo.id }
      let postData = Object.assign(value, {
        items: items,
        remark: this.remark,
        coupon_code: couponCode,
        express_fare: this.express_fare,
      })

      if (getShareUserId() !== 'null') {
        Object.assign(postData, {
          forwarding: getForwardingData(),
        })
      }

      if (this.selectedRule.points) {
        postData.points = this.selectedRule.points
      }

      if (this.isPointConsumption) {
        postData.is_point_consumption = this.isPointConsumption
      }

      let { active } = this.payWay
      switch (active) {
        default:
          break

        case 'account':
          postData.using_amounts = true
          break

        case 'timesCard':
        case 'chargedCard':
          postData.card_deduction = {
            id: this.payWay[active].active.id,
            type: active.replace('Card', ''),
          }
          break
      }

      return Promise.resolve(postData)
    },
    onSubmit: async function () {
      let order = null,
        postData = null,
        res = null

      try {
        await this.validateOrder()
        postData = await this.prepareSubmitData()
      } catch (e) {
        return Toast(e.message)
      }

      try {
        res = await createOrder(this.company_id, postData)
      } catch (error) {
        console.error(error.response)
        switch (error.response.status) {
          case 422:
            if (error.response.data.errors) {
              Toast(Object.values(error.response.data.errors)[0][0])
            } else {
              Toast(error.response.data.message)
            }
            break
          default:
            toastHttpUnKnownError(error)
            break
        }
        this.submitLoading = false
        return
      }

      order = res.data || null
      this.submitLoading = false
      Toast('订单已提交')

      try {
        if (order.total_amount != 0) {
          res = await wechatPay(order.id)
        }
      } catch (error) {
        console.error(error)
        switch (error.response.status) {
          case 422:
            Toast(`错误码：${error.response.data.code}，错误信息：${error.response.data.message}`)
            break
          default:
            toastHttpUnKnownError(error)
            break
        }
        return
      }

      let pay_resp = res.data
      let payParam = { orderId: order.id, ...pay_resp }

      if (this.isMiniApp) {
        let url = '/pages/customer/pay/index?payParam=' + encodeURIComponent(JSON.stringify(payParam))
        return wechat.naviToMiniApp(url)
      } else {
        wechat.invokePayment(payParam, () => {
          setTimeout(() => {
            window.location = `/#/order?company_id=${this.company_id}`
          }, 2000)
        })
      }
    },
    onCouponChange(index) {
      this.showCouponsList = false
      this.chosenCoupon = index
    },
    onCouponExchange() {
      // @TOOD: delete this?
      console.log('onCouponExchange')
    },
    checkIsMiniApp() {
      wechat.checkIsMiniApp().then(
        (res) => (this.isMiniApp = res),
        (rej) => ''
      )
    },
    switchClick: function (e) {
      this.switchChecked = e
    },
    getStores: function () {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      })
      getStores(this.company_id, this.latitude, this.longitude, '', 0).then((resp) => {
        this.stores = resp.data
        if (this.salesroom.id) {
          const selectStores = resp.data.filter((item) => {
            return item.id == this.salesroom.id
          })
          this.selectStores = selectStores[0]
        } else {
          this.selectStores = resp.data[0]
        }
        Toast.clear()
      })
    },
    checkIsSameProvince(address) {
      let fareCity = this.expressSetting.address.province
      let city = address.isSelfCollect ? address.address.province : address.province
      return city === fareCity
    },
    checkIsSameCity: function (address) {
      let fareCity = this.expressSetting.address.city
      let city = address.isSelfCollect ? address.address.city : address.city
      console.log(fareCity, address)
      return city === fareCity
    },
    checkIsExcludeProvince(address) {
      if (this.expressSetting.address) {
        let exclude_province = this.expressSetting.exclude_province
        let city = address.isSelfCollect ? address.address.province : address.province
        let fare = exclude_province.find((item) => item.province == city)
        if (fare) {
          return fare.fare
        } else {
          return false
        }
      }

      // console.log(exclude_province.find((item) => item.province == '650000'))
    },
    fetchCompanyInfo: async function () {
      let resp = await fetchCompanyDetail(this.company_id)
      let data = resp.data
      document.title = data.name || '优选商城'
    },
    fetchCompanyPointRule: async function () {
      await fetchCompanyPointRule(this.company_id)
        .then((res) => (this.companyPointRule = res.data))
        .catch((rej) => '')
    },
    autoCoupon: async function () {
      await autoReceiveCoupon()
    },
  },
}
