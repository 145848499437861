import { Toast } from 'vant'

export default {
  wechatOAuthFaile: 1001,
  bindPhoneNeeded: 1002,
}

export function toastHttpUnKnownError(error) {
  Toast(`未知错误，状态码 ${error.response.status}`)
}
