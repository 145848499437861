<template>
  <div class="order_refund">
    <van-nav-bar title="申请售后" left-arrow @click-left="onClickLeft" />
    <div class="item-msg">
      <img :src="item.product_snap && item.product_snap.image_url" />
      <div class="msg_detail">
        <div class="msg-name">{{ item.product_snap && item.product_snap.title }}</div>
        <div class="msg-pay">
          <span class="pay-real-pay">￥{{ item.price }}</span>
          <span class="pay-num">×{{ item.amount }}</span>
        </div>
      </div>
    </div>
    <van-cell
      center
      title="我要退款（无需退货）"
      label="没收到货，或与商家协商同意不用退货只退款"
      is-link
      :to="{ path: '/order/refund_form', query: { onlyApply: 0, item: item, ship_status: ship_status } }"
    />
    <van-cell
      v-if="!is_store"
      center
      title="我要退货退款"
      label="已收到货，需要退还收到的货物"
      is-link
      :to="{ path: '/order/refund_form', query: { onlyApply: 1, item: item, ship_status: ship_status } }"
    />
  </div>
</template>
<script>
import { getItemDetail } from '../../services/order'
import { Toast } from 'vant'

export default {
  data: function () {
    return {
      id: null,
      is_store: false,
      ship_status: 'pending',
      item: {},
    }
  },
  created: function () {
    let { id, is_store, ship_status } = this.$route.query
    this.id = id
    this.is_store = is_store == true || is_store == 'true' ? true : false
    this.ship_status = ship_status || 'pending'
    getItemDetail(id).then((detail) => {
      this.item = detail.data
    })
  },
  // mounted: function() {
  //    let { is_store } = this.$route.query;
  //    this.is_store = is_store==true||is_store=='true'?true:false
  // },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
  },
}
</script>
<style scoped>
.order_refund {
  width: 100%;
  height: 100%;
  background: #f5f5f9;
}
.item-msg {
  display: flex;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  background: #ffffff;
}
.item-msg:last-child {
  border-bottom: none;
}

.item-msg > img {
  width: 80px;
  height: 80px;
}

.item-msg > div {
  color: #000;
  flex: 1;
  margin-left: 10px;
}
.item-msg .msg-name {
  font-size: 13px;
  margin-bottom: 24px;
}

.item-msg .msg-pay {
  display: flex;
  justify-content: space-between;
}

.item-msg .msg-pay .pay-real-pay {
  font-size: 15px;
}

.item-msg .msg-pay .pay-num {
  font-size: 12px;
  color: #888;
}
</style>
