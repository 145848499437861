import http from '../utils/http'
import api from '../utils/api'

/**
 * 获取商家运费配置
 *
 * @param {company_id} company_id
 */
export function fetchCompanyExpressFare(company_id) {
  return http.get(api.express_fare.getByCompany(company_id))
}
