import { saveAccessToken, removeAccessToken } from '../services/auth'

const beforeEach = (to, from, next) => {
  if (to.query.access_token === 'clear') {
    // 携带了清除 token flag
    removeAccessToken()
  } else if (to.query.access_token !== 'null' && to.query.access_token !== undefined) {
    // 如果携带了 token
    saveAccessToken(to.query.access_token)
  }

  // 如果目标路径需要缓存
  if (to.meta.isCache && from.meta.isCache) {
    if (from.meta.tree > to.meta.tree) {
      // 后退,上一层级页面缓存
      from.meta.keepAlive = false
      to.meta.keepAlive = true
    } else if (from.meta.tree < to.meta.tree) {
      // 前进,下一层级重新加载
      from.meta.keepAlive = true
      to.meta.keepAlive = false
    } else {
      // 同一层级,页面缓存
      from.meta.keepAlive = true
      to.meta.keepAlive = true
    }
  }
  next()
}

export default beforeEach
