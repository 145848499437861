import Index from '@/components/Index'
import Cart from '@/components/Cart'
import AddressList from '@/components/Address/Index'
import AddressForm from '@/components/Address/Form'
import OrderPage from '@/components/Order/Index'
import OrderForm from '@/components/Order/Form'
import OrderDetail from '@/components/Order/Detail'
import Logistics_detail from '@/components/Order/Logistics_detail'
import Logistics_details from '@/components/Order/Logistics_details'
import OrderRefund from '@/components/Order/Refund'
import OrderRefundForm from '@/components/Order/Refund_form'
import OrderRefundDetail from '@/components/Order/Refund_detail'
import mePage from '@/components/Me/Index'
import GoodList from '@/components/Good/Index'
import GoodDetail from '@/components/Good/Detail'
import BrandStory from '@/components/Good/Company/Story'
import CouponByGood from '@/components/Good/Coupon'
import NotFoundErrorPage from '@/components/Error/NotFoundError'
import Expire from '@/components/Error/Expire'
import SalesroomList from '@/components/Salesroom/Index'
import Composition from '@/components/Good/Composition'
import BannerDetail from '@/components/Banner/Detail'
import ActivityList from '@/components/Activity/Index'
const routes = [
  { path: '/', name: 'home', component: Index, meta: { isCache: true, tree: 1 } },
  { path: '/cart', name: 'cart', component: Cart, meta: { isCache: true, tree: 2.5 } },
  { path: '/address', name: 'addressList', component: AddressList, meta: { isCache: true, tree: 2.5 } },
  { path: '/address/create', name: 'addressCreate', component: AddressForm, meta: { isCache: true, tree: 3 } },
  {
    path: '/address/edit/:id',
    name: 'addressEdit',
    component: AddressForm,
    props: true,
    meta: { isCache: true, tree: 3 },
  },
  { path: '/salesroom', name: 'salesroomList', component: SalesroomList, meta: { isCache: true, tree: 2.5 } },
  { path: '/order', name: 'orderList', component: OrderPage, meta: { isCache: false, tree: 2 } },
  { path: '/order/create', name: 'orderCreate', component: OrderForm, meta: { isCache: true, tree: 3 } },
  { path: '/order/detail', name: 'orderDetail', component: OrderDetail, meta: { isCache: true, tree: 3 } },
  {
    path: '/order/logistics_detail',
    name: 'logistics_detail',
    component: Logistics_detail,
    meta: { isCache: true, tree: 4 },
  },
  {
    path: '/order/logistics_details',
    name: 'logistics_details',
    component: Logistics_details,
    meta: { isCache: true, tree: 4 },
  },
  { path: '/order/refund', name: 'orderRefund', component: OrderRefund, meta: { isCache: true, tree: 4 } },
  {
    path: '/order/refund_form',
    name: 'orderRefundCreate',
    component: OrderRefundForm,
    meta: { isCache: true, tree: 5 },
  },
  {
    path: '/order/refund_detail',
    name: 'orderRefundDetail',
    component: OrderRefundDetail,
    meta: { isCache: true, tree: 5 },
  },
  { path: '/me', name: 'me', component: mePage, meta: { isCache: true, tree: 1 } },
  { path: '/goods', name: 'goodList', component: GoodList, meta: { isCache: true, tree: 1.5 } },
  { path: '/goods/coupon', name: 'couponByGood', component: CouponByGood, meta: { isCache: true, tree: 3 } },
  { path: '/goods/:id', name: 'goodDetail', component: GoodDetail, props: true, meta: { isCache: true, tree: 2 } },
  {
    path: '/goods/:id/compositions',
    name: 'composition',
    component: Composition,
    props: true,
    meta: { isCache: true, tree: 3 },
  },
  {
    path: '/goods/brand/:id',
    name: 'brandDetail',
    component: BrandStory,
    props: true,
    meta: { isCache: true, tree: 3 },
  },
  { path: '/banner/:id', name: 'bannerDetail', component: BannerDetail, props: true, meta: { isCache: true, tree: 2 } },
  { path: '/activity', name: 'activityList', component: ActivityList, meta: { isCache: true, tree: 1.5 } },
  { path: '/expire', name: 'expire', component: Expire, meta: { isCache: true, tree: 1 } },
  { path: '*', name: 'notFoundError', component: NotFoundErrorPage },
]

export default routes
