<template>
  <div id="notfound">
    <img src="@/assets/404_image.png" alt srcset />
    <h1>页面不存在</h1>
    <h2>啊哦，出错了，页面丢失了</h2>
    <button @click="goHome">返回首页</button>
  </div>
</template>
<style scoped>
#app {
  height: unset;
}
#notfound {
  padding-top: 30px;
  text-align: center;
}
img {
  width: 97px;
  height: 116px;
  margin-bottom: 15px;
}
h1,
h2,
button {
  text-align: center;
  margin: 0;
}
h1 {
  font-size: 22px;
  font-weight: 350;
  margin-bottom: 10px;
}
h2 {
  font-size: 13px;
  color: #727272;
  font-weight: lighter;
}
button {
  color: #666666;
  border: #b2b2b2 solid 0.5pt;
  border-radius: 4px;
  padding: 8px 25px;
  display: block;
  margin: 30px auto 0;
  background: #fff;
}
</style>
<script>
import { getInfo } from '@/services/me'
import { checkAuthentication } from '@/services/auth'

export default {
  data: function () {
    return {
      company_id: null,
    }
  },
  mounted: function () {
    if (checkAuthentication()) {
      getInfo().then((resp) => {
        this.company_id = resp.data.user.company_id
      })
    }
  },
  methods: {
    goHome() {
      this.$router.push(`/?company_id=${this.company_id || 1}`)
    },
  },
}
</script>
