export const ACCESS_TOKEN_FLAG = 'access_token'
const BASE_URL = process.env.VUE_APP_BASE_URL
const MINI_PROGRAM_BASE_URL = process.env.VUE_APP_MINI_PROGRAM_BASE_URL

import http from '../utils/http'
import api from '../utils/api'
import { Dialog } from 'vant'
import wechat from '../utils/wechat'
import redirect_count from './redirect_count'

export function checkAuthentication() {
  if (!getAccessToken()) {
    return false
  } else {
    return true
  }
}

export function saveAccessToken(value) {
  window.localStorage.setItem('access_token', value)
  http.reload()
}

export function getAccessToken() {
  return window.localStorage.getItem('access_token')
}

export function removeAccessToken() {
  window.localStorage.removeItem(ACCESS_TOKEN_FLAG)
  http.reload()
}

export function redirectToWeixinMiniProgramLogin(bindphone = false, company_id = 1) {
  if (redirect_count.times() >= 1) {
    return false
  }

  let redirectTo = `pages/customer/shop/shopLink&link=${encodeURIComponent(document.location.href)}`
  let url = `/pages/guide/login/login?checkPhone=${bindphone}&redirectTo=${redirectTo}`

  wechat.checkIsMiniApp().then(
    (res) => {
      wechat.naviToMiniApp(url)
    },
    (res) => {
      http.constructor(MINI_PROGRAM_BASE_URL)
      http
        .post(
          api.mini_program.minicode_unlimit,
          {
            company_id,
            page: `pages/customer/shop/index`,
            scene: 'index',
          },
          'arraybuffer'
        )
        .then((res) => {
          let src = Buffer.from(res.data, 'binary').toString('base64')
          let message = '<img src="data:image/jpeg;base64, ' + src + '" height="150">'
          let title = '请扫描二维码在小程序中打开'
          Dialog.alert({
            title,
            message,
          })
        })
      http.reload()
    }
  )

  redirect_count.addTimes()
}
