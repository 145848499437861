<template>
  <div id="compositions">
    <header>
      <img class="product-img" :src="item.image_url" :alt="item.title" />
      <h1>{{ item.title }}</h1>
    </header>
    <table>
      <tr>
        <th>成分名称</th>
        <th>安全指数</th>
        <th>活性成分</th>
        <th>致痘风险</th>
        <th>使用目的</th>
      </tr>
      <tr v-for="(_item, index) in compositions" :key="index">
        <td>{{ _item.title }}</td>
        <td>
          <span class="safety-circle" :class="_item.safety_class">{{ _item.safety }}</span>
        </td>
        <td class="active">
          <img src="@/assets/icons/active_indicators/actived.png" v-if="_item.active == '1' || _item.active == 's'" />
          <img src="@/assets/icons/active_indicators/uvab.png" v-if="_item.active == 'UVAB'" />
          <img src="@/assets/icons/active_indicators/uva.png" v-if="_item.active == 'UVA'" />
          <img src="@/assets/icons/active_indicators/uvb.png" v-if="_item.active == 'UVB'" />
        </td>
        <td>
          <span class="acne_risk" v-if="_item.acne_risk != 0"></span>
        </td>
        <td>
          <span v-for="(_used, index) in _item.used_as" :key="index">{{ _used.title }}</span>
        </td>
      </tr>
    </table>

    <div id="intro" class="container">
      <div id="safety">
        <div class="title">
          <h1>安全指数</h1>
        </div>
        <div class="content">
          <div class="flex-row">
            <span class="safety-circle safety-circle-green"></span>
            <span>表示安全</span>
            <span class="safety-circle safety-circle-yellow"></span>
            <span>表示中等</span>
            <span class="safety-circle safety-circle-red"></span>
            <span>表示风险</span>
          </div>
          <span>安全指数范围为 0-10，数字越大表示风险越大，但风险大并不代表成分不好，比如视黄醇。</span>
        </div>
      </div>

      <div id="active">
        <div class="title">
          <h1>活性成分</h1>
        </div>
        <div class="detail">
          <div class="row">
            <img src="@/assets/icons/active_indicators/actived.png" />
            <span>表示该成分对皮肤有一定的调理功效</span>
          </div>
          <div class="row">
            <img src="@/assets/icons/active_indicators/uvab.png" />
            <span>表示该成分主要防护 UVA 紫外线，防止皮肤晒老</span>
          </div>
          <div class="row">
            <img src="@/assets/icons/active_indicators/uva.png" />
            <span>表示该成分主要防护 UVB 紫外线，防止皮肤晒伤、晒红</span>
          </div>
          <div class="row">
            <img src="@/assets/icons/active_indicators/uvb.png" />
            <span>表示该成分对 UVA、UVB 波段紫外线都有防护能力</span>
          </div>
        </div>
      </div>

      <div id="acne">
        <div class="title">
          <h1>致痘风险</h1>
        </div>
        <div class="detail">
          <div>
            <span class="acne_risk"></span>
          </div>
          <span>表示该成分有诱发油皮粉刺或导致粉刺恶化的风险，但并不一定会长粉刺，这也与成分用量及个人肤质有关。</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#compositions {
  width: 95%;
  display: block;
  margin: 0 auto;
  padding: 20px 0;
}

.product-img {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 200px;
}

h1 {
  color: #333333;
  font-size: 18px;
  text-align: center;
}

table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  box-sizing: border-box;
  font-size: 14px;
  color: #7b7b7b;
}

.safety-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  line-height: 15px;
  display: inline-block;
  color: #fff;
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.safety-circle-green {
  background-color: #56bc46;
}

.safety-circle-yellow {
  background-color: #f1a65e;
}

.safety-circle-red {
  background-color: #cf5050;
}

.active img {
  height: 40px;
  width: 40px;
}

.acne_risk {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: #ed877f solid 5px;
  display: inline-block;
}

td {
  text-align: center;
}

#intro span {
  color: #646464;
  font-size: 14px;
}

#safety,
#active,
#acne {
  margin: 30px 0;
}
.title {
  position: relative;
}
.title::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: #64c7cc;
  width: 5px;
  height: 16px;
  border-radius: 5px;
}

.title h1 {
  padding-left: 10px;
  text-align: left;
  font-weight: normal;
}

.flex-row {
  margin-bottom: 20px;
  display: flex;
  /* justify-content: flex-start; */
  line-height: 25px;
  align-items: center;
}

.flex-row span:nth-child(even) {
  margin-right: 40px;
}

#acne .detail {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#acne .detail div {
  margin-right: 10px;
}

/* 活性成分 */

#active .row {
  display: flex;
  align-items: center;
}
#active img {
  width: 40px;
  display: block;
  flex-shrink: 0;
}

#active text {
  margin-left: 20px;
  display: block;
  color: #646464;
  font-size: 14px;
}
</style>
>
<script>
import { goodDetail, getCompositions } from '../../services/goods'
import { toastHttpUnKnownError } from '../../utils/errors'

export default {
  props: ['id'],
  data: function () {
    return {
      item: {
        image_url:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAACF0lEQVR4nO3TMRHAMBDAsCT86f7cEOh5bQcJgRfvmXkW8Op8HQB/ZhAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBcAH6TwVuJuIErgAAAABJRU5ErkJggg==',
      },
      compositions: [],
    }
  },

  mounted: function () {
    goodDetail(this.id)
      .then((res) => {
        this.item = res.data
      })
      .catch((err) => {
        toastHttpUnKnownError(err)
      })
    getCompositions(this.id)
      .then((res) => {
        this.compositions = res.data.data.map((item) => {
          let safety = item.safety
          let safety_level = 0
          if (Number(safety)) {
            safety_level = Number(safety)
          } else {
            safety_level = Number(safety.slice(safety.indexOf('-') + 1))
          }
          if (0 <= safety_level && safety_level <= 3) {
            item.safety_class = 'safety-circle-green'
          } else if (4 <= safety_level && safety_level <= 6) {
            item.safety_class = 'safety-circle-yellow'
          } else if (7 <= safety_level && safety_level <= 10) {
            item.safety_class = 'safety-circle-red'
          }
          return item
        })
      })
      .catch((error) => {
        toastHttpUnKnownError(error)
      })
  },
}
</script>
