<template>
  <div>
    <header>
      <van-search disabled placeholder="搜索店铺内商品" shape="round" @click="showPopup" />
      <van-popup class="index_popup" v-model="popupShow" position="top">
        <div class="popup_box">
          <div class="popup_header">
            <van-icon class="popup_icon" @click="closePopup" size="24" color="#333" name="arrow-left" />
            <van-search class="popup_search" placeholder="搜索店铺内商品" v-model="keywords" shape="round" show-action>
              <div slot="action" @click="onSearch">搜索</div>
            </van-search>
          </div>
          <div class="popup_content">
            <div
              @click="goGoodDetail(item.id, 0, item)"
              class="content_item"
              v-for="item in search_goods"
              :key="item.id"
            >
              <img :src="item.image_url" :alt="item.title" />
              <div class="good_msg">
                <div>{{ item.title }}</div>
                <span>¥{{ item.price }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-popup>

      <img v-if="couponPopShow" class="coupon_pop_close_btn" src="@/assets/close2.png" alt @click="closeCouponPopup" />
      <van-popup style="background: transparent" v-model="couponPopShow" overlay>
        <div class="coupon_pop">
          <div>进店有礼</div>
          <div class="title">幸运红包</div>
          <div class="coupon_detail">
            <div class="coupon_item" v-for="(item, index) in popupCoupon" :key="index">
              <div class="item_left">
                <span v-if="item.type == 'fixed'">
                  <span>¥</span>
                  {{ item.value }}
                </span>
                <span v-else>
                  {{ item.value }}
                  <span>折</span>
                </span>
              </div>
              <div class="item_right">{{ item.description }}</div>
            </div>
          </div>
          <div class="coupon_btn" @click="receiveCoupons(popupCoupon)">立即领取</div>
        </div>
      </van-popup>
      <van-swipe :autoplay="6000" :style="{ height: swiperH + 'px' }">
        <van-swipe-item v-for="(item, index) in banners" :key="index">
          <a :href="item.url" target="_blank">
            <img
              :style="{ width: '100%', height: swiperH + 'px' }"
              @load="bannerLoad"
              @click="onClickBanner(item)"
              :src="item.image"
            />
          </a>
        </van-swipe-item>
      </van-swipe>
    </header>
    <div id="verification">
      <div v-if="homeCoupons.length > 0" id="coupons">
        <div style="overflow-x: auto; height: 72px">
          <div id="coupon-container">
            <div class="coupon-item" v-for="item in homeCoupons" :key="item.id" @click="receiveCoupon(item)">
              <img src="../assets/coupon1.png" alt />
              <div class="left">
                <h3 v-if="item.type == 'fixed'">
                  <span>¥</span>
                  {{ item.value }}
                </h3>
                <h3 v-else>
                  {{ item.value }}
                  <span>折</span>
                </h3>
                <h4>{{ item.description }}</h4>
              </div>
              <div :class="[item.isDraw ? 'right' : 'right noDraw']">
                <div class="noDraw" v-if="item.limit - item.own !== 0 && item.fe_position == 'homepage'">
                  <p>领</p>
                  <p>取</p>
                </div>
                <div class="isDraw" v-else>已领取</div>
                <!-- <div class="isDraw" v-if="!item.isDraw&item.limit-item.own==0">已领完</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 限时活动 -->
    <div v-if="timeLimitList.length > 0" class="time_limit">
      <div class="gutter"></div>

      <div v-for="item in timeLimitList" :key="item.id" class="limit_box">
        <div v-if="item.style !== 'banner'" class="limit_header">
          <div class="header_msg">
            <div class="header_title">{{ item.title }}</div>
            <div class="header_time">{{ item.started_at }}-{{ item.ended_at }}</div>
          </div>
          <div @click="goActivityList(item.id)" class="more">查看更多 ></div>
        </div>
        <div v-if="item.style == 'row'" :class="'limit_' + item.style">
          <div
            @click="goGoodDetail(product.id, 0, product)"
            class="product_item"
            v-for="product in item.products"
            :key="product.id"
          >
            <div class="img_box">
              <img :src="product.image_url + '?x-oss-process=image/resize,w_320,h_320'" alt />
            </div>
            <div class="product_title">{{ product.title }}</div>
            <div class="product_price">
              <span class="price">¥ {{ product.time_limit_discount.price }}</span>
              <span v-if="item.label" class="s-triangle">{{ item.label }}</span>
            </div>
          </div>
        </div>
        <div v-if="item.style == 'column'" :class="'limit_' + item.style">
          <div
            @click="goGoodDetail(product.id, 0, product)"
            class="product_item"
            v-for="product in item.products"
            :key="product.id"
          >
            <img :src="product.image_url + '?x-oss-process=image/resize,w_320,h_320'" alt />
            <div class="product_msg">
              <div class="product_title">{{ product.title }}</div>
              <div class="product_price">
                <span class="price">¥ {{ product.time_limit_discount.price }}</span>
                <span v-if="item.label" class="s-triangle">{{ item.label }}</span>
              </div>
              <div>
                <s>￥{{ product.price }}</s>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.style == 'banner'" :class="'limit_' + item.style">
          <img @click="goActivityList(item.id)" :src="item.banner_image_url" alt />
        </div>
        <div class="gutter"></div>
      </div>
    </div>
    <!-- 体验卡 -->
    <div v-if="cards.length > 0" id="cards">
      <div class="card_header">
        <span class="title">体验卡</span>
        <span class="more" @click="goToGoods()">更多</span>
      </div>
      <div class="card_box">
        <div v-for="item in cards" :key="item.id" class="card_item" @click="goGoodDetail(item.id, 1, item)">
          <img :src="item.image_url + '?x-oss-process=image/resize,w_320,h_320'" alt />
          <div class="card_msg">
            <div class="msg_title">{{ item.title }}</div>
            <div class="msg_memo">{{ item.memo }}</div>
            <div class="msg_price">¥ {{ item.price }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="cards.length > 0" class="gutter"></div>
    <div id="goods">
      <div class="goods_header">
        <span class="title">新品上架</span>
        <span class="more" v-on:click="viewAll">更多</span>
      </div>
      <div id="goods-list">
        <van-list v-model="listLoading" @load="listOnLoad" :finished="finished" :immediate-check="false" :offset="100">
          <van-row gutter="20">
            <van-col
              v-for="item in goods"
              :key="item.id"
              @click="goGoodDetail(item.id, 0, item)"
              class="good-contaienr"
              span="12"
            >
              <img :src="item.image_url" :alt="item.title" />
              <div>{{ item.title }}</div>
              <span>
                ¥{{ item.price }}
                <span
                  v-if="item.time_limit_discount && item.time_limit_discount.label"
                  class="s-triangle"
                  style="margin-left: 9px"
                  >{{ item.time_limit_discount.label }}</span
                >
              </span>
            </van-col>
          </van-row>
        </van-list>
      </div>
      <div v-if="finished" class="button-wrapper">
        <van-button round plain hairline v-on:click="viewAll" type="danger">查看全部商品</van-button>
      </div>
    </div>

    <div v-if="!tabShow" @click="goToCart()" id="cartNumBtn">
      <img src="../assets/index_cart.png" />
      <div v-if="cartNum > 0">{{ cartNum }}</div>
    </div>
    <div v-if="bottomCouponShow" class="bottom_coupon">
      <img class="coupon_bottom_close_btn" src="@/assets/close2.png" alt @click="closeBottomCoupon" />
      <span>进店有礼</span>
      <div @click="bottomCouponClick">立即领取</div>
    </div>
    <Footer active="index" :company_id="parseInt(company_id)" />
  </div>
</template>
<script>
import Footer from './Footer'
import { fetchGoods } from '../services/cart'
import { checkAuthentication, saveAccessToken } from '../services/auth'
import { fetchCompanyCoupons, receiveCoupon, fetchMyCoupons } from '../services/coupon'
import { fetchIndexBanners } from '../services/banner'
import { indexGoodsList, goodsList, getCards } from '../services/goods'
import { getOrders } from '../services/order'
import { timeLimitList, timeLimitDetail } from '../services/activity'
import http from '../utils/http'
import api from '../utils/api'
import { Toast } from 'vant'
import { Search } from 'vant'
import errors from '../utils/errors'
import wechat from '../utils/wechat'
import { fetchCompanyDetail } from '../services/company'
import { getTimer, createTime } from '@/utils/convert'

export default {
  data() {
    return {
      keywords: null,
      popupShow: false,
      banners: [
        {
          image:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAACF0lEQVR4nO3TMRHAMBDAsCT86f7cEOh5bQcJgRfvmXkW8Op8HQB/ZhAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBcAH6TwVuJuIErgAAAABJRU5ErkJggg==',
        },
      ],
      homeCoupons: [],
      goods: [],
      search_goods: [],
      cartNum: 0,
      isdrawCoupons: false,
      tabShow: true,
      cards: [],
      couponPopShow: false,
      popupCoupon: [],
      bottomCouponShow: false,
      bottomCoupon: [],
      isbottomCoupon: false,
      timeLimitList: [],
      listLoading: false,
      page: 1,
      finished: false,
      imgHeights: [],
      swiperH: '',
    }
  },
  components: {
    Footer: Footer,
  },
  created: function () {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    if (typeof wx != 'undefined') {
      wx.miniProgram.getEnv((res) => {
        console.log('miniProgram', res.miniprogram)
        this.tabShow = !res.miniprogram
      })
    }
    this.company_id = this.$route.query.company_id || urlParams.get('company_id')

    if (this.company_id == null) {
      this.$router.push('/errors/404')
      return
    }

    this.fetchBanners()
    this.fetchCompanyCoupons()
    this.fetchIndexGoodsList()
    this.fetchCompanyInfo()
    this.fetchCards()
    this.getTimeLimitList()
    if (checkAuthentication()) {
      this.fetchCartNum()
    }
  },
  activated: function () {
    this.isdrawCoupons = false
  },
  methods: {
    goToGoods: function () {
      this.$router.push(`/goods?company_id=${this.company_id}&isCard=1`)
    },
    goToGoodCoupon: function () {
      this.$router.push({
        path: `/goods/coupon?company_id=${this.company_id}`,
        query: { item: JSON.stringify(this.drawCoupon) },
      })
    },
    viewAll: function () {
      this.$router.push(`/goods?company_id=${this.company_id}`)
    },
    fetchCards: function () {
      getCards({ company_id: this.company_id }).then((res) => {
        this.cards = res.data.data
          .sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
          })
          .slice(0, 3)
      })
    },
    fetchCompanyCoupons: function (isFirst = true) {
      let positionArr = {
        homepage: [],
        popup: [],
        bottom_right_corner_on_homepage: [],
        product_detail: [],
      }
      const filterCoupon = () => {
        // 首次进来或者刷新重新弹窗
        this.homeCoupons = [...positionArr.homepage, ...this.homeCoupons]
        if (isFirst) {
          if (positionArr.popup.length > 0) {
            this.couponPopShow = true
            this.popupCoupon = positionArr.popup
          }
          if (positionArr.bottom_right_corner_on_homepage.length > 0) {
            this.bottomCouponShow = true
            this.bottomCoupon = positionArr.bottom_right_corner_on_homepage
          }
        }
      }
      if (checkAuthentication()) {
        Promise.all([
          fetchCompanyCoupons(this.company_id),
          fetchMyCoupons(this.company_id),
          getOrders('received', this.company_id),
        ]).then((arr) => {
          let companyCoupons = arr[0].data.data
          let myCoupons = arr[1].data.data
          let orders = arr[2].data.data
          let isOldUser = orders.length > 0 ? true : false
          this.homeCoupons = []
          companyCoupons.forEach((item) => {
            // 判断是否能领取
            const isDraw =
              myCoupons.findIndex((v) => {
                return v.id == item.id && v.limit - v.own == 0
              }) !== -1
                ? false
                : true
            if (isDraw) {
              // 判断是否符合身份领取
              let canDraw
              if (
                (isOldUser && item.target == 'old_customers') ||
                (!isOldUser && item.target == 'new_customer') ||
                item.target == 'all'
              ) {
                canDraw = true
              } else {
                canDraw = false
              }
              if (canDraw) {
                /** 判断位置
                 * homepage  首页
                 * popup 进店弹窗
                 * bottom_right_corner_on_homepage 右下角
                 * product_detail 商品详情页
                 * */
                const position = item.fe_position
                positionArr[position].push(item)
              }
            }
          })
          filterCoupon()
        })
      } else {
        fetchCompanyCoupons(this.company_id).then((resp) => {
          const coupons = resp.data.data
          coupons.forEach((item) => {
            const position = item.fe_position
            positionArr[position].push(item)
          })
          filterCoupon()
        })
      }
    },
    closeCouponPopup() {
      this.couponPopShow = false
    },
    receiveCoupon: function ({ id, limit, own }) {
      if (limit - own !== 0) {
        receiveCoupon(id).then((resp) => {
          Toast(resp.data.message)
          this.fetchCompanyCoupons(false)
        })
      }
    },
    receiveCoupons: function (coupons) {
      const receiveArr = []
      coupons.forEach(({ id, limit, own }) => {
        if (limit - own !== 0) {
          receiveArr.push(receiveCoupon(id))
        }
      })
      Promise.all(receiveArr).then(() => {
        Toast('领取成功！')
        this.fetchCompanyCoupons(false)
        if (this.isbottomCoupon) {
          this.bottomCouponShow = false
          this.isbottomCoupon = false
        }
        this.couponPopShow = false
      })
    },
    fetchBanners: function () {
      fetchIndexBanners(this.company_id).then((resp) => {
        this.banners = resp.data
      })
    },
    fetchIndexGoodsList() {
      indexGoodsList({ company_id: this.company_id, page: this.page }).then((resp) => {
        this.listLoading = false
        const data = resp.data.data
        data.forEach((item) => {
          item.image_url = item.image_url + '?x-oss-process=image/resize,w_320,h_320'
          item.price =
            item.time_limit_discount && createTime(item.time_limit_discount.ended_at) > createTime()
              ? item.time_limit_discount.price
              : item.price
        })
        this.goods = [...this.goods, ...data]
      })
    },
    fetchCartNum: function () {
      fetchGoods().then((resp) => {
        this.cartNum = resp.data.length
      })
    },
    fetchCompanyInfo: async function () {
      try {
        let resp = await fetchCompanyDetail(this.company_id)
        let data = resp.data
        document.title = data.name || '优选商城'
        if (typeof wx != 'undefined') {
          wechat.configJSSDK(document.URL).then(() => {
            wx.ready(() => {
              wechat.configAppMessageShareData(
                data.name,
                '刚刚发现一个不错的店铺，赶紧来看看吧',
                document.URL,
                data.logo
              )
              wechat.configTimelineShareData(data.name, document.URL, data.logo)
            })
          })
        }
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.$router.push('/expire')
        }
      }
    },
    goToCart() {
      this.$router.push(`/cart?company_id=${this.company_id}`)
    },
    goGoodDetail(id, isCard = 0, product = null) {
      let limit = 0
      if (product.time_limit_discount && createTime(product.time_limit_discount.ended_at) > createTime()) {
        limit = 1
      }
      this.$router.push(
        `/goods/${id}?company_id=${this.company_id}&isCard=${product.type == 'product' ? 0 : 1}&isLimit=${limit}`
      )
    },
    onSearch: function (search) {
      this.loading = true
      goodsList(this.company_id, '', '', '', '', this.keywords).then((resp) => {
        this.loading = false
        this.search_goods = resp.data.data
        this.lastPage = resp.data.meta.last_page
      })
    },
    showPopup: function () {
      this.keywords = ''
      this.search_goods = []
      this.popupShow = true
    },
    closePopup: function () {
      this.popupShow = false
    },
    onClickBanner: function (item) {
      if (!item.url && item.content) {
        this.$router.push(`/banner/${item.id}?company_id=${this.company_id}`)
      }
    },
    closeBottomCoupon() {
      this.bottomCouponShow = false
    },
    bottomCouponClick() {
      this.couponPopShow = true
      this.isbottomCoupon = true
      this.popupCoupon = [...this.bottomCoupon]
    },
    getTimeLimitList() {
      timeLimitList(this.company_id).then((resp) => {
        if (resp.status == 200) {
          // 排序并筛选出结束时间大于现在时刻的活动
          const timeLimitList = resp.data
            .sort((a, b) => {
              return a.sort - b.sort
            })
            .filter(({ ended_at }) => {
              return new Date(ended_at).valueOf() > new Date().valueOf()
            })
          timeLimitList.map((item) => {
            return (
              (item.started_at = getTimer('mm.dd', item.started_at)),
              (item.ended_at = getTimer('mm.dd', item.ended_at)),
              item
            )
          })
          this.timeLimitList = timeLimitList
        }
      })
    },
    goActivityList(id) {
      this.$router.push(`/activity?company_id=${this.company_id}&id=${id}`)
    },
    listOnLoad() {
      this.listLoading = true
      if (this.page > 2) {
        this.page = 1
        this.listLoading = false
        this.finished = true
        return
      }
      this.page = this.page + 1
      this.fetchIndexGoodsList()
    },
    bannerLoad(detail) {
      const imgHeights = [...this.imgHeights, detail.path[0].height]
      this.imgHeights = imgHeights
      if (imgHeights.length == this.banners.length + 1) {
        imgHeights.sort((a, b) => b - a)
        this.swiperH = imgHeights[0]
      }
    },
  },
}
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, Helvetica, sans-serif;
}

/* banner 下方文字区域 */
#verification {
  background-color: #fafafa;
  /* height: 40px; */
  position: relative;
}

#verification li img {
  height: 15px;
  width: 15px;
  margin-right: 3px;
}

#verification ul {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
}

#verification ul li {
  float: left;
  display: flex;
  align-items: center;
  color: #969799;
  font-size: 12px;
}

#coupons {
  position: absolute;
  width: 90%;
  height: 72px;
  overflow-x: auto;
  left: 50%;
  top: -95px;
  transform: translateX(-50%);
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
  color: transparent;
}

#coupons h2 {
  color: #333333;
  font-size: 16px;
  margin-bottom: 10px;
}

#coupon-container {
  height: 72px;
  white-space: nowrap;
  overflow-y: hidden;
}

.coupon-item {
  position: relative;
  height: 71px;
  width: 150px;
  display: inline-block;
  margin-right: 15px;
  border-radius: 8px;
}

.coupon-item > img {
  width: 100%;
  height: 100%;
}

.coupon-item .left {
  width: 115px;
  height: 100%;
  padding-left: 10px;
  position: absolute;
  top: 0;
  box-sizing: border-box;
}

.coupon-item .right {
  position: absolute;
  width: 35px;
  height: 100%;
  top: 0;
  right: 0;
}

.coupon-item .isDraw {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: rgba(255, 177, 177, 1);
  font-size: 12px;
  border-radius: 50%;
  border: 1px dotted rgba(255, 184, 184, 1);
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
  right: 10px;
}

.coupon-item .noDraw::before {
  content: '';
  position: absolute;
  top: 4px;
  width: 1px;
  height: 63px;
  border-left: 1px dotted rgba(255, 184, 184, 1);
}
.coupon-item .right .noDraw {
  margin-top: 15px;
}
.coupon-item .right .noDraw > p {
  text-align: center;
  color: #ff373f !important;
  font-weight: 500;
  margin: 0;
}

.coupon-item h3,
h4 {
  color: #ff373f;
}

.coupon-item h3 span {
  font-size: 15px;
}

.coupon-item h3 {
  font-size: 22px;
  margin: 12px 0 0;
}

.coupon-item h4 {
  font-size: 12px;
  margin: 0;
  font-weight: lighter;
}
/* 优惠券详细信息 */
.draw_coupons {
  width: 90%;
  height: 103px;
  margin: 15px 0 15px 5%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(190, 190, 190, 0.5);
  border-radius: 6px;
  border: 1px solid rgba(243, 243, 243, 1);
  display: flex;
  align-items: center;
}

.draw_coupons > .coupons_left {
  font-size: 12px;
  color: #ee0a22;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 30px;
}

.draw_coupons > .coupons_left > div {
  font-size: 24px;
  margin-bottom: 5px;
}
.draw_coupons > .coupons_right {
  display: flex;
  flex: 1;
  font-size: 12px;
  color: #666;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.draw_coupons > .coupons_right > div {
  font-size: 16px;
  color: #000;
  margin-bottom: 6px;
}

.gutter {
  background-color: #f5f5f9;
  height: 15px;
}

/* 新品上架 */

#goods {
  width: 90%;
  margin: 0 auto;
  height: 150px;
}

#goods h2 {
  color: #000;
  font-size: 16px;
}
.good-contaienr img {
  width: 100%;
  height: 168px;
}

.good-contaienr div {
  color: #333333;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
}
.good-contaienr > span {
  margin-bottom: 20px;
  font-size: 16px;
  color: #ff373f;
  display: flex;
  align-items: center;
  height: 20px;
}
/* 全部商品 */
.button-wrapper {
  display: block;
  margin-bottom: 40px;
  height: 90px;
  text-align: center;
}

/** 购物车 */
#cartNumBtn {
  position: fixed;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(255, 59, 48, 0.8);
  box-shadow: 0px 2px 4px 0px rgba(185, 185, 185, 1);
  bottom: 160px;
  right: 10px;
  text-align: center;
  line-height: 44px;
}
#cartNumBtn > img {
  width: 18px;
  height: 18px;
  vertical-align: sub;
}
#cartNumBtn > div {
  position: absolute;
  top: -8px;
  right: -3px;
  width: 15px;
  height: 15px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  border: 1px solid rgba(255, 59, 48, 1);
  color: #ff3b30;
  font-size: 12px;
  line-height: 15px;
}
/** 我的按钮 */
#meBtn {
  position: fixed;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(185, 185, 185, 0.5);
  bottom: 65px;
  right: 10px;
  text-align: center;
  line-height: 44px;
}
#meBtn > img {
  width: 18px;
  height: 18px;
  vertical-align: sub;
}
/* 弹出框样式 */
.index_popup {
  height: 100%;
}
.popup_header {
  height: 60px;
  display: flex;
  align-items: center;
}
.popup_icon {
  width: 35px;
  text-align: center;
  height: 60px;
  line-height: 60px;
}
.popup_search {
  flex: 1;
}
.popup_search > .van-search__content {
  background: #f7f7f9;
}
.popup_search > .van-search__action {
  width: 48px;
  text-align: center;
  background: #df2f36;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 8px;
}
.popup_content {
  height: calc(100% - 60px);
  overflow-y: auto;
}
.popup_content > .content_item {
  display: flex;
  height: 110px;
  align-items: center;
  padding: 0 10px;
  border-top: 1px solid #e8e8e8;
}
.popup_content > .content_item > img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
.popup_content > .content_item > .good_msg {
  flex: 1;
  height: 110px;
  color: #000000;
  font-size: 13px;
  padding: 15px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.good_msg > span {
  color: #e80000;
  font-size: 15px;
  margin-bottom: 10px;
}

/* 体验卡 */
.card_header,
.goods_header {
  height: 38px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 17px;
  border-top: 1px solid rgba(239, 239, 239, 1);
  /* border-bottom: 1px solid rgba(239, 239, 239, 1); */
  box-sizing: border-box;
  align-items: center;
}
.goods_header {
  padding: 0;
}
.card_header > .title,
.goods_header > .title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.card_header > .more,
.goods_header > .more {
  color: #727272;
  font-size: 13px;
}

.card_item {
  min-height: 112px;
  width: 100%;
  padding: 16px;
  display: flex;
  /* border-bottom: 1px solid rgba(239, 239, 239, 1); */
  box-sizing: border-box;
  align-items: center;
}
.card_item > img {
  width: 80px;
  height: 80px;
  margin-right: 14px;
}
.card_msg {
  flex: 1;
  overflow: hidden;
}
.card_msg > .msg_title {
  color: #000;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 7px;
}

.card_msg > .msg_memo {
  color: #888;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.card_msg > .msg_price {
  color: #f00;
  font-size: 16px;
  margin-top: 5px;
}
/* 立即使用 */
.coupon_btn_own {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  border-radius: 5px;
  border: 1px solid #000;
  padding: 0 10px;
  margin-right: 25px;
}
.popup_box {
  height: 100%;
}
/* 进店弹窗 */
.coupon_pop {
  width: 250px;
  min-height: 285px;
  max-height: 328px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  background: linear-gradient(180deg, rgba(255, 99, 58, 1) 0%, rgba(254, 43, 37, 1) 100%);
  border-radius: 20px;
  padding: 30px 20px;
  box-sizing: border-box;
  position: relative;
}
.coupon_pop > .title {
  font-size: 32px;
  margin-top: 4px;
  margin-bottom: 20px;
}
.coupon_pop > .coupon_detail {
  max-height: 140px;
  overflow: auto;
}
.coupon_pop > .coupon_btn {
  width: 163px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  color: rgba(106, 59, 9, 1);
  background: rgba(255, 213, 168, 1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  margin-left: 24px;
  margin-top: 20px;
}
.coupon_pop .coupon_item {
  display: flex;
  height: 60px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  margin-bottom: 12px;
  color: #e02020;
  font-size: 14px;
  align-items: center;
}
.coupon_pop .coupon_item > .item_left {
  min-width: 40%;
}
.coupon_pop .coupon_item > .item_right {
  flex: 1;
  word-break: break-word;
}
.coupon_pop_close_btn {
  position: absolute;
  z-index: 99999;
  top: calc(50% - 180px);
  right: calc(50% - 150px);
}
/* 首页右下角优惠券 */
.bottom_coupon {
  position: fixed;
  bottom: 80px;
  right: 10px;
  width: 53px;
  height: 60px;
  background: linear-gradient(180deg, rgba(255, 99, 58, 1) 0%, rgba(254, 43, 37, 1) 100%);
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-size: 10px;
  transform: scale(0.89);
  padding: 12px 0;
  box-sizing: border-box;
}
.bottom_coupon > div {
  color: #6a3b09;
  background: rgba(255, 213, 168, 1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  margin-top: 5px;
  transform: scale(0.9);
}
.coupon_bottom_close_btn {
  position: absolute;
  top: -13px;
  right: -12px;
  width: 12px;
  height: 12px;
}
/* 限时折扣 */
.limit_box {
  width: 100%;
}
.limit_header {
  display: flex;
  align-items: center;
  color: #333333;
  margin: 15px 0;
  padding: 0 20px;
  justify-content: space-between;
}
.header_msg {
  display: flex;
  align-items: center;
}
.limit_header .header_title {
  font-size: 16px;
  margin-right: 10px;
  font-weight: 500;
}
.limit_header .header_time {
  font-size: 14px;
}
.limit_header > .more {
  font-size: 13px;
  color: #727272;
  float: right;
}
/* 横向折扣 */
.limit_row {
  width: 100%;
  overflow-x: auto;
  padding: 0 20px;
  display: flex;
  box-sizing: border-box;
}
.limit_row > .product_item {
  width: 30%;
  font-size: 13px;
  color: #ff373f;
  margin-right: 3.3%;
  flex-shrink: 0;
  margin-bottom: 15px;
}
.limit_row > .product_item > .img_box {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
.img_box > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.limit_row > .product_item > .product_title {
  font-size: 13px;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}
.limit_row > .product_item > .product_price {
  font-size: 16px;
  word-break: break-all;
}
.limit_row > .product_item > .product_price > .price {
  display: block;
}
/* 纵向折扣 */
.limit_column {
  padding: 10px 20px;
}
.limit_column > .product_item {
  display: flex;
  margin-bottom: 28px;
}
.limit_column > .product_item:last-child {
  margin-bottom: 0;
}
.limit_column > .product_item > img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  border: 1px solid #f7f7f7;
}
.limit_column > .product_item > .product_msg {
  flex: 1;
  font-size: 13px;
}
.limit_column > .product_item > .product_msg > .product_title {
  font-size: 14px;
  color: #333;
}
.limit_column > .product_item > .product_msg > .product_price {
  color: #ff373f;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
}
.limit_column > .product_item > .product_msg > div:last-child {
  color: #aaaaaa;
  font-size: 12px;
}
/* banner折扣 */
.limit_banner {
  /* height: 195px; */
}
.limit_banner > img {
  width: 100%;
  height: 100%;
}
/* 三角标签 */
.product_price > .price {
  margin-right: 9px;
}
.s-triangle {
  display: inline-block;
  font-size: 10px;
  position: relative;
  color: #ff373f;
  border: 1px solid rgba(255, 55, 63, 1);
  border-radius: 2px;
  padding: 0 2px;
  box-sizing: border-box;
}

/* .triangelTag {
  display: block;
  background-color: #ff2727;
  height: 16px;
  line-height: 16px;
  text-align: center;
  min-width: 32px;
  position: relative;
  margin-left: 5px;
}

.triangelTag:before {
  content: " ";
  width: 0px;
  height: 0px;
  position: absolute;
  top: 0;
  left: -16px;
  border: 8px solid transparent;
  border-right-color: #ff2727;
} */
/* 同步font */

#goods,
#cards,
.limit_box {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
}
</style>
