<template>
  <div class="order_index">
    <van-nav-bar title="全部订单" left-arrow @click-left="onClickLeft" />
    <div class="order-box">
      <div class="order-status">
        <div>
          <span
            :class="[item.click ? 'status_item_click' : 'status_item']"
            v-for="(item, index) in order_status"
            v-bind:key="index"
            @click="clickStatus(item.status, index)"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div class="order_items">
        <div class="order-item" v-for="item in orders" v-bind:key="item.id">
          <div class="item-header" @click="clickOrder(item.id)">
            <span v-if="item.address" class="header-left">
              顾客：{{ item.address.contact_name }}
              {{ item.address.contact_phone }}
            </span>
            <span class="header-right">{{ item.order_state_zh_cn }}</span>
          </div>
          <div class="item-middle" @click="clickOrder(item.id)">
            <img v-if="item.items[0].product_snap" :src="item.items[0].product_snap.image_url" />
            <div v-if="item.items[0].product_snap">
              {{ item.items[0].product_snap.title }}
              <span v-if="item.items.length > 1">...</span>
              <div v-if="item.items.length > 1">等{{ item.items.length }}件商品</div>
            </div>
          </div>
          <div class="item-footer">
            <div class="creatTime">
              <span>创建时间</span>
              <span class="creatTime-value">{{ item.created_at }}</span>
            </div>
            <div class="cost_detail">
              <span class="express-fare" v-if="item.express_fare > 0">运费 ¥ {{ item.express_fare }}</span>
              <span class="title" v-if="item.closed">未付款</span>
              <span class="title" v-else-if="item.paid_at">实付款</span>
              <span class="title" v-else>待付款</span>
              <span class="value">￥{{ item.total_amount }}</span>
            </div>
            <van-popup
              class="vanPopup"
              v-model="show"
              :style="{ height: '50%', width: '70%', display: 'flex' }"
              closeable
              ><div class="vueQr">
                <vue-qr
                  :text="downloadData.url"
                  :margin="0"
                  colorDark="#000000"
                  colorLight="#fff"
                  :logoScale="0.3"
                  :size="200"
                ></vue-qr></div
            ></van-popup>
            <button v-if="item.order_state_zh_cn == '待核销'" @click.stop="showPopup(item)" class="footer_btn">
              核销码
            </button>
            <button
              v-if="item.order_status == 'delivered'"
              @click.stop="openDialog(item.id, '是否确认收货？', 'receivedOrder')"
              class="footer_btn"
            >
              确认收货
            </button>

            <button
              v-if="item.order_state_zh_cn == '待付款'"
              @click.stop="wechatPay(item)"
              class="footer_btn wechatPay_btn"
            >
              去支付
            </button>
            <button
              v-if="item.order_state_zh_cn == '待付款'"
              @click.stop="openDialog(item.id, '是否取消订单？', 'cancelOrder')"
              class="footer_btn"
            >
              取消订单
            </button>
          </div>
        </div>
        <div class="order-none" v-if="orders.length == 0">暂无订单</div>
      </div>
    </div>
    <van-dialog
      v-model="dialogShow"
      show-cancel-button
      :message="dialogOptions.message"
      cancel-button-text="否"
      @confirm="onConfirm"
    />
  </div>
</template>
<script>
import { getOrders, received, cancel, wechatPay, payWithoutMoney } from '../../services/order'
import { dateToStr } from '../../utils/convert'
import { Toast } from 'vant'
import wechat from '../../utils/wechat'
import { fetchCompanyDetail } from '@/services/company'
import vueQr from 'vue-qr'

export default {
  components: {
    vueQr,
  },
  data: function () {
    return {
      company_id: null,
      show: false,
      orders: [],
      downloadData: {
        url: 'www.baidu.com',
      },
      order_status: [
        { status: '', name: '全部', click: true },
        { status: 'not_paid', name: '待付款', click: false },
        { status: 'unverify', name: '待核销', click: false },
        { status: 'pending', name: '待发货', click: false },
        { status: 'delivered', name: '已发货', click: false },
        { status: 'received', name: '已完成', click: false },
        { status: 'closed', name: '已取消', click: false },
        { status: 'refund', name: '售后', click: false },
      ],
      dialogShow: false,
      selectOrderId: null,
      selectOrderStatus: '',
      dialogOptions: {
        message: '',
        onConfirm: '',
      },
      isMiniApp: false,
      currentPage: 1,
      lastPage: 10,
      isLoading: false,
    }
  },
  mounted: function () {
    this.company_id = this.$route.query.company_id
    this.currentPage = 1
    this.lastPage = 10
    let query = this.$route.query
    let status = query.status || ''
    this.selectOrderStatus = status
    this.order_status.forEach((c, i) => {
      c.click = c.status == status ? true : false
    })
    this.getOrders(status)
    this.checkIsMiniApp()
    this.fetchCompanyInfo()
    // 添加滚动事件，检测滚动到页面底部
    window.addEventListener('scroll', this.scrollBottom, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollBottom)
  },
  methods: {
    showPopup(item) {
      this.downloadData.url =
        'pages/customer/employee_order/list/index?scene=' +
        item.phone +
        '&orderId=' +
        item.id +
        '&time=' +
        this.getTimer('YYYY-mm-dd HH:MM:SS', new Date().valueOf())
      console.log(this.downloadData, item)
      this.show = true
      return
    },
    getTimer(fmt, time) {
      const date = new Date(parseInt(time))
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
      }
      for (let k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    scrollBottom(e) {
      if (e.target.scrollTop + e.target.clientHeight + 5 >= e.target.scrollHeight) {
        //在此处放入你的加载更多方法
        if (this.currentPage >= this.lastPage) {
          Toast('已经加载全部订单')
          return
        } else {
          this.currentPage++
          this.getOrders(this.selectOrderStatus)
        }
      }
    },
    getOrders(status = '') {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      getOrders(status, this.company_id, this.currentPage).then((resp) => {
        this.orders = [...this.orders, ...resp.data.data]
        this.currentPage = resp.data.meta.current_page
        this.lastPage = resp.data.meta.last_page
        this.isLoading = false
        this.orders.forEach((c) => {
          c.order_state_zh_cn = this.getOrderStatus(c)
          c.created_at = dateToStr(new Date(c.created_at))
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    clickOrder(id) {
      this.$router.push({
        path: '/order/detail',
        query: { id, company_id: this.company_id },
      })
    },
    clickStatus(status, idx) {
      this.order_status.forEach((c, i) => {
        c.click = i == idx ? true : false
      })
      this.currentPage = 1
      this.lastPage = 10
      this.selectOrderStatus = status
      this.orders = []
      this.getOrders(status)
      this.$router.push({
        path: '/order',
        query: {
          status,
          company_id: this.company_id,
        },
      })
    },
    openDialog(id, message, onConfirm) {
      this.selectOrderId = id
      this.dialogShow = true
      this.dialogOptions = {
        message: message,
        onConfirm: onConfirm,
      }
    },
    onConfirm() {
      switch (this.dialogOptions.onConfirm) {
        case 'receivedOrder':
          this.receivedOrder()
          break
        case 'cancelOrder':
          this.cancelOrder()
          break
      }
    },
    receivedOrder() {
      received(this.selectOrderId)
        .then((resp) => {
          Toast('确认收货成功')
          this.getOrders(this.selectOrderStatus)
          this.selectOrderId = null
        })
        .catch((error) => {
          Toast('确认收货失败')
        })
    },
    cancelOrder() {
      cancel(this.selectOrderId)
        .then((resp) => {
          Toast(resp.data.message)
          this.getOrders(this.selectOrderStatus)
          this.selectOrderId = null
        })
        .catch((error) => {
          Toast('订单取消失败，请刷新重试')
        })
    },
    getOrderStatus(item) {
      let status
      const ship_status = {
        pending: '待发货',
        delivered: '已发货',
        received: '已完成',
        unverify: '待核销',
      }
      const refund_status = {
        pending: '未退款',
        applied: '已申请退款',
        processing: '退款中',
        success: '退款成功',
        failed: '退款失败',
      }
      if (!item.closed) {
        if (item.paid_at) {
          if (item.refund_status !== 'pending') {
            status = refund_status[item.refund_status]
          } else {
            status = ship_status[item.order_status]
          }
        } else {
          status = '待付款'
        }
      } else {
        status = '已取消'
      }
      return status
    },
    wechatPay(order) {
      if (order.total_amount == 0) {
        return payWithoutMoney(order.id)
          .then((res) => {
            Toast(res.data.message)
            this.getOrders(this.selectOrderStatus)
          })
          .catch((error) => {
            if (error === undefined) return Promise.reject()
            console.error(error)
            switch (error.response.status) {
              case 422:
                Toast(`错误码：${error.response.data.code}，错误信息：${error.response.data.message}`)
                break
              default:
                toastHttpUnKnownError(error)
                break
            }
          })
      }
      return wechatPay(order.id)
        .then((res) => {
          let pay_resp = res.data
          let payParam = { orderId: order.id, ...pay_resp }

          if (this.isMiniApp) {
            let url = '/pages/customer/pay/index?payParam=' + encodeURIComponent(JSON.stringify(payParam))
            return wechat.naviToMiniApp(url)
          } else {
            wechat.invokePayment(payParam, () => this.getOrders(this.selectOrderStatus))
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              Toast('订单状态不正确')
              break
            default:
              toastHttpUnKnownError(error)
              break
          }
        })
    },
    checkIsMiniApp() {
      wechat.checkIsMiniApp().then(
        (res) => (this.isMiniApp = res),
        (rej) => ''
      )
    },
    fetchCompanyInfo: async function () {
      let resp = await fetchCompanyDetail(this.company_id)
      let data = resp.data
      document.title = data.name || '优选商城'
    },
  },
}
</script>
<style scoped>
.vanPopup {
  justify-content: center;
  align-items: center;
}
.order_index {
  width: 100%;
  height: 100%;
}
.order-box {
  height: calc(100% - 46px);
  background: #f5f5f9;
  padding-top: 1px;
}

.order-status {
  margin-top: 10px;
  height: 46px;
  background: #ffffff;
  font-size: 14px;
  overflow-x: auto;
}

.order-status::-webkit-scrollbar {
  display: none;
}

.order-status .status_item_click {
  color: #ff0000;
}

.order-status .status_item_click:after {
  content: '';
  position: absolute;
  width: 46px;
  height: 2px;
  background: #ff0000;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.order-status .status_item {
  color: #333;
}

.order-status > div {
  width: 550px;
}
.order-status span {
  position: relative;
  width: 46px;
  height: 100%;
  line-height: 46px;
  padding: 0 16px;
  text-align: center;
  display: inline-block;
}
.order_items {
  height: calc(100% - 46px);
  overflow-y: auto;
}

.order-item {
  margin-top: 15px;
}

.order-item > .item-header {
  height: 44px;
  background: white;
  line-height: 44px;
}

.item-header > .header-left {
  font-size: 14px;
  margin-left: 10px;
}

.item-header > .header-right {
  float: right;
  margin-right: 10px;
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

.order-item > .item-middle {
  min-height: 92px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.item-middle > img {
  width: 72px;
  height: 72px;
  margin-right: 10px;
}

.item-middle > div {
  font-size: 14px;
  color: #000;
}

.order-item > .item-footer {
  min-height: 74px;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.item-footer > .creatTime {
  font-size: 12px;
  color: #888;
  margin-bottom: 15px;
}
.creatTime > .creatTime-value {
  float: right;
}

.item-footer > .cost_detail {
  width: 100%;
  text-align: right;
}

.item-footer > .cost_detail > .express-fare {
  margin-right: 12px;
  font-size: 12px;
  color: #666;
}

.item-footer > .cost_detail > .title {
  font-size: 14px;
  color: #333;
}

.item-footer > .cost_detail > .value {
  font-size: 18px;
  color: #000;
}

.item-footer > .footer_btn {
  width: 100px;
  height: 30px;
  color: #ff373f;
  font-size: 16px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid rgba(255, 55, 63, 1);
  float: right;
  margin-top: 8px;
}
.item-footer > .wechatPay_btn {
  background: linear-gradient(to right, #ff6034, #ee0a24);
  color: #fff;
  margin-left: 15px;
}

.order-none {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
