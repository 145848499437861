<template>
  <div id="address-list">
    <van-nav-bar title="管理收货地址" left-arrow @click-left="onClickLeft" />
    <div>
      <van-address-list
        v-model="selectedId"
        :list="items"
        :switchable="action == 'select'"
        @select="onSelect"
        @add="onAdd"
        @edit="onEdit"
      />
    </div>
  </div>
</template>
<script>
import { list } from '../../services/address'
import { fetchCompanyDetail } from '@/services/company'

const actions = {
  list: 'list',
  select: 'select',
}

export default {
  data() {
    return {
      items: [],
      selectedId: null,
    }
  },
  mounted: function () {
    this.initPage()
    this.fetchCompanyInfo()
  },
  activated: function () {
    this.initPage()
  },
  methods: {
    onClickLeft() {
      localStorage.removeItem('salesroom')
      this.$router.go(-1)
    },
    initPage() {
      this.fetchData()
      this.company_id = this.$route.query.company_id
      this.selectedId = parseInt(this.$route.query.selectedId)
    },
    onAdd() {
      let latitude = this.$route.query.latitude
      let longitude = this.$route.query.longitude
      this.$router.push(`/address/create?company_id=${this.company_id}&latitude=${latitude}&longitude=${longitude}`)
    },
    onEdit(_, index) {
      this.$router.push(`/address/edit/${this.items[index].id}?company_id=${this.company_id}`)
    },
    onCreate() {
      this.$router.push(`/address/create?company_id=${this.company_id}`)
    },
    onSelect(item, index) {
      localStorage.removeItem('salesroom')
      localStorage.setItem('addressId', item.id)
      this.$router.go(-1)
    },
    fetchData() {
      list().then((resp) => {
        this.items = []
        this.items = resp.data.map((item) => {
          return {
            id: item.id,
            name: item.contact_name,
            tel: item.contact_phone,
            address: item.full_address,
          }
        })
      })
    },
    fetchCompanyInfo: async function () {
      let resp = await fetchCompanyDetail(this.$route.query.company_id)
      let data = resp.data
      document.title = data.name || '优选商城'
    },
  },
  computed: {
    action: function () {
      return this.$route.query.is_select_address ? actions.select : actions.list
    },
    showAddNewBtn: function () {
      return this.action == actions.select
    },
  },
}
</script>
<style scoped>
#address-list {
  height: 100%;
  background: #f5f5f9;
}
.add-new {
  bottom: 60px;
}
button {
  bottom: 30px;
}
.van-address-list__bottom {
  background-color: #f5f5f9;
  padding: 5px 12px;
}
</style>
