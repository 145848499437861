<template>
  <van-tabbar
    :style="tabShow ? 'display:flex' : 'display:none'"
    :safe-area-inset-bottom="true"
    active-color="#FF0000"
    v-model="idx"
  >
    <van-tabbar-item name="index" :to="'/?company_id=' + company_id">
      <span>首页</span>
      <img slot="icon" slot-scope="props" :src="props.active ? icon[0].active : icon[0].inactive" />
    </van-tabbar-item>
    <van-tabbar-item name="goods" :to="'/goods?company_id=' + company_id">
      <span>分类</span>
      <img slot="icon" slot-scope="props" :src="props.active ? icon[1].active : icon[1].inactive" />
    </van-tabbar-item>
    <van-tabbar-item name="cart" :info="cartNum" :to="'/cart?company_id=' + company_id">
      <span>购物车</span>
      <img slot="icon" slot-scope="props" :src="props.active ? icon[2].active : icon[2].inactive" />
    </van-tabbar-item>
    <van-tabbar-item name="me" :to="'/me?company_id=' + company_id">
      <span>我的</span>
      <img slot="icon" slot-scope="props" :src="props.active ? icon[3].active : icon[3].inactive" />
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { checkAuthentication } from '../services/auth'
import { fetchGoods } from '@/services/cart'
export default {
  props: {
    company_id: Number,
    active: String,
  },
  data() {
    return {
      idx: 'index',
      cartNum: 0,
      tabShow: true,
      icon: [
        {
          inactive: require('@/assets/footer/index.png'),
          active: require('@/assets/footer/index_active.png'),
        },
        {
          inactive: require('@/assets/footer/goods.png'),
          active: require('@/assets/footer/goods_active.png'),
        },
        {
          inactive: require('@/assets/footer/cart.png'),
          active: require('@/assets/footer/cart_active.png'),
        },
        {
          inactive: require('@/assets/footer/me.png'),
          active: require('@/assets/footer/me_active.png'),
        },
      ],
    }
  },
  activated() {
    this.idx = this.active || 'goods'
  },
  created() {
    if (this.active) {
      this.idx = this.active || 'goods'
    }
    const urlParams = new URLSearchParams(window.location.search)
    if (typeof wx != 'undefined') {
      wx.miniProgram.getEnv((res) => {
        this.tabShow = !res.miniprogram
      })
    }
    if (checkAuthentication() && !urlParams.get('code')) {
      fetchGoods().then((resp) => {
        this.cartNum = resp.data.length
      })
    }
  },
}
</script>

<style scoped></style>
