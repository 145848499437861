<template>
  <div id="good-coupon">
    <van-nav-bar title="优惠券" left-arrow @click-left="onClickLeft" />
    <div id="goods_container">
      <header>
        <div class="coupon_content">
          <div class="coupon_item">
            <div class="coupon_price">
              <div v-if="coupon.type == 'fixed'">{{ coupon.value }}元</div>
              <div v-else>{{ coupon.value }}折</div>
              <span>{{ coupon.description }}</span>
            </div>
            <div class="coupon_type">
              <div>{{ coupon.name }}</div>
              <span>{{ coupon.not_before }} - {{ coupon.not_after }}</span>
            </div>
            <div v-if="isShare !== 'true' || coupon.limit - coupon.own == 0" class="coupon_btn_own">已领取</div>
            <div v-else v-on:click="receiveCoupon" class="coupon_btn_normal">立即领取</div>
          </div>
        </div>
        <div style="font-size: 14px; color: #333; margin-bottom: 26px; text-align: center">
          - 以下商品可用于优惠券 -
        </div>
      </header>
      <!-- 条件筛选 -->
      <div id="category" class="row">
        <div v-if="!collapseActive" class="goods-top">
          <div class="bubble-container">
            <van-tabs :border="false" line-width="14" @click="tabClick" title-inactive-color="#333">
              <van-tab name="id_asc" title="综合"></van-tab>
              <van-tab name="created_at" title="新品"></van-tab>
              <van-tab name="sold_count_asc" title="销量"></van-tab>
              <van-tab name="price_asc">
                <template #title>
                  <div :class="price_top ? 'price_tab price_top' : 'price_tab price_bottom'">价格</div>
                </template>
              </van-tab>
            </van-tabs>
          </div>
        </div>
        <div @click="filterClick" :class="category_name ? 'goods-filter active' : 'goods-filter'">
          <div>{{ category_name || '筛选' }}</div>
          <img :src="category_name ? imgs.filter_activeImg : imgs.filterImg" alt />
        </div>
        <van-popup get-container="body" v-model="popupShow" position="right" class="filter_popup">
          <div class="filter_content">
            <div class="content_title">种类</div>
            <div class="categories_filter">
              <div
                :class="{ item_active: category_ids.includes(item.id) }"
                v-on:click="changeCategory(item.id)"
                v-for="item in categories"
                :key="item.id"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="content_title" style="margin-top: 39px">品牌</div>
            <div class="categories_filter">
              <div
                :class="{ item_active: brand_ids.includes(item.id) }"
                @click="changeBrand(item.id)"
                v-for="item in brands"
                :key="item.id"
              >
                {{ item.name_zh }}
              </div>
            </div>
          </div>
          <div class="filter_footer">
            <van-button class="clear_btn" type="default" @click="clear">清空</van-button>
            <van-button class="submit_btn" type="danger" @click="submit_filter">确认</van-button>
          </div>
        </van-popup>
      </div>
      <!-- 商品列表 -->
      <div id="good_box">
        <div class="good_item" v-for="item in goods" :key="item.id">
          <img @click="toGood(item)" :src="item.image_url" alt />
          <div @click="toGood(item)" class="item_msg">
            <div class="msg_title">{{ item.title }}</div>
            <div class="msg_memo">{{ item.memo }}</div>
            <div class="msg_price">
              <span>¥</span>
              {{ item.price }}
            </div>
          </div>
          <img v-if="item.type == 'product'" @click="createSku(item)" class="cart_btn" src="@/assets/cart.png" />
        </div>
      </div>
    </div>

    <!-- 底部栏 -->
    <footer class="cart_box">
      <div class="cart_msg">
        <div>
          <span>总计：</span>
          <span class="cart_price">¥{{ remainder ? totalPrice : totalPrice - coupon.value }}</span>
        </div>
        <span>{{ remainder ? `还差${remainder}元使用优惠` : `已优惠${coupon.value}元` }}</span>
      </div>
      <van-button style="width: 90px" type="danger" size="small" @click="goCart">去购物车</van-button>
    </footer>
    <van-sku
      v-model="showSkuModel"
      :sku="sku"
      :goods="skuGoodsInfo"
      :goods-id="selectItem.id"
      :hide-stock="false"
      :quota="0"
      :quota-used="selectItem.sold_count"
      :reset-stepper-on-hide="false"
      :reset-selected-sku-on-hide="false"
      :close-on-click-overlay="true"
      :disable-stepper-input="false"
      :show-add-cart-btn="false"
      @buy-clicked="addCart"
      buy-text="确定"
    />
  </div>
</template>
<script>
import filter_activeImg from '@/assets/filter_active.png'
import filterImg from '@/assets/filter.png'
import { dateToStr, getTimer } from '@/utils/convert'
import { toastHttpUnKnownError } from '@/utils/errors'
import wechat from '@/utils/wechat'
import { brandList, categoriesOwnGoods, goodsList } from '@/services/goods'
import { getOrders } from '@/services/order'
import { addCart } from '@/services/cart'
import { couponDetail, receiveCoupon } from '@/services/coupon'
import { backOrHome } from '@/services/navigator'
import { Toast } from 'vant'

export default {
  data: function () {
    return {
      isShare: null,
      selectItem: {
        id: null,
        sold_count: 0,
      },
      showSkuModel: false,
      coupon: {},
      skuGoodsInfo: {
        title: '',
        picture: '',
      },
      price_top: true,
      brand_id: null, // 用于标识只显示单一品牌
      brand_ids: [],
      category_ids: [],
      brands: [],
      categories: [],
      goods: [],
      company_id: 1,
      collapseActive: false,
      popupShow: false,
      search_goods: [],
      imgs: {
        filterImg: '',
        filter_activeImg: '',
      },
      category_name: '',
      order: 'price_asc',
      sku: {
        tree: [],
        list: [],
        price: '0.00',
        stock_num: 0,
        collection_id: 0,
        none_sku: false,
        messages: [],
        hide_stock: false,
      },
      totalPrice: 0,
      isOldUser: false,
    }
  },
  mounted: function () {
    this.imgs = {
      filterImg,
      filter_activeImg,
    }
    this.company_id = this.$route.query.company_id
    this.coupon = JSON.parse(this.$route.query.item)
    this.isShare = this.$route.query.isShare
    this.requestForCategory()
    this.requestForBrands()
    this.requestForList()
    this.configJSJDK()
    this.getOrders()
    // 分享的 重新获取优惠券状态
    if (this.isShare == 'true') {
      couponDetail(this.coupon.code).then((resp) => {
        const { own, limit, not_after, not_before } = resp.data
        this.coupon = resp.data
        this.coupon.not_after = not_after ? getTimer('YYYY.mm.dd', not_after) : ''
        this.coupon.not_before = not_before ? getTimer('YYYY.mm.dd', not_before) : ''
        if (limit - own == 0) {
          this.isShare == 'false'
        }
      })
    }
  },
  methods: {
    onClickLeft() {
      backOrHome(this.company_id)
    },
    requestForCategory: function () {
      categoriesOwnGoods(this.company_id)
        .then((resp) => {
          let items = []
          for (let [key, value] of Object.entries(resp.data)) {
            items.push(value)
          }
          this.categories = [...this.categories, ...items]
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    },
    requestForBrands: function () {
      brandList(this.company_id)
        .then((resp) => {
          this.brands = [...this.brands, ...resp.data]
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    },
    requestForList: function () {
      goodsList(
        this.company_id,
        this.brand_ids,
        this.category_ids,
        1,
        this.order,
        '',
        this.coupon.available_products,
        'all'
      )
        .then((res) => {
          res.data.data.forEach((item) => {
            item.image_url = item.image_url + '?x-oss-process=image/resize,w_320,h_320'
          })

          this.goods = []
          this.goods = [...res.data.data]
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    },
    configJSJDK() {
      let title = `送你一张超值优惠券，${this.coupon.description ? this.coupon.description + '，' : ''}快来领取吧！`
      let description = `快来领取和选购吧～`
      let imgUrl = `https://shanyan-bucket.oss-cn-shenzhen.aliyuncs.com/fxmall-images/coupon_head.png`
      let url = document.URL
      if (typeof wx != 'undefined') {
        wechat.configJSSDK(url).then(() => {
          wx.ready(() => {
            wechat.configAppMessageShareData(title, description, url + `&isShare=true`, imgUrl)
            wechat.configTimelineShareData(title, url + `&isShare=true`, imgUrl)
          })
        })
      }
    },
    changeCategory: function (id) {
      let category_ids = this.category_ids
      if (category_ids.includes(id)) {
        let idx = category_ids.indexOf(id)
        category_ids.splice(idx, 1)
        this.category_ids = category_ids
      } else {
        this.category_ids.push(id)
      }
    },
    changeBrand(id) {
      let brands_ids = this.brand_ids
      if (brands_ids.includes(id)) {
        let idx = brands_ids.indexOf(id)
        brands_ids.splice(idx, 1)
        this.brand_ids = brands_ids
      } else {
        this.brand_ids.push(id)
      }
    },
    toGood: function ({ type, id }) {
      let path = `/goods/${id}?company_id=${this.company_id}${type == 'product' ? '' : '&isCard=1'}`
      this.$router.push(path)
    },
    addCart(data) {
      let selectedSkuId = data.selectedSkuComb.id
      let amount = data.selectedNum
      this.showSkuModel = false
      this.selectedSku = this.selectItem.skus.filter((item) => {
        return item.id == selectedSkuId
      })[0]
      this.totalPrice = this.totalPrice + this.selectedSku.price * amount
      addCart(selectedSkuId, amount)
        .then((resp) => {
          Toast(resp.data.message)
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    },
    goCart: function () {
      this.$router.push(`/cart?company_id=${this.company_id}`)
    },
    // 条件筛选
    tabClick(name, title) {
      if (name == 'price_asc') {
        this.order = this.price_top ? 'price_desc' : name
        this.price_top = !this.price_top
        this.requestForList()
        return
      }
      this.price_top = true
      if (name == 'created_at') {
        let goods = this.goods
        goods.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at)
        })
        this.goods = goods
        return
      }
      if (this.order !== name) {
        this.order = name
        this.requestForList()
      }
    },
    filterClick() {
      this.popupShow = true
    },
    submit_filter() {
      this.popupShow = false
      this.category_name =
        this.category_ids.length > 0 ? this.categories.filter((c) => this.category_ids.includes(c.id))[0].name : ''
      this.requestForList()
    },
    clear() {
      this.category_ids = []
      this.brand_ids = []
      this.order = 'price_asc'
    },
    // 规格器
    createSku(resp) {
      const { title, skus, image_url, price, id } = resp
      let skuTreeValue = skus.map((item) => {
        return {
          id: item.id,
          name: item.title,
          imgUrl: image_url,
          previewImgUrl: image_url,
        }
      })
      let skuList = skus.map((item) => {
        return {
          id: item.id,
          price: item.price * 100,
          s1: item.id,
          stock_num: item.stock,
        }
      })
      let stock_num = skus
        .map((item) => {
          return item.stock
        })
        .reduce((a, b) => a + b, 0)
      this.sku = {
        tree: [
          {
            k: '',
            v: skuTreeValue,
            k_s: 's1',
          },
        ],
        list: skuList,
        price,
        stock_num: stock_num,
        collection_id: id,
      }
      this.skuGoodsInfo = {
        title,
        picture: image_url,
      }
      this.selectItem = resp
      this.showSkuModel = true
    },

    receiveCoupon: function () {
      let { id, own, limit, target } = this.coupon
      const isOldUser = this.isOldUser
      if (target !== 'all') {
        if (target == 'old_customers' && !isOldUser) {
          Toast('仅限老顾客领取')
          return
        }
        if (target == 'new_customer' && isOldUser) {
          Toast('仅限新顾客领取')
          return
        }
      }
      if (limit - own !== 0) {
        receiveCoupon(id)
          .then((resp) => {
            this.coupon.own = own++
            Toast(resp.data.message)
          })
          .then((error) => {
            Toast(error.message)
          })
      }
    },
    getOrders() {
      getOrders('received', this.company_id).then(({ data }) => {
        let orders = data.data
        this.isOldUser = orders.length > 0 ? true : false
      })
    },
  },
  computed: {
    remainder: function () {
      let val = (this.coupon.min_amount - this.totalPrice).toFixed(2)
      return val < 0 ? 0 : val
    },
  },
}
</script>
<style scoped>
#good-coupon {
  width: 100%;
  height: 100%;
}
/*
header {
  margin-top: 10px;
} */

header .row {
  height: 50px;
  width: 100%;
  align-content: center;
  margin-top: 10px;
}

header .row:first-child {
  margin-top: 0;
  position: relative;
}

#category {
  background: #fff;
  position: sticky;
  top: 0;
  display: flex;
  height: 45px;
  border-bottom: 1px solid #ebedf0;
  z-index: 999;
}

header #category label {
  color: #363636;
  margin: 0 20px;
  height: 50px;
  line-height: 50px;
  float: left;
}
.goods-top {
  width: 70%;
  background: #fff;
  padding-left: 10px;
}
.good-select {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.goods-filter {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}
.goods-filter > img {
  width: 14px;
  height: 14px;
  margin-right: 22px;
  margin-left: 2px;
}
.filter_popup {
  height: 100vh;
  width: 80vw;
}
.filter_content {
  height: calc(100vh - 44px);
  padding: 20px 22px 0 22px;
  box-sizing: border-box;
  overflow-y: auto;
}

.content_title {
  font-size: 14px;
  color: #333;
  padding-left: 8px;
  margin-bottom: 21px;
  font-weight: 500;
}
.categories_filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.categories_filter > div {
  min-width: 20vw;
  margin-right: 2vw;
  margin-bottom: 8px;
  height: 30px;
  background: rgba(243, 242, 242, 1);
  border-radius: 2px;
  border: 1px solid rgba(243, 242, 242, 1);
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #363636;
}
.categories_filter > .item_active {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(224, 32, 32, 1);
  color: #e02020;
}
.clear_btn {
  width: 35%;
}
.submit_btn {
  width: 65%;
}
.active {
  color: #df2f36;
}
header .wrap {
  margin: 0 20px;
  width: unset;
}

.row label {
  height: 50px;
  line-height: 50px;
  font-size: 12px;
}

.row .left {
  color: #363636;
  margin-right: 15px;
  float: left;
}

.row .right {
  color: #333333;
}

#brand-logo {
  margin-top: 7.5px;
  height: 35px;
  float: left;
}

#brand-name {
  float: left;
  margin-left: 10px;
}

#brand-navigator {
  float: right;
  height: 50px;
  line-height: 50px;
}

.bubble-container,
.company-container {
  overflow-x: auto;
  scrollbar-width: none;
}

.bubble-container ul {
  white-space: nowrap;
  margin-top: 10px;
}

.company-container {
  height: 120px;
  background: #f9f9f9;
}

.company-container ul {
  margin-top: 15px;
  white-space: nowrap;
}

.company-container li {
  height: 90px;
  width: 100px;
  background: white;
  display: inline-block;
  margin-right: 10px;
  overflow: hidden;
}

.company-container img {
  height: 50px;
  width: 50px;
  display: block;
  margin: 10px auto;
}

.company-container h6 {
  text-align: center;
  font-weight: lighter;
}

.active-company {
  border-bottom: 1px black solid;
  height: 89px !important;
}

.bubble {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  background-color: #f4f4f4;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 20px;
  font-weight: lighter;
}
.collapse-bubble {
  min-width: 20%;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  border-radius: 15px;
  border: 1px solid rgba(211, 211, 211, 1);
  color: #363636;
  margin-left: 4%;
  margin-bottom: 12px;
}
.collapse-ul {
  display: flex;
  flex-wrap: wrap;
}

.active-bubble {
  color: #fff;
  background-color: #ee0a24;
  border: none;
}
/* 优惠券 */
.coupon_content {
  padding: 0 22px;
  margin-bottom: 30px;
}
.coupon_item {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  margin-top: 20px;
  background: rgba(254, 244, 244, 1);
  border-radius: 3px;
}
.coupon_title {
  color: #858585;
  font-size: 14px;
  margin-top: 15px;
}
.coupon_price {
  line-height: 22px;
  margin-right: 20px;
  text-align: center;
}
.coupon_price > div {
  color: #ff373f;
  font-size: 24px;
}
.coupon_price > span {
  color: #e02020;
  font-size: 8px;
}
.coupon_type {
  flex: 1;
}
.coupon_type > div {
  color: #000000;
  font-size: 14px;
  margin-bottom: 3px;
}
.coupon_type > span {
  color: #858585;
  font-size: 10px;
}
.coupon_btn_own {
  font-size: 12px;
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  color: #ffa8a8;
  border-radius: 50%;
  border: 1px dotted rgba(255, 168, 168, 1);
}
.coupon_btn_normal {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #fff;
  background: rgba(255, 55, 63, 1);
  border-radius: 12px;
  padding: 0 10px;
}

/* 商品列表 */

/* 弹出框样式 */

/* 价格箭头 */
.price_tab {
  position: relative;
}
.price_tab::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  top: 16px;
  right: 6px;
}
.price_tab::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  bottom: 16px;
  right: 6px;
}
.price_top::after {
  border-bottom: 4px solid #333;
}
.price_top::before {
  border-top: 4px solid #dedede;
}
.price_bottom::after {
  border-bottom: 4px solid #dedede;
}
.price_bottom::before {
  border-top: 4px solid #333;
}
/* 商品列表 */
#good_box {
  z-index: 998;
}
#goods_container {
  height: calc(100% - 122px);
  overflow: auto;
}
.good_item {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  margin-bottom: 10px;
  position: relative;
}
.good_item > img {
  width: 120px;
  height: 120px;
  margin-right: 10px;
  align-self: flex-start;
}
.item_msg {
  flex: 1;
}
.msg_title {
  font-size: 14px;
  color: #333333;
  margin-bottom: 5px;
}
.msg_memo {
  font-size: 13px;
  color: #666666;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.msg_price {
  font-size: 16px;
  color: #ff373f;
}
.msg_price > span {
  font-size: 13px;
}

.good_item > .cart_btn {
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0;
  right: 20px;
  padding: 10px;
  z-index: 2;
}
/* 底部栏 */
.cart_box {
  padding: 10px 30px;
  width: 100%;
  border-top: 1px solid rgba(222, 212, 212, 1);
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.cart_msg > div {
  color: #000000;
  font-size: 14px;
}
.cart_msg > span {
  color: #858585;
  font-size: 12px;
}
.cart_price {
  color: #ff373f;
  font-size: 24px;
}
</style>
