import http from '../utils/http'
import api from '../utils/api'

export function fetchIndexBanners(company_id) {
  return http.get(api.banner.index(company_id))
}

export function fetchBannerDetail(banner_id) {
  return http.get(api.banner.detail(banner_id))
}
