<template>
  <div id="me-box">
    <van-nav-bar title="个人中心" left-arrow @click-left="onClickLeft" />
    <div id="me-info">
      <img :src="info.avatar" />
      <div>
        <span class="nickname">{{ info.nick_name }}</span>
        <span class="location">{{ info.province }} {{ info.city }}</span>
      </div>
    </div>
    <div id="me-order" @click="goToOrder()">
      <span>我的订单</span>
      <span class="checkall">
        查看全部
        <img src="../../assets/right.png" />
      </span>
    </div>
    <div id="me-order_show">
      <div class="order_status" @click="goToOrder(0)">
        <img src="../../assets/obligation.png" alt />
        <span>待付款</span>
        <div v-if="ordersNums.not_paid > 0">{{ ordersNums.not_paid }}</div>
      </div>
      <div class="order_status" @click="goToOrder(1)">
        <img src="../../assets/padding_send.png" alt />
        <span>待发货</span>
        <div v-if="ordersNums.pending > 0">{{ ordersNums.pending }}</div>
      </div>
      <div class="order_status" @click="goToOrder(2)">
        <img src="../../assets/issend.png" alt />
        <span>已发货</span>
        <div v-if="ordersNums.delivered > 0">{{ ordersNums.delivered }}</div>
      </div>
      <div class="order_status" @click="goToOrder(3)">
        <img src="../../assets/refund.png" alt />
        <span>退款/售后</span>
        <div v-if="ordersNums.refund > 0">{{ ordersNums.refund }}</div>
      </div>
    </div>
    <div id="me-coupon">
      <div>我的优惠券</div>
      <div class="coupon-box">
        <div class="coupon-item" v-for="(item, index) in coupons" :key="index" @click="isDrawClick(item.id)">
          <div class="name">{{ item.name }}</div>
          <div>
            <span v-if="item.type == 'fixed'">￥</span>
            <span class="num">{{ item.value }}</span>
            <span v-if="item.type != 'fixed'">%</span>
            <span class="des">{{ item.description }}</span>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet style="height: 80%" v-model="isdrawCoupons" title="优惠券" :round="false">
      <div @click="goToGoodCoupon" class="draw_coupons">
        <div class="coupons_left">
          <div v-if="drawCoupon.type == 'fixed'">{{ drawCoupon.value }}元</div>
          <div v-else>{{ drawCoupon.value }}折</div>
          <span>{{ drawCoupon.description }}</span>
        </div>
        <div class="coupons_right">
          <div>{{ drawCoupon.name }}</div>
          <span>{{ startAt }} - {{ endAt }}</span>
        </div>
        <div class="coupon_btn_own">立即使用</div>
      </div>
    </van-action-sheet>
    <div @click="goToAddress()" id="me-address">
      <div>我的收货地址</div>
      <img src="../../assets/right.png" />
    </div>
    <Footer active="me" :company_id="parseInt(company_id)" />
  </div>
</template>
<script>
import { getInfo } from '@/services/me'
import { fetchMyCoupons } from '@/services/coupon'
import { getOrderNumbers } from '@/services/order'
import { Toast } from 'vant'
import Footer from '../Footer'
export default {
  data: function () {
    return {
      company_id: null,
      info: {},
      coupons: [],
      ordersNums: {},
      isdrawCoupons: false,
      drawCoupon: {
        name: '',
        value: '',
        descridescription: '',
        not_before: null,
        not_after: null,
      },
    }
  },
  components: {
    Footer,
  },
  mounted: function () {
    const { company_id } = this.$route.query
    this.company_id = company_id
    if (this.company_id == null) {
      this.$router.push('/errors/404')
      return
    }
    this.getInfo()
    this.getCoupon()
    this.getOrderNums(company_id)
  },

  activated: function () {
    this.isdrawCoupons = false
  },
  methods: {
    getInfo() {
      getInfo().then((resp) => {
        this.info = resp.data
      })
    },
    getCoupon() {
      fetchMyCoupons(this.company_id).then((resp) => {
        this.coupons = resp.data.data
      })
    },
    getOrderNums(id) {
      getOrderNumbers(id).then((resp) => {
        this.ordersNums = resp.data
      })
    },
    goToOrder(status = 5) {
      const click_status = ['not_paid', 'pending', 'delivered', 'refund']
      if (status < 5) {
        this.$router.push({
          path: '/order',
          query: {
            status: click_status[status],
            company_id: this.company_id,
          },
        })
      } else {
        this.$router.push(`/order?company_id=${this.company_id}`)
      }
    },
    goToGoodCoupon: function () {
      this.$router.push({
        path: `/goods/coupon?company_id=${this.company_id}`,
        query: { item: JSON.stringify(this.drawCoupon) },
      })
    },
    goToAddress() {
      this.$router.push(`/address?company_id=${this.company_id}`)
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    isDrawClick: function (id) {
      this.isdrawCoupons = true
      this.drawCoupon = this.coupons.filter((item) => {
        return item.id == id
      })[0]
    },
  },
  computed: {
    startAt: function () {
      if (this.drawCoupon.not_before) {
        let date = new Date(this.drawCoupon.not_before)
        return date.getFullYear() + '.' + (parseInt(date.getMonth()) + 1) + '.' + date.getDate()
      }
      return ''
    },
    endAt: function () {
      if (this.drawCoupon.not_after) {
        let date = new Date(this.drawCoupon.not_after)
        return date.getFullYear() + '.' + (parseInt(date.getMonth()) + 1) + '.' + date.getDate()
      }
      return ''
    },
  },
}
</script>
<style scoped>
#me-box {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
}
#me-box > div {
  background: #fff;
}
#me-info {
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  color: #fff;
  background: linear-gradient(87deg, rgba(255, 106, 106, 1) 0%, rgba(255, 55, 63, 1) 100%) !important;
}
#me-info > img {
  width: 64px;
  height: 64px;
  border: 1px solid rgba(247, 247, 247, 1);
  border-radius: 50%;
  margin-right: 15px;
  margin-left: 20px;
}
#me-info > div {
  display: flex;
  flex-direction: column;
}
#me-info > div > .nickname {
  font-size: 20px;
  font-weight: 500;
}
#me-info > div > .location {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}

#me-order {
  padding: 20px;
  color: #333333;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#me-order > .checkall {
  font-size: 12px;
  color: #666666;
  display: flex;
}

#me-coupon {
  padding: 15px 20px;
  color: #333333;
  font-size: 17px;
}
#me-coupon > .coupon-box {
  display: flex;
  overflow: auto;
  margin-top: 20px;
}
.coupon-item {
  min-width: 132px;
  max-height: 50px;
  color: #333333;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  background-image: url('../../assets/coupon.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.coupon-item > .name {
  margin-bottom: 10px;
  word-break: break-all;
}
.coupon-item .num {
  font-size: 20px;
}
.coupon-item .des {
  font-size: 10px;
  color: #888888;
  margin-left: 8px;
}

#me-address {
  padding: 15px 20px;
  color: #333333;
  font-size: 17px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#me-order_show {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.order_status {
  display: flex;
  flex-direction: column;
  color: #333;
  font-size: 12px;
  align-items: center;
  position: relative;
}

.order_status > img {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}
.order_status > div {
  position: absolute;
  top: -8px;
  right: -3px;
  width: 15px;
  height: 15px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  border: 1px solid rgba(255, 59, 48, 1);
  color: #ff3b30;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

/* 优惠券详细信息 */
.draw_coupons {
  width: 90%;
  height: 103px;
  margin: 15px 0 15px 5%;
  background: #fef4f4;
  /* box-shadow: 0px 2px 4px 0px rgba(190, 190, 190, 0.5); */
  border-radius: 3px;
  /* border: 1px solid rgba(243, 243, 243, 1); */
  display: flex;
  align-items: center;
}

.draw_coupons > .coupons_left {
  font-size: 12px;
  color: #ee0a22;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 30px;
}

.draw_coupons > .coupons_left > div {
  font-size: 24px;
  margin-bottom: 5px;
}
.draw_coupons > .coupons_right {
  display: flex;
  flex: 1;
  font-size: 12px;
  color: #666;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.draw_coupons > .coupons_right > div {
  font-size: 16px;
  color: #000;
  margin-bottom: 6px;
}
/* 立即使用 */
.coupon_btn_own {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #000;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 12px;
  padding: 0 10px;
  margin-right: 25px;
}
</style>
