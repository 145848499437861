import http from './http'
import api from './api'
import { Toast } from 'vant'

class wechat {
  checkIsMiniApp() {
    return new Promise((resolve, reject) => {
      if (typeof wx != 'undefined') {
        return wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            return resolve(res.miniprogram)
          } else {
            return reject(false)
          }
        })
      }
      return reject(false)
    })
  }

  naviToMiniApp(url) {
    wx.miniProgram.navigateTo({
      url: url,
    })
  }

  async configJSSDK(url) {
    if (typeof wx != 'undefined') {
      let resp = await http.get(api.wechat.jssdk_config(url))
      let config = resp.data
      wx.config({
        debug: false,
        appId: config.appId,
        timestamp: config.timestamp,
        nonceStr: config.nonceStr,
        signature: config.signature,
        jsApiList: config.jsApiList,
        openTagList: ['wx-open-launch-weapp'],
      })
    }
  }

  configAppMessageShareData(title, desc, link, imgUrl) {
    return new Promise((resolve, reject) => {
      wx.updateAppMessageShareData({
        title,
        desc,
        link,
        imgUrl,
        success: resolve,
        fail: reject,
      })
    })
  }

  configTimelineShareData(title, link, imgUrl) {
    return new Promise((resolve, reject) => {
      wx.updateTimelineShareData({
        title,
        link,
        imgUrl,
        success: resolve,
        fail: reject,
      })
    })
  }

  invokePayment(payParam, callback) {
    if (typeof WeixinJSBridge == 'undefined') return Toast('请在微信环境打开')

    WeixinJSBridge.invoke('getBrandWCPayRequest', payParam, (res) => {
      if (res.err_msg == 'get_brand_wcpay_request:ok') {
        Toast('支付已成功')
      } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
        Toast('取消支付')
      } else {
        let message = res.errMsg ? res.errMsg : res
        Toast(`支付失败\n订单id:${order.id}\n${message}`)
        console.error(res)
      }

      callback()
    })
  }
}

export default new wechat()
