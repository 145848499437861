expressList
<template>
  <div class="logistics_detail">
    <van-nav-bar title="订单详情" left-arrow @click-left="onClickLeft" />
    <div class="logistics_info">
      <div class="info_status">你的订单被分成多个包裹发出：</div>
      <div class="expressList">
        <block v-for="(item, index) in info.express" :key="index">
          <div class="express_item" :class="current == index ? 'active' : ''" @click="changeIndex(index)">
            <div class="left">
              <div class="title" :class="current == index ? 'active' : ''">包裹{{ index + 1 }}</div>
              <div class="number">共{{ item.product.length }}件</div>
            </div>
            <div class="product_img">
              <img :src="item.product[0].product_snap.image[0]" alt="" />
            </div>
          </div>
        </block>
      </div>
      <div>快递公司：{{ logisticsInfo.Name }}</div>
      <div>
        <span>运单编号：{{ logisticsInfo.LogisticCode }}</span>
        <span class="copy_num" v-clipboard:copy="logisticsInfo.LogisticCode" v-clipboard:success="onCopy">复制</span>
      </div>
    </div>
    <div class="logistics_steps">
      <van-steps direction="vertical" :active="0">
        <van-step v-for="(item, index) in logisticsInfo.Traces" :key="item.AcceptTime">
          <template #active-icon>
            <div class="active_icon">
              <div></div>
            </div>
          </template>
          <template #inactive-icon>
            <div :class="[index == logisticsInfo.Traces.length - 1 ? 'last_inactive_icon' : 'inactive_icon']">
              <div></div>
            </div>
          </template>
          <div :style="{ color: index == 0 ? '#000' : '#666' }" class="acceptStation">{{ item.AcceptStation }}</div>
          <div :style="{ color: index == 0 ? '#000' : '#888' }" class="acceptTime">{{ item.AcceptTime }}</div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>
<script>
import wechat from '../../utils/wechat'
import { Toast } from 'vant'
import { toastHttpUnKnownError } from '@/utils/errors'
import { getTimer } from '@/utils/convert'
export default {
  data: function () {
    return {
      isMiniApp: false,
      logisticsInfo: {},
      info: {},
      current: 0,
    }
  },
  created: function () {
    let { logisticsInfo } = this.$route.query
    let realLogisticsInfo = JSON.parse(logisticsInfo)
    this.info = realLogisticsInfo
    realLogisticsInfo.express.forEach((item) => {
      item.Traces = item.Traces.reverse()
    })
    this.logisticsInfo = realLogisticsInfo.express[0]
  },
  computed: {
    product_price: function () {
      return this.detail.items.reduce((total, curr) => {
        return (Number(total) + Number(curr.price) * curr.amount).toFixed(2)
      }, 0)
    },
    discount: function () {
      // 商品金额 - 实付款 + 运费 = 折扣优惠
      return (this.product_price - this.detail.total_amount + Number(this.detail.express_fare)).toFixed(2)
    },
  },
  mounted: function () {
    this.checkIsMiniApp()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    changeIndex(index) {
      this.current = index
      this.logisticsInfo = this.info.express[index]
    },
    checkIsMiniApp() {
      wechat.checkIsMiniApp().then(
        (res) => (this.isMiniApp = res),
        (rej) => ''
      )
    },
    onCopy() {
      Toast('已复制至剪切板')
    },
  },
}
</script>
<style scoped>
.logistics_detail {
  width: 100%;
  height: 100%;
  background: #f5f5f9;
}
.logistics_info {
  background: #fff;
  padding: 12px 20px;
  font-size: 13px;
  color: #666666;
  margin-top: 15px;
  margin-bottom: 15px;
}
.info_status {
  color: #000000;
  font-size: 16px;
  margin-bottom: 7px;
}
.acceptStation {
  font-size: 14px;
  color: #666;
  margin-bottom: 11px;
}
.acceptTime {
  font-size: 12px;
  color: #888;
}
.logistics_steps {
  height: calc(100% - 93px - 46px);
  overflow: auto;
}
.logistics_steps .van-step__line {
  background: #dedede;
}
.active_icon {
  width: 20px;
  height: 20px;
  background: #ffd7d9;
  border-radius: 50%;
  position: relative;
}
.active_icon > div {
  width: 12px;
  height: 12px;
  background: #ff373f;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.inactive_icon {
  width: 7px;
  height: 7px;
  background: #dedede;
  border-radius: 50%;
}
.last_inactive_icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #b2b2b2;
  position: relative;
}
.last_inactive_icon > div {
  width: 8px;
  height: 8px;
  background: #b2b2b2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.copy_num {
  display: inline-block;
  width: 44px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border: 1px solid #b2b2b2;
  opacity: 1;
  border-radius: 2px;
  margin-left: 10px;
}
.expressList {
  display: flex;
  padding: 8px;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: scroll;
}
.expressList .express_item {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 4px 10px;
  align-items: center;
  margin-right: 16px;
}
.expressList .active {
  border: 1px solid #f65307;
}
.expressList .express_item .product_img {
  width: 56px;
  height: 56px;
  margin-left: 32px;
}
.expressList .express_item .product_img > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.expressList .express_item .left .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 3px;
}
.expressList .express_item .left .active {
  color: #f65307;
  border: 0;
}
::-webkit-scrollbar {
  display: none;
}
</style>
