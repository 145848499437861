import http from '../utils/http'
import api from '../utils/api'

export function fetchCompanyDetail(id) {
  return http.get(api.company.detail(id))
}

export function fetchCompanyPointRule(id) {
  return http.get(api.company.point_rule(id))
}
