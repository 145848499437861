import http from '../utils/http'
import api from '../utils/api'

export function fetchGoods(company_id = '') {
  return http.get(`${api.cart.list}?company_id=${company_id}`)
}

export function addCart(sku_id, amount) {
  return http.post(api.cart.add, {
    sku_id: sku_id,
    amount: amount,
  })
}

export function removeCart(sku_id) {
  return http.delete(api.cart.remove(sku_id), {})
}

export function updateCart(sku_id, amount) {
  return http.patch(api.cart.update, {
    sku_id: sku_id,
    amount: amount,
  })
}
