/**
 * 转化后端时间格式
 *
 * @param {string} date
 */
export function localizedTimeToTimestamp(date) {
  return new Date(date).getTime() / 1000
}

function padLeft(val) {
  return ('00' + val).slice(-2)
}

/**
 * 转换时间格式
 *
 * @param { Date } date
 */

export function dateToStr(date) {
  return `${date.getFullYear()}-${padLeft(date.getMonth() + 1)}-${padLeft(date.getDate())} ${padLeft(
    date.getHours()
  )}:${padLeft(date.getMinutes())}:${padLeft(date.getSeconds())}`
}

export function getCompanyIdFromString(url) {
  let strUrl = String(url)
  let paramStr = strUrl.slice(strUrl.lastIndexOf('?') + 1)
  let params = new URLSearchParams(paramStr)
  return params.get('company_id')
}

export function getTimer(fmt, time) {
  const date = new Date(time)
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}

export function createTime(time = null) {
  const date = time ? new Date(time.includes('T') ? time : time.replace(/-/g, '/')) : new Date()
  return date.getTime()
}

/**
 * 高德地图定位
 * @type {{}}
 */
export const location = {
  initMap(id, onComplete, onError) {
    let mapObj = new AMap.Map(id, {})
    let geolocation
    mapObj.plugin(['AMap.Geolocation'], function () {
      geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, //  是否使用高精度定位，默认:true
        timeout: 10000, //  超过10秒后停止定位，默认：无穷大
        maximumAge: 0, // 定位结果缓存0毫秒，默认：0
        convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
        showButton: true, //  显示定位按钮，默认：true
        buttonPosition: 'LB', // 定位按钮停靠位置，默认：'LB'，左下角
        buttonOffset: new AMap.Pixel(10, 20), //  定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
        showMarker: true, //  定位成功后在定位到的位置显示点标记，默认：true
        showCircle: true, //  定位成功后用圆圈表示定位精度范围，默认：true
        panToLocation: true, //  定位成功后将定位到的位置作为地图中心点，默认：true
        zoomToAccuracy: true, //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
      })
      mapObj.addControl(geolocation)
      geolocation.getCurrentPosition(function (status, result) {
        if (status === 'complete') {
          onComplete(result)
        } else {
          onError(result)
        }
      })
    })
    return geolocation
  },
}

export const address = {
  initMap(id, lnglat, onComplete, onError) {
    let geolocation
    AMap.plugin(['AMap.Geocoder'], function () {
      geolocation = new AMap.Geocoder({
        // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
        city: '010',
      })

      geolocation.getAddress(lnglat, function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          onComplete(result)
        } else {
          onError(result)
        }
      })
    })
    return geolocation
  },
}
