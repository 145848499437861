import http from '../utils/http'
import api from '../utils/api'
import qs from 'qs'

export function goodDetail(id, query) {
  return http.get(api.good.detail(id, query))
}

export function manyGoodsDetail(ids) {
  return http.get(
    `${api.good.list}?${qs.stringify({
      ids: ids.join(','),
    })}`
  )
}

export function brandList(company_id) {
  return http.get(api.good.brands(company_id))
}

export function brandDetail(brand_id) {
  return http.get(api.good.brand(brand_id))
}

/**
 * 查看分类树
 *
 * 子列表 key:
 */
export function categoryList() {
  return http.get(api.good.category)
}

/**
 * 分类列表，只获取有商品的类目
 *
 * @param { Number } company_id
 */
export function categoriesOwnGoods(company_id, product_ids) {
  return http.get(api.good.categories_own_goods(company_id, product_ids))
}

export function goodsList(company_id, brand_id, category_id, page = 1, order = 'price_asc', search, ids, type = '') {
  return http.get(
    `${api.good.list}?${qs.stringify({
      company_id,
      order: order,
      brand_id: brand_id ? brand_id.join(',') : '',
      category_id: category_id ? category_id.join(',') : '',
      search: search,
      page: page,
      ids: ids ? ids.join(',') : '',
      type,
    })}`
  )
}

export function indexGoodsList({ company_id, page }) {
  return http.get(
    `${api.good.list}?${qs.stringify({
      company_id,
      page,
      order: 'id_desc',
    })}`
  )
}

export function getCompositions(id) {
  return http.get(api.good.compositions(id))
}

export function getCards({
  company_id,
  brand_id = null,
  category_id = '',
  page = 1,
  order = 'price_asc',
  search = '',
  ids = [],
}) {
  return http.get(
    `${api.card.cards}?${qs.stringify({
      company_id,
      order: order,
      brand_id: brand_id ? brand_id.join(',') : '',
      category_id: category_id,
      search,
      page,
      ids: ids.join(','),
    })}`
  )
}

export function getCardDetail(id, query) {
  return http.get(api.card.detail(id, query))
}

export function getCouponList(id) {
  return http.get(api.good.coupon_list(id))
}
