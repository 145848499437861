class redirect_count {
  times() {
    return window.localStorage.getItem('redirect_times') ? window.localStorage.getItem('redirect_times') : 0
  }

  addTimes() {
    let times = this.times()

    window.localStorage.setItem('redirect_times', ++times)
  }

  reset() {
    window.localStorage.removeItem('redirect_times')
  }
}

export default new redirect_count()
