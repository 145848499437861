import qs from 'qs'
import { dateToStr } from '../utils/convert'

export const shareFlag = 'from_user'
export const shareSourceUrlFlag = 'from_url'
export const shareSourceSkuFlag = 'from_sku'
export const sharedAt = 'shared_at'

export function setShareUserId(user_id, url) {
  localStorage.setItem(shareFlag, user_id)
  localStorage.setItem(shareSourceUrlFlag, url)
  localStorage.setItem(sharedAt, dateToStr(new Date()))
}
export function setShareSkuId(sku_id) {
  localStorage.setItem(shareSourceSkuFlag, sku_id)
}

export function getShareUserId() {
  return localStorage.getItem(shareFlag)
}

function getParamFromStr(str, key) {
  let _ = str.split('?')
  if (_.length < 2) {
    return false
  }
  let params = qs.parse(_[1])
  if (!Object.keys(params).includes(key) || params[key] == '') {
    return false
  }
  return params[key]
}

/**
 * 获取参数中的分享标识项目，通常传入 document.URL
 *
 * @param {string} str
 */
export function getNewUserId(str) {
  return getParamFromStr(str, shareFlag)
}

export function getNewSkuId(str) {
  return getParamFromStr(str, shareSourceSkuFlag)
}

export function getForwardingData() {
  return {
    sku_id: localStorage.getItem(shareSourceSkuFlag),
    user_id: localStorage.getItem(shareFlag),
    url: localStorage.getItem(shareSourceUrlFlag),
    created_at: localStorage.getItem(sharedAt),
  }
}
