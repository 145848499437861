<template>
  <div id="brand-story">
    <van-nav-bar title="品牌故事" left-arrow @click-left="onClickLeft" />
    <div class="header" :style="'background-image:url(' + brand.backgroup_image_url + ');'">
      <div class="msg">
        <img class="logo" :src="brand.logo_url" />
        <div class="name">
          <div class="name_zh">{{ brand.name_zh }}</div>
          <div>{{ brand.country }}</div>
          <div class="name_en">{{ brand.name_en }}</div>
        </div>
      </div>
    </div>
    <div
      class="description"
      :style="showContent ? (brand.description.length >= 150 ? 'height:220px' : 'height:120px') : 'height:60px'"
    >
      <div
        v-if="true"
        class="content"
        :style="showContent ? (brand.description.length >= 150 ? 'height:200px' : 'height:110px') : 'height:36px'"
      >
        {{ brand.description }}
      </div>
      <div v-if="true" @click="toggleContent" :class="showContent ? 'arrow show_content' : 'arrow hidden_content'">
        <img src="../../../assets/right.png" alt />
      </div>
    </div>
    <div id="category" class="row">
      <div class="goods-top">
        <div class="bubble-container">
          <!-- <ul>
            <li
              v-on:click="changeCategory(item.id)"
              class="bubble"
              :class="{'active-bubble': category_id == item.id}"
              v-for="item in categories"
              :key="item.id"
            >{{ item.name }}</li>
          </ul>-->
          <van-tabs line-width="14" @click="tabClick" sticky title-inactive-color="#333">
            <van-tab name="id_asc" title="综合"></van-tab>
            <van-tab name="created_at" title="新品"></van-tab>
            <van-tab name="sold_count_asc" title="销量"></van-tab>
            <van-tab name="price_asc">
              <template #title>
                <div :class="price_top ? 'price_tab price_top' : 'price_tab price_bottom'">价格</div>
              </template>
            </van-tab>
          </van-tabs>
        </div>
      </div>
      <div @click="filterClick" :class="category_name ? 'goods-filter active' : 'goods-filter'">
        <div>{{ category_name || '筛选' }}</div>
        <img :src="category_name ? imgs.filter_activeImg : imgs.filterImg" alt />
      </div>
      <van-popup get-container="body" v-model="popupShow" position="right" class="filter_popup">
        <div class="filter_content">
          <div class="content_title">种类</div>
          <div class="categories_filter">
            <div
              :class="{ item_active: category_ids.includes(item.id) }"
              v-on:click="changeCategory(item.id)"
              v-for="item in categories"
              :key="item.id"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="content_title" style="margin-top: 39px">品牌</div>
          <div class="categories_filter">
            <div class="item_active">{{ brand.name_zh }}</div>
          </div>
        </div>
        <div class="filter_footer">
          <van-button class="clear_btn" type="default" @click="clear">清空</van-button>
          <van-button class="submit_btn" type="danger" @click="submit_filter">确认</van-button>
        </div>
      </van-popup>
      <!-- <van-collapse class="good-select" v-model="activeNames" :border="false" accordion>
        <van-collapse-item title="全部类目" name="1">
          <ul class="collapse-ul">
            <li
              v-on:click="changeCategory(item.id)"
              class="collapse-bubble"
              :class="{'active-bubble': category_id == item.id}"
              v-for="item in categories"
              :key="item.id"
            >{{ item.name }}</li>
          </ul>
        </van-collapse-item>
      </van-collapse>-->
    </div>
    <div id="goods-list">
      <van-row gutter="20">
        <div v-for="item in goods" :key="item.id">
          <van-col @click="goGoodDetail(item.id)" class="good-contaienr" span="12">
            <img :src="item.image_url" :alt="item.title" />
            <div>{{ item.title }}</div>
            <span>
              ¥{{ item.price }}
              <span
                v-if="item.time_limit_discount && item.time_limit_discount.label"
                class="s-triangle"
                style="display: inline-block; font-size: 10px; margin: 0"
                >{{ item.time_limit_discount.label }}</span
              >
            </span>
          </van-col>
        </div>
      </van-row>
    </div>
  </div>
</template>
<script>
import filter_activeImg from '@/assets/filter_active.png'
import filterImg from '@/assets/filter.png'
import { brandDetail, categoriesOwnGoods, goodsList } from '@/services/goods'
import { toastHttpUnKnownError } from '../../../utils/errors'
import { createTime } from '@/utils/convert'

export default {
  props: ['id'],
  data() {
    return {
      brand: {},
      popupShow: false,
      showContent: false,
      categories: [],
      collapseActive: false,
      category_ids: [],
      activeNames: 2,
      order: 'price_asc',
      price_top: true,
      goods: [],
      category_name: '',
      imgs: {
        filterImg: '',
        filter_activeImg: '',
      },
    }
  },
  mounted: function () {
    this.imgs = {
      filterImg,
      filter_activeImg,
    }
    this.company_id = this.$route.query.company_id
    if (this.company_id == null) {
      this.$router.push('/errors/404')
      return
    }
    this.requestForBrand()
    this.requestForCategory()
    this.requestForList()
  },
  methods: {
    onClickLeft: function () {
      this.$router.go(-1)
    },
    toggleContent: function () {
      this.showContent = !this.showContent
    },
    requestForBrand: function () {
      brandDetail(this.id).then((resp) => {
        this.brand = resp.data
      })
    },
    requestForCategory: function () {
      categoriesOwnGoods(this.company_id)
        .then((resp) => {
          let items = []
          for (let [key, value] of Object.entries(resp.data)) {
            items.push(value)
          }
          this.categories = [...this.categories, ...items]
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    },
    changeCategory: function (id) {
      // if (id === this.category_id) {
      //   this.category_id = null;
      // } else {
      //   this.category_id = id;
      // }
      let category_ids = this.category_ids
      if (category_ids.includes(id)) {
        let idx = category_ids.indexOf(id)
        category_ids.splice(idx, 1)
        this.category_ids = category_ids
      } else {
        this.category_ids.push(id)
      }
      this.page = 1
      this.goods = []
      this.activeNames = 2
      // this.requestForList();
    },
    goGoodDetail(id) {
      this.$router.push(`/goods/${id}?company_id=${this.company_id}`)
    },
    requestForList: function () {
      this.loading = true
      goodsList(this.company_id, [this.id], this.category_ids, '', this.order, '')
        .then((resp) => {
          this.loading = false
          resp.data.data.forEach((item) => {
            item.price =
              item.time_limit_discount && createTime(item.time_limit_discount.ended_at) > createTime()
                ? item.time_limit_discount.price
                : item.price
          })
          this.goods = [...this.goods, ...resp.data.data]
        })
        .catch((error) => {
          console.log(error)
        })
    },
    tabClick(name, title) {
      if (name == 'price_asc') {
        this.page = 1
        this.goods = []
        this.activeNames = 2
        this.order = this.price_top ? 'price_desc' : name
        this.price_top = !this.price_top
        this.requestForList()
        return
      }
      this.price_top = true
      if (name == 'created_at') {
        let goods = this.goods
        goods.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at)
        })
        this.goods = goods
        return
      }
      if (this.order !== name) {
        this.page = 1
        this.goods = []
        this.activeNames = 2
        this.order = name
        this.requestForList()
      }
    },
    filterClick() {
      this.popupShow = true
    },
    submit_filter() {
      this.popupShow = false
      this.category_name =
        this.category_ids.length > 0 ? this.categories.filter((c) => this.category_ids.includes(c.id))[0].name : ''
      this.requestForList()
    },
    clear() {
      this.category_ids = []
      this.order = 'price_asc'
      //  this.popupShow = false;
      // this.requestForList();
    },
  },
}
</script>

<style scoped>
#brand-story > .header {
  height: 260px;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
}
.header:after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 1;
  width: 100%;
  height: 100%;
}
.header > .msg {
  width: 100%;
  position: absolute;
  display: flex;
  z-index: 2;
  padding: 0 18px;
  bottom: 71px;
  box-sizing: border-box;
}
.header .logo {
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 3px;
  margin-right: 10px;
}
.header .name {
  flex: 1;
  color: #e4e4e4;
  font-size: 10px;
}

.name_zh {
  color: #fff;
  font-size: 18px;
}
.name_en {
  margin-top: 5px;
}

.description {
  background: #fff;
  border-radius: 16px 16px 0 0;
  transform: translateY(-59px);
  position: relative;
  z-index: 3;
  padding: 12px;
  box-sizing: border-box;
  transition: all 0.4s ease;
  border-bottom: 1px solid #ebedf0;
}
.content {
  color: #666666;
  font-size: 13px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s linear;
}
.arrow {
  position: absolute;
  width: 42px;
  background: #fff;
  bottom: 8px;
  right: 0px;
  text-align: center;
}
.hidden_content > img {
  transform: rotate(90deg);
}
.show_content > img {
  transform: rotate(-90deg);
}
#category {
  display: flex;
  transform: translateY(-60px);
  position: relative;
  height: 45px;
  border-bottom: 1px solid #ebedf0;
}

#category label {
  color: #363636;
  margin: 0 20px;
  height: 50px;
  line-height: 50px;
  float: left;
}
.goods-top {
  width: 70%;
  background: #fff;
  padding-left: 10px;
}
.good-select {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.goods-filter {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}
.goods-filter > img {
  width: 14px;
  height: 14px;
  margin-right: 22px;
  margin-left: 2px;
}
.filter_popup {
  height: 100vh;
  width: 80vw;
}
.filter_content {
  height: calc(100vh - 44px);
  padding: 20px 22px 0 22px;
  box-sizing: border-box;
  overflow-y: auto;
}

.content_title {
  font-size: 14px;
  color: #333;
  padding-left: 8px;
  margin-bottom: 21px;
  font-weight: 500;
}
.categories_filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.categories_filter > div {
  min-width: 20vw;
  margin-bottom: 8px;
  height: 30px;
  background: rgba(243, 242, 242, 1);
  border-radius: 2px;
  border: 1px solid rgba(243, 242, 242, 1);
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #363636;
}
.categories_filter > .item_active {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(224, 32, 32, 1);
  color: #e02020;
}
.clear_btn {
  width: 35%;
}
.submit_btn {
  width: 65%;
}

.bubble-container {
  overflow-x: auto;
  scrollbar-width: none;
}

.bubble-container ul {
  white-space: nowrap;
  margin-top: 10px;
}
.bubble {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  color: #363636;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 20px;
  font-weight: lighter;
}
.collapse-bubble {
  min-width: 20%;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  border-radius: 15px;
  border: 1px solid rgba(211, 211, 211, 1);
  color: #363636;
  margin-left: 4%;
  margin-bottom: 12px;
}
.collapse-ul {
  display: flex;
  flex-wrap: wrap;
}

.active-bubble {
  color: #fff;
  background-color: #ee0a24;
  border: none;
}

#goods-list {
  width: 90%;
  margin: 0 auto;
  height: 150px;
}
.good-contaienr img {
  width: 100%;
  height: 168px;
}

.good-contaienr div {
  color: #333333;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.good-contaienr span {
  margin-bottom: 20px;
  font-size: 16px;
  color: #ff373f;
  display: block;
  height: 20px;
}
.price_tab {
  position: relative;
}
.price_tab::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  top: 16px;
  right: 6px;
}
.price_tab::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  bottom: 16px;
  right: 6px;
}
.price_top::after {
  border-bottom: 4px solid #333;
}
.price_top::before {
  border-top: 4px solid #dedede;
}
.price_bottom::after {
  border-bottom: 4px solid #dedede;
}
.price_bottom::before {
  border-top: 4px solid #333;
}
.active {
  color: #df2f36;
}
/* 标签 */
.s-triangle {
  display: inline-block;
  font-size: 10px;
  position: relative;
  color: #ff373f;
  border: 1px solid rgba(255, 55, 63, 1);
  border-radius: 2px;
  padding: 0 2px;
  box-sizing: border-box;
}
</style>
