import http from '../utils/http'
import api from '../utils/api'

export function addAdress(formData) {
  return http.post(api.address.add, formData)
}

export function updateAddress(address_id, formData) {
  return http.patch(api.address.edit(address_id), formData)
}

export function removeAddress(address_id) {
  return http.delete(api.address.delete(address_id), {})
}

export function list() {
  return http.get(api.address.list)
}
