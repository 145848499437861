<template>
  <div id="salesroom-list">
    <van-nav-bar title="门店地址" left-arrow @click-left="onClickLeft" />
    <div class="list-title">收货人信息</div>
    <van-cell-group>
      <van-field v-model="userName" label="收货人" placeholder="请输入收货人" />
      <van-field type="tel" v-model="userPhone" label="手机号" placeholder="请输入手机号" />
    </van-cell-group>
    <div class="popup_header">
      <van-search
        class="popup_search"
        placeholder="搜索店铺"
        v-model="keywords"
        shape="round"
        @search="onSearch"
        @clear="onSearch"
        show-action
      >
        <div slot="action" @click="onSearch">搜索</div>
      </van-search>
    </div>
    <van-list
      style="padding-bottom: 80px"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多门店了"
      @load="onLoads"
    >
      <van-radio-group class="list-radio-group" checked-color="#FF383F" v-model="radio">
        <div v-for="item in stores" v-bind:key="item.id" class="radio-item">
          <div class="item-msg">
            <van-radio :name="item.id">{{ item.name }}</van-radio>
            <div class="item-location">
              <img src="../../assets/location.png" alt />
              <span>{{ item.full_address }}</span>
            </div>
            <div class="item-opentime">
              <img src="../../assets/sales_time.png" alt />
              <span>{{ item.business_date }} {{ item.business_time }}</span>
            </div>
            <div class="item-location">
              <img src="../../assets/location.png" alt />
              <span>离你距离{{ item.distances }}Km</span>
            </div>
          </div>
          <!-- <img src="../../assets/icons8-phone.png" alt /> -->
          <img class="item-phone" :src="item.images_list[0]" @click="clickImage(item)" v-lazy="item.images_list[0]" />
          <!-- -->
        </div>
      </van-radio-group>
    </van-list>
    <van-button v-on:click="submit" class="submit-btn" type="danger">提交</van-button>
  </div>
</template>
<script>
import { getStores } from '../../services/order'
import { location } from '../../utils/convert'
import { Toast } from 'vant'
import { ImagePreview } from 'vant'

const actions = {
  list: 'list',
  select: 'select',
}

export default {
  data() {
    return {
      radio: '1',
      stores: [],
      userName: '',
      userPhone: '',
      keywords: '',
      latitude: null,
      longitude: null,
      loading: false,
      finished: false,
      pageSize: 10,
      pageNum: 0,
      total: 10,
    }
  },
  mounted: function () {
    const { id, selectStores_user, company_id, latitude, longitude } = this.$route.query
    this.radio = id
    this.userName = selectStores_user.name
    this.userPhone = selectStores_user.phone
    console.log(latitude, longitude)
    if (latitude && latitude != 0) {
      this.latitude = latitude
      this.longitude = longitude
      const { company_id } = this.$route.query
      this.pageNum = 1
      this.stores = []
      this.getStores(company_id, this.latitude, this.longitude, '', this.pageNum)
    } else {
      this.getLocation()
    }
  },
  methods: {
    clickImage(item) {
      ImagePreview(item.images_list)
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    getStores: function (company_id, latitude, longitude, name, page) {
      this.loading = true
      getStores(company_id, latitude, longitude, name, page).then((resp) => {
        const data = resp.data
        this.total = data.length
        this.stores = [...this.stores, ...data]
        this.stores.forEach((item) => {
          item.distances = item.distance.toFixed(2)
          if (typeof item.image_list == 'string') {
            item.images_list.push(item.image_list)
          } else {
            item.images_list = item.image_list
          }
        })
        setTimeout(() => {
          this.loading = false
        }, 50)
      })
    },
    onLoads() {
      setTimeout(() => {
        const { company_id } = this.$route.query
        //this.pageNum * this.pageSize >= this.total
        this.loading = true
        if (this.total < 10) {
          this.loading = false
          this.finished = true
          return
        }
        this.pageNum++
        // this.getStores(company_id, this.latitude, this.longitude, this.keywords, this.pageNum)
        if (this.pageNum != 1) {
          this.getStores(company_id, this.latitude, this.longitude, this.keywords, this.pageNum)
        }
      }, 500)
    },
    submit: function () {
      if (this.userName == '' || this.userPhone == '') {
        Toast('请填写相关信息')
        return
      }
      let param = {
        name: this.userName,
        phone: this.userPhone,
        id: this.radio,
      }
      localStorage.setItem('salesroom', JSON.stringify(param))
      this.$router.go(-1)
    },
    initAdressInfo({ position }) {
      const { company_id } = this.$route.query
      this.latitude = position.getLat()
      this.longitude = position.getLng()
      this.pageNum = 1
      this.stores = []
      this.getStores(company_id, this.latitude, this.longitude, '', this.pageNum)
    },
    onSearch: function () {
      if (this.loading) {
        return
      }
      const { company_id } = this.$route.query
      this.pageNum = 1
      this.finished = false
      this.total = 10
      this.stores = []
      this.getStores(company_id, this.latitude, this.longitude, this.keywords, 1)
    },
    /**获取地图定位*/
    getLocation() {
      const loading = Toast.loading({
        message: '定位识别中...',
        forbidClick: true,
        duration: 0,
      })
      let _that = this
      location.initMap(
        'map-container',
        (result) => {
          console.log('定位地址', result)
          _that.initAdressInfo(result)
          loading.clear()
        },
        (error) => {
          _that.pageNum = 1
          _that.getStores(this.$route.query.company_id, this.latitude, this.longitude, this.keywords, this.pageNum)
          loading.clear()
          Toast.loading({
            message: '获取定位失败',
            forbidClick: true,
            duration: 1000,
          })
          loading.clear()
          console.log('获取定位失败', error)
        }
      )
    },
  },
}
</script>
<style scoped>
#salesroom-list {
  height: 100%;
  background: #f5f5f9;
}

.list-title {
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  color: #999999;
  font-size: 12px;
}

.list-radio-group {
  margin-top: 15px;
  height: calc(100% - 280px);
  overflow-y: auto;
}

.radio-item {
  background: #fff;
  border-bottom: 1px solid #f5f5f9;
  padding: 15px;
  display: flex;
}

.item-msg {
  flex: 1;
}

.item-phone {
  width: 130px !important;
  height: 80px !important;
  margin-top: 30px;
}

.item-location {
  margin-top: 15px;
  margin-bottom: 8px;
}

.item-location,
.item-opentime {
  color: #666666;
  font-size: 12px;
}

.item-location > img,
.item-opentime > img {
  width: 10px;
  height: 12px;
  margin-left: 5px;
  margin-right: 12px;
}

.submit-btn {
  position: fixed;
  bottom: 0;
  width: 90%;
  margin-top: 15px;
  margin-left: 5%;
}
.popup_header {
  height: 60px;
  display: flex;
  align-items: center;
}
.popup_icon {
  width: 35px;
  text-align: center;
  height: 60px;
  line-height: 60px;
}
.popup_search {
  flex: 1;
}
.popup_search > .van-search__content {
  background: #f7f7f9;
}
.popup_search > .van-search__action {
  width: 48px;
  text-align: center;
  background: #df2f36;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 8px;
}
</style>
