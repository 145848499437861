<template>
  <div v-html="item.content"></div>
</template>

<script>
import { fetchBannerDetail } from '../../services/banner'
export default {
  props: ['id'],
  data: function () {
    return {
      item: {},
    }
  },
  mounted: function () {
    this.detail()
  },
  methods: {
    detail: async function () {
      let resp = await fetchBannerDetail(this.id)
      this.item = resp.data
    },
  },
}
</script>

<style></style>
