<template>
  <div class="order_refund">
    <van-nav-bar title="退款" left-arrow @click-left="onClickLeft" />
    <div class="item-msg">
      <img :src="item.product_snap.image_url" />
      <div class="msg_detail">
        <div class="msg-name">{{ item.product_snap.title }}</div>
        <div class="msg-pay">
          <span class="pay-real-pay">￥{{ item.price }}</span>
          <span class="pay-num">×{{ item.amount }}</span>
        </div>
      </div>
    </div>
    <div class="refund_num">
      <span>商品件数：</span>
      <van-stepper disable-input :max="maxNum" v-model="productNum" />
    </div>
    <div class="refund_price mb-1">
      <span>退款金额：</span>
      <span style="color: #ff3b30"> ￥{{ amount_to_refund }} </span>
      <span class="fare" v-if="Number(item.amount_to_refund.fare) != 0">含邮费￥{{ item.amount_to_refund.fare }}</span>
      <span class="fare" v-else>不含运费</span>
    </div>
    <div class="refund_points" v-if="item.points_to_refund">
      <span>退还积分：</span>
      <span>{{ item.points_to_refund }}</span>
    </div>
    <div class="reason">
      <div class="refund_reason">
        <span>退款说明：</span>
        <van-field
          style="padding-left: 0; flex: 1"
          autosize
          type="textarea"
          v-model="productReason"
          placeholder="选填"
        />
      </div>
      <div class="reasons" v-if="this.$route.query.ship_status == 'delivered'">
        <van-button
          class="reason_prop"
          v-for="reason in reasons.delivered"
          :key="reason.text"
          @click="onClickReason(reason.text)"
        >
          {{ reason.text }}
        </van-button>
      </div>
      <div class="reasons" v-else>
        <van-button
          class="reason_prop"
          v-for="reason in reasons.pending"
          :key="reason.text"
          @click="onClickReason(reason.text)"
        >
          {{ reason.text }}
        </van-button>
      </div>
    </div>
    <div class="refund_images">
      <span>上传凭证：</span>
      <van-uploader v-model="fileList" multiple :max-count="3" />
    </div>
    <van-button @click="submit()" class="submit_btn" type="danger">提交</van-button>
  </div>
</template>
<script>
import { applyRefund } from '../../services/order'
import { Toast } from 'vant'
import { toastHttpUnKnownError } from '@/utils/errors'
export default {
  data: function () {
    return {
      onlyApply: false,
      item: {},
      productNum: 1,
      maxNum: 1,
      productReason: '',
      fileList: [],
      imgList: [],
      reasons: {
        pending: [
          { text: '不想要/拍多了' },
          { text: '信息填写错误' },
          { text: '缺货' },
          { text: '协商一致退款' },
          { text: '未收到货' },
        ],
        delivered: [
          { text: '颜色/尺寸不符' },
          { text: '商品瑕疵' },
          { text: '质量问题' },
          { text: '少件/漏发' },
          { text: '收到商品时有破损' },
        ],
      },
    }
  },
  created: function () {
    let { item, onlyApply } = this.$route.query
    this.item = item
    this.onlyApply = onlyApply == '1' ? 1 : 0
    this.productNum = item.amount
    this.maxNum = item.amount
  },
  mounted: function () {},
  computed: {
    amount_to_refund: function () {
      let { amount_to_refund = null, amount = 0 } = this.item

      if (amount_to_refund && amount) {
        return (
          (Number((amount_to_refund.amount / amount) * this.productNum) + Number(amount_to_refund.fare)).toFixed(2) || 0
        )
      }
      return 0
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    submit() {
      applyRefund(this.item.id, this.onlyApply, this.productReason, this.productNum, this.fileList)
        .then(() => {
          Toast('提交成功')
          this.$router.replace({
            path: '/order/refund_detail',
            query: {
              id: this.item.id,
            },
          })
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    },
    onClickReason(reason) {
      this.productReason += reason + '，'
    },
  },
}
</script>
<style scoped>
.order_refund {
  width: 100%;
  height: 110%;
  background: #f5f5f9;
}
.item-msg {
  display: flex;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  background: #ffffff;
}
.item-msg:last-child {
  border-bottom: none;
}

.item-msg > img {
  width: 80px;
  height: 80px;
}

.item-msg > div {
  color: #000;
  flex: 1;
  margin-left: 10px;
}
.item-msg .msg-name {
  font-size: 13px;
  margin-bottom: 24px;
}

.item-msg .msg-pay {
  display: flex;
  justify-content: space-between;
}

.item-msg .msg-pay .pay-real-pay {
  font-size: 15px;
}

.item-msg .msg-pay .pay-num {
  font-size: 12px;
  color: #888;
}

.refund_num {
  height: 44px;
  justify-content: space-between;
  padding: 0 10px;
}
.refund_price,
.refund_points {
  height: 44px;
  padding: 0 10px;
}
.refund_price > .fare {
  margin-left: 10px;
  color: #b2b2b2;
  font-size: 12px;
}
.refund_reason {
  width: 100%;
}
.refund_reason > span {
  display: inline-block;
  margin-bottom: 25px;
}
.refund_images {
  padding: 10px;
}
.refund_num,
.refund_price,
.refund_points,
.refund_reason,
.reason,
.refund_images {
  color: #2b2b2b;
  font-size: 14px;
  margin-bottom: 10px;
  background: #fff;
  display: flex;
  align-items: center;
}
.reason {
  padding: 0 10px;
  flex-direction: column;
}
.reason_prop {
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #c7c9cc;
  border-radius: 20px;
}
.images_box {
  position: relative;
  width: 64px;
  height: 64px;
}
.images_box > input {
  width: 64px;
  height: 64px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.submit_btn {
  width: 90%;
  margin-left: 5%;
  border-radius: 3px;
}
.mb-1 {
  margin-bottom: 1px;
}
</style>
