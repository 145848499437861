export default {
  me: {
    info: `/me`,
    accounts: (type = '', card_type = 'all') => `/user_accounts?type=${type}&card_type=${card_type}`,
  },
  address: {
    list: `/user_addresses`,
    add: `/user_addresses`,
    edit: (id) => {
      return `/user_addresses/${id}`
    },
    delete: (id) => {
      return `/user_addresses/${id}`
    },
  },
  company: {
    detail: (id) => {
      return `/companies/${id}`
    },
    point_rule: (id) => `/companies/${id}/point_rule`,
  },
  good: {
    category: `/category_tree`, // 所有的分类列表
    categories_own_goods: (company_id, product_ids = []) => {
      return `/categories?company_id=${company_id}&product_ids=${product_ids}`
    }, // 分类列表中有商品的列表项目
    brands: (company_id) => {
      return `/brands_by_company/${company_id}`
    },
    brand: (brand_id) => {
      return `/brands/${brand_id}`
    },
    list: `/products`,
    detail: (id, query) => {
      if (typeof query != 'undefined' && query.from === 'admin') {
        return `/products/${id}?from=${query.from}`
      }
      return `/products/${id}`
    },
    compositions: (id) => {
      return `/products/${id}/compositions`
    },
    coupon_list: (id) => {
      return `/products/${id}/coupon_codes`
    },
  },
  cart: {
    list: `/cart`,
    add: `/cart`,
    update: `/cart`,
    remove: (sku_id) => {
      return `/cart/${sku_id}`
    },
  },
  coupon: {
    my: (id) => {
      return `/coupon_codes?company_id=${id}`
    },
    receive: (id) => {
      return `/coupon_codes/${id}`
    },
    detail: (code) => {
      return `/coupon_codes/${code}`
    },
    getByCompany: (id) => {
      return `/coupon_codes_by_company/${id}`
    },
    autoReceive: () => {
      return `/coupon_codes/receive/auto`
    },
  },
  auth: {
    wechat: (id) => {
      return `/socials/authorizations?company_id=${id}`
    },
  },
  order: {
    create: (id, order_status = '', page) => {
      return order_status == ''
        ? `/orders?company_id=${id}&page=${page}`
        : `/orders?order_status=${order_status}&company_id=${id}&page=${page}`
    },
    detail: (order_id) => {
      return `/orders/${order_id}`
    },
    wechatPay: (order_id) => {
      return `/payment/${order_id}/wechat`
    },
    payWithoutMoney: (order_id) => `/payment/${order_id}/without_money`,
    item_detail: (orderItemId) => {
      return `/order_items/${orderItemId}`
    },
    apply_refund: (orderItemId) => {
      return `/order_items/${orderItemId}/apply_refund`
    },
    return_product: (orderItemId) => {
      return `/order_items/${orderItemId}/return_product`
    },
    order_numbers: (company_id) => {
      return `/orders_numbers?company_id=${company_id}`
    },
    stores_by_company: (company_id, latitude, longitude, name, page) => {
      return `/stores_by_company/${company_id}?latitude=${latitude}&longitude=${longitude}&name=${name}&page=${page}`
    },
    received: (order_id) => {
      return `/orders/${order_id}/received`
    },
    cancel: (order_id) => {
      return `/orders/${order_id}/cancel`
    },
    express: (order_id) => `/orders/${order_id}/express`,
    expressList: (order_id) => `/orders/${order_id}/express/list`,
  },
  banner: {
    index: (company_id) => {
      return `/banners?company_id=${company_id}`
    },
    detail: (id) => {
      return `/banners/${id}`
    },
  },
  wechat: {
    jssdk_config: (url) => {
      return `/jssdk_config?path=${encodeURIComponent(url)}`
    },
  },
  card: {
    cards: `/cards`,
    detail: (id, query) => {
      if (typeof query != 'undefined' && query.from === 'admin') {
        return `/cards/${id}?from=${query.from}`
      }
      return `/cards/${id}`
    },
  },
  express_fare: {
    getByCompany: (company_id) => {
      return `/express_fares?company_id=${company_id}`
    },
  },
  activity: {
    list: (id) => {
      return `/activity/time_limit_discounts?company_id=${id}`
    },
    detail: (activityId, category_id) => {
      return category_id
        ? `/activity/time_limit_discounts/${activityId}?category_id=${category_id}`
        : `/activity/time_limit_discounts/${activityId}`
    },
  },
  distribution: {
    bind: (sharer_wechat_id) => {
      return `/sharer_customers/${sharer_wechat_id}`
    },
  },
  mini_program: {
    minicode_unlimit: `/minicode_unlimit`,
  },
}
