import Vue from 'vue'
import Vant from 'vant'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import VueClipboard from 'vue-clipboard2'
import 'vant/lib/index.css'
import { Lazyload } from 'vant'

Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']

Vue.use(Vant)
Vue.use(Lazyload)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 3,
})
Vue.use(VueClipboard)
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
