import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import beforeEach from './beforeEach'
import afterEach from './afterEach'

const router = new VueRouter({
  routes,
})

Vue.use(VueRouter)

router.beforeEach(beforeEach)

router.afterEach(afterEach)

export default router
