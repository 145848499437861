import { setShareUserId } from '@/services/share'
import redirect_count from '@/services/redirect_count'

let firstLoad = true

const afterEach = (to) => {
  if (firstLoad) {
    firstLoad = false
    const shareUserId = to.query.from_user || null
    setShareUserId(shareUserId, document.URL)
  }

  redirect_count.reset()
}

export default afterEach
