<template>
  <div id="cart_box">
    <van-nav-bar v-if="isMiniProgram" title="购物车" />
    <van-nav-bar v-else title="购物车" left-arrow @click-left="onClickLeft" />

    <div id="cart">
      <div id="cart-empty" v-if="items.length == 0">
        <img src="../assets/cart_empty.png" alt="购物车" id="cart-empty-image" srcset />
        <h4>购物车是空的</h4>
      </div>
      <van-pull-refresh v-model="refreshLoading" @refresh="onRefresh">
        <div id="cart-container" v-if="items.length > 0">
          <div class="cart-shop" v-for="(itemObj, index) in itemCollections" v-bind:key="index">
            <div v-if="itemObj.data.length > 1" class="cart-repo">
              <van-checkbox
                @click="toggleCheckbox(index, itemObj)"
                checked-color="#ee0a24"
                v-model="itemObj.checkbox.is_selected"
                :disabled="itemObj.checkbox.disabled"
                class="cart-repo-checkbox"
              ></van-checkbox>
              <div class="cart-repo-name">
                <h2 v-if="index == 'companyProducts'">门店现货</h2>
                <h2 v-else>{{ itemObj.name }}发货</h2>
              </div>
            </div>
            <div class="cart-item" v-for="item in itemObj.data" v-bind:key="item.id">
              <van-swipe-cell>
                <div class="product">
                  <van-checkbox
                    @click="toggleSelect(index)"
                    class="product_checkbox"
                    checked-color="#ee0a24"
                    v-model="item.is_selected"
                    :disabled="item.disabled"
                  ></van-checkbox>
                  <img
                    @click="goGoodDetail(item)"
                    :src="item.productSku.product.image_url"
                    :alt="item.productSku.product.title"
                    class="product_image"
                  />
                  <div @click="goGoodDetail(item)" class="product_row_right">
                    <h3>{{ item.productSku.product.title }}</h3>
                    <h4>{{ item.productSku.title }}</h4>
                    <h2>¥ {{ item.productSku.price }}</h2>
                  </div>
                  <div class="cart-tips">{{ cardTips(item) }}</div>
                  <van-stepper class="stepper" :min="item.productSku.min_purchase || 1" v-model="item.amount" />
                </div>
                <template slot="right">
                  <van-button square @click="removeCart(item.productSku.id, index)" type="danger" text="删除" />
                </template>
              </van-swipe-cell>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>

    <van-submit-bar
      :style="tabShow ? 'bottom:50px' : 'bottom:0'"
      :price="total"
      button-text="去结算"
      @submit="onSubmit"
    >
      <van-checkbox @click="toggleSelectAll" checked-color="#ee0a24" v-model="isSelectAll">全选</van-checkbox>
    </van-submit-bar>

    <Footer active="cart" :company_id="parseInt(company_id)" />
  </div>
</template>
<script>
import { fetchGoods, removeCart, updateCart } from '@/services/cart'
import { toastHttpUnKnownError } from '@/utils/errors'
import { createTime } from '@/utils/convert'
import { Toast } from 'vant'
import Footer from './Footer'
import wechat from '@/utils/wechat'
export default {
  data() {
    return {
      company_id: null,
      isSelectAll: false,
      items: [],
      itemCollections: {
        companyProducts: {
          data: [],
          checkbox: {
            disabled: false,
            is_selected: false,
          },
        },
      },
      amounts: [],
      tabShow: true,
      isMiniProgram: false,
      refreshLoading: false,
    }
  },
  components: {
    Footer,
  },
  mounted: function () {
    this.company_id = this.$route.query.company_id
    if (this.company_id == null) {
      this.$router.push('/errors/404')
      return
    }
    if (typeof wx != 'undefined') {
      wx.miniProgram.getEnv((res) => {
        this.tabShow = !res.miniprogram
      })
    }
    this.fetchGoods()
    wechat.checkIsMiniApp().then(
      (res) => (this.isMiniProgram = res),
      (rej) => ''
    )
  },
  computed: {
    selectedProductIds: function () {
      return this.items
        .filter((item) => {
          return item.is_selected
        })
        .map((item) => {
          return item.productSku.product.id
        })
    },
    selectedSkuIds: function () {
      return this.items
        .filter((item) => {
          return item.is_selected
        })
        .map((item) => {
          return item.productSku.id
        })
    },
    selectedSkuAmounts: function () {
      return this.items
        .filter((item) => {
          return item.is_selected
        })
        .map((item) => {
          return item.amount
        })
    },
    total: function () {
      return (
        this.items
          .filter((item) => {
            return item.is_selected
          })
          .map((map) => {
            return {
              value: map.productSku.price,
              amount: map.amount,
            }
          })
          .reduce((total, item) => {
            return total + item.value * item.amount
          }, 0) * 100
      )
    },
    containsBoth: function () {
      let selectedSuppliers = []

      selectedSuppliers = this.items
        .filter((item) => {
          return item.is_selected
        })
        .map((item) => {
          return item.productSku.supplier
        })

      return selectedSuppliers.every((item) => {
        // 以第一个为基准，比较每个元素的 supplier 是否相同
        if (!selectedSuppliers[0]) {
          return !item
        } else {
          return selectedSuppliers[0].id == item.id
        }
      })
        ? false
        : true
    },
  },
  watch: {
    selectedSkuAmounts: function (newAmounts, oldAmounts) {
      // 没有选择元素的时候清除所有的禁用
      if (newAmounts.length == 0) {
        this.items.forEach((item) => {
          item.disabled = false
        })
        Object.keys(this.itemCollections).forEach((item) => {
          this.itemCollections[item].checkbox.disabled = false
        })
      }
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onSubmit() {
      if (this.selectedProductIds.length == 0) {
        return Toast('至少选择\n一件商品')
      }

      if (this.containsBoth) {
        return Toast('不能同时选择商家的商品和供应商的商品')
      }

      this.$router.push({
        path: `/order/create?company_id=${this.company_id}`,
        query: {
          product_ids: this.selectedProductIds,
          sku_ids: this.selectedSkuIds,
          amount: this.selectedSkuAmounts,
        },
      })
    },
    fetchGoods() {
      fetchGoods(this.company_id)
        .then((resp) => {
          this.refreshLoading = false
          let items = resp.data
            .filter(({ productSku, amount }, i) => {
              // 限购判断是否更新
              const { max_purchase, purchased, id, limit_type } = productSku
              if (limit_type !== 'none') {
                const newAmounts = max_purchase - purchased
                const isDelete = max_purchase && newAmounts == 0
                const isUpdate = max_purchase && newAmounts > 0 && amount > newAmounts
                if (isDelete) {
                  removeCart(id)
                }
                if (isUpdate) {
                  updateCart(id, newAmounts)
                  resp.data[i].amount = newAmounts
                }
                return !isDelete
              }
              return true
            })
            .map((item) => {
              item.is_selected = false
              item.productSku.price =
                item.productSku.time_limit_discount &&
                item.productSku.time_limit_discount.stock > 0 &&
                createTime(item.productSku.time_limit_discount.time_limit_discount_sku.ended_at) > createTime() &&
                createTime() > createTime(item.productSku.time_limit_discount.time_limit_discount_sku.started_at)
                  ? item.productSku.time_limit_discount.price
                  : item.productSku.price
              this.amounts.push(item.amount)
              return item
            })
          this.items = items
          // 拼装数据结构
          let supplierProducts = [],
            supplierName
          this.itemCollections.companyProducts.data = []
          items.forEach((item) => {
            if (item.productSku.supplier) {
              supplierProducts.push(item)
            } else {
              this.itemCollections.companyProducts.data.push(item)
            }
          })

          supplierProducts.forEach((item) => {
            supplierName = item.productSku.supplier.name

            this.$set(this.itemCollections, supplierName, {
              name: item.productSku.supplier.name_zh,
              data: supplierProducts.filter((item) => {
                return item.productSku.supplier.name == supplierName
              }),
            })

            this.$set(this.itemCollections[supplierName], 'checkbox', {
              disabled: false,
              is_selected: false,
            })
          })
        })
        .catch((error) => {
          this.refreshLoading = false
          Toast('请登录查看～')
        })
    },
    goGoodDetail({
      productSku: {
        time_limit_discount,
        product: { id },
      },
    }) {
      // time_limit_discount.stock库存
      this.$router.push(
        `/goods/${id}?company_id=${this.company_id}&isLimit=${
          time_limit_discount &&
          time_limit_discount.stock > 0 &&
          createTime(time_limit_discount.time_limit_discount_sku.ended_at) > createTime() &&
          createTime() > createTime(time_limit_discount.time_limit_discount_sku.started_at)
            ? 1
            : 0
        }`
      )
    },
    toggleSelectAll() {
      this.items.forEach((item) => {
        item.is_selected = !this.isSelectAll
      })
      Object.keys(this.itemCollections).forEach((item) => {
        this.itemCollections[item].checkbox.is_selected = !this.isSelectAll
      })
    },
    toggleCheckbox(index, itemObj) {
      this.itemCollections[index].data.forEach((item) => {
        item.is_selected = !itemObj.checkbox.is_selected
      })
      this.toggleSelect(index)
    },
    toggleSelect(index) {
      // 不能同时选择多个 checkbox group 内的商品
      // 如果选择了一个 checkbox group 内的商品 禁用其他 checkbox group
      Object.keys(this.itemCollections)
        .filter((_index, _item) => {
          return index != _index
        })
        .forEach((_item) => {
          let item = this.itemCollections[_item]
          item.checkbox.disabled = true
          item.checkbox.is_selected = false
          item.data.forEach((_item) => {
            _item.disabled = true
            _item.is_selected = false
          })
        })
    },
    removeCart(sku_id, index) {
      removeCart(sku_id)
        .then(() => {
          let key = this.itemCollections[index].data.findIndex((item) => item.productSku.id == sku_id)
          this.itemCollections[index].data.splice(key, 1)

          if (this.itemCollections[index].data.length == 0) {
            this.$delete(this.itemCollections, index)
          }

          if (Object.keys(this.itemCollections).length == 0) {
            this.items = []
          }
        })
        .catch((error) => {
          toastHttpUnKnownError(error)
        })
    },
    cardTips: function (item) {
      let text = ''
      const { max_purchase, min_purchase, purchased, limit_type } = item.productSku
      if (limit_type !== 'none') {
        if (max_purchase) {
          text = `限购${max_purchase - purchased}件`
        }
        if (min_purchase > 1) {
          text = `${min_purchase}件起购`
        }
      }
      return text
    },
    onRefresh() {
      this.fetchGoods()
    },
  },
  beforeDestroy: function () {
    this.items.forEach((c, i) => {
      if (c.amount !== this.amounts[i]) {
        updateCart(c.productSku.id, c.amount)
      }
    })
  },
}
</script>
<style scoped>
h2,
h3,
h4,
text {
  font-family: 'PingFang SC', 'Courier New', Courier, monospace;
}
#cart_box {
  height: 100%;
}
#cart {
  margin-top: 10px;
  height: calc(100% - 146px);
}

#cart-empty {
  margin-top: 100px;
}

#cart-empty h4 {
  text-align: center;
  color: #888888;
  font-size: 12px;
  font-weight: 400;
}

#cart-empty-image {
  width: 103px;
  height: 89px;
  display: block;
  margin: 0 auto;
}

#cart-container {
  width: 95%;
  height: 100%;
  display: block;
  margin: 0 auto;
  overflow-y: auto;
}
.cart-tips {
  color: #ff0000;
  font-size: 10px;
  position: absolute;
  right: 100px;
  bottom: 30px;
}

.cart-repo {
  height: 44pt;
}

.cart-repo-checkbox {
  float: left;
  position: relative;
  height: 44pt;
  margin-right: 10px;
}

.cart-repo-name {
  float: left;
  display: block;
}

.cart-repo-name h2 {
  font-size: 17px;
  color: #333333;
}

.product {
  height: 90pt;
  background-color: #fff;
}

.product_image {
  height: 70pt;
  width: 70pt;
  float: left;
}

.product_checkbox {
  float: left;
  position: relative;
  top: 30pt;
  margin-right: 10pt;
}

.product_row_right {
  margin-left: 10px;
  float: left;
  width: calc(100% - 105pt);
}

.product h3 {
  font-size: 14px;
  color: #333333;
  margin: 20px 0 0 0;
}

.product h4 {
  font-size: 12px;
  color: #888888;
  margin: 0;
}

.product h2 {
  font-size: 14px;
  color: #ff0000;
  margin: 10px 0 0 0;
}

.stepper {
  clear: both;
  float: right;
  position: relative;
  top: -24px;
}

.van-swipe-cell__right .van-button {
  height: 120px;
}
</style>
