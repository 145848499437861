import http from '../utils/http'
import api from '../utils/api'

export function timeLimitList(companyId) {
  return http.get(api.activity.list(companyId))
}

export function timeLimitDetail(activity, category_id) {
  return http.get(api.activity.detail(activity, category_id))
}
