<template>
  <div class="refund_detail">
    <van-nav-bar title="退款" left-arrow @click-left="onClickLeft" />
    <div class="detail-status">
      <img src="../../assets/dengdai.png" />
      <span>{{ refund_status }}</span>
      <span v-if="item.refund_status == 'success'" class="end_time">
        {{ item.refund_ended_at }}
      </span>
    </div>
    <div class="item-msg">
      <img :src="item.product_snap.image_url" />
      <div class="msg_detail">
        <div class="msg-name">{{ item.product_snap.title }}</div>
        <div class="msg-pay">
          <span class="pay-real-pay">￥{{ item.price }}</span>
          <span class="pay-num">×{{ item.amount }}</span>
        </div>
      </div>
    </div>
    <div v-if="needexpress_no" class="express_no">
      <span>物流单号：</span>
      <van-field style="padding-left: 0; flex: 1" v-model="express_no" placeholder="必填" />
    </div>
    <div class="refund_msg">
      <div class="msg_title">退款信息</div>
      <div>退款说明：{{ item.refund_extra.refund_reason }}</div>
      <div>退款金额：￥{{ item.refund_extra.refund_amount }}</div>
      <div>积分返还：{{ item.refund_extra.refund_points }}</div>
      <div>申请件数：{{ item.refund_extra.refund_number }}</div>
      <div>申请时间：{{ item.refund_extra.refund_created_at }}</div>
      <div>退款编号：{{ item.refund_extra.no }}</div>
    </div>
    <van-button v-if="!needexpress_no" @click="goIndex" class="bottom_btn">返回首页</van-button>
    <van-button
      v-if="
        (item.refund_status == 'processing' || item.refund_status == 'processing_product_needed') &&
        item.refund_extra.refund_disagree_reason !== ''
      "
      @click="refundAgain()"
      class="bottom_btn"
      >重新申请退款</van-button
    >
    <van-button v-if="needexpress_no" @click="submit()" class="bottom_btn">确认</van-button>
  </div>
</template>
<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
<script>
import { getItemDetail, returnProduct } from '../../services/order'
import { Toast } from 'vant'

export default {
  data: function () {
    return {
      onlyApply: false,
      item: {
        refund_extra: '',
        product_snap: {},
      },
      express_no: '',
      refund_status: '',
      needexpress_no: false,
      company_id: '',
    }
  },
  created: function () {
    let { id } = this.$route.query
    this.id = id
    getItemDetail(id).then((detail) => {
      this.company_id = detail.data.product.company_id
      this.item = detail.data
      this.item.refund_extra = detail.data.refund_extra || {
        refund_reason: '',
        refund_amount: '',
        refund_number: '',
        refund_created_at: '',
        no: '',
      }

      const status = {
        applied: '等待商家确认',
        applied_product_returned: '等待商家确认',
        processing: '等待商家确认',
        processing_product_needed: '商家已同意请补充快递单号',
        success: '退款成功',
      }

      this.refund_status = !this.item.refund_extra.refund_disagree_reason
        ? status[this.item.refund_status]
        : '商家拒绝退款，请主动联系商家'
      this.needexpress_no =
        this.item.refund_status == 'processing_product_needed' && !this.item.refund_extra.refund_disagree_reason
          ? true
          : false
    })
  },
  mounted: function () {},
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    goIndex() {
      wx.miniProgram.switchTab({
        url: `/pages/customer/shop/index`,
        fail: function (erro) {
          alert(erro)
        },
      })
    },
    submit() {
      if (this.express_no == '') {
        Toast('请输入物流单号')
      } else {
        returnProduct(this.id, this.express_no).then(() => {
          Toast('提交成功')
          this.refund_status = '等待商家确认'
          this.needexpress_no = false
        })
      }
    },
    refundAgain() {
      this.$router.push({
        path: '/order/refund',
        query: {
          id: id,
        },
      })
    },
  },
}
</script>
<style scoped>
.refund_detail {
  width: 100%;
  height: 100%;
  background: #f5f5f9;
}
.detail-status {
  height: 70px;
  font-size: 18px;
  color: #fff;
  background: linear-gradient(360deg, rgba(252, 137, 112, 1) 0%, rgba(252, 87, 102, 1) 100%);
  display: flex;
  align-items: center;
}

.detail-status > .hips {
  font-size: 12px;
  padding-left: 15px;
}

.detail-status > img {
  width: 22px;
  height: 22px;
  margin-left: 8px;
  margin-right: 8px;
}
.item-msg {
  display: flex;
  padding: 15px;
  margin-bottom: 15px;
  background: #ffffff;
}
.item-msg:last-child {
  border-bottom: none;
}

.item-msg > img {
  width: 80px;
  height: 80px;
}

.item-msg > div {
  color: #000;
  flex: 1;
  margin-left: 10px;
}
.item-msg .msg-name {
  font-size: 13px;
  margin-bottom: 24px;
}

.item-msg .msg-pay {
  display: flex;
  justify-content: space-between;
}

.item-msg .msg-pay .pay-real-pay {
  font-size: 15px;
}

.item-msg .msg-pay .pay-num {
  font-size: 12px;
  color: #888;
}
.express_no {
  color: #2b2b2b;
  font-size: 14px;
  margin-bottom: 15px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.bottom_btn {
  color: #666;
  font-size: 15px;
  width: 90%;
  margin-left: 5%;
  border-radius: 3px;
  margin-bottom: 15px;
  border: 1px solid rgba(178, 178, 178, 1);
}
.refund_msg {
  padding: 10px;
  background: #fff;
  margin-bottom: 15px;
  color: #999;
  font-size: 12px;
}
.refund_msg > div {
  margin-bottom: 10px;
}
.refund_msg > .msg_title {
  color: #333333;
  font-size: 15px;
  margin-bottom: 15px;
}
.end_time {
  font-size: 12px;
  margin-left: 12px;
}
</style>
