import { getAccessToken, redirectToWeixinMiniProgramLogin } from '../services/auth'
import errors from '../utils/errors'
const access_token = getAccessToken()
const BASE_URL = process.env.VUE_APP_BASE_URL

import axios from 'axios'
import qs from 'qs'
import { getCompanyIdFromString } from './convert'
import router from '../router'

class http {
  constructor(baseURL = null) {
    let service = axios.create({
      headers: { Authorization: `Bearer ${access_token}` },
      baseURL: baseURL ? baseURL : BASE_URL,
    })
    service.interceptors.response.use(this.handleSuccess, this.handleError)
    this.service = service
  }

  refreshConfig(replace = {}) {
    return this.service.interceptors.request.use((config) => {
      if (replace.headers) {
        Object.assign(config.headers, replace.headers)
      }

      return config
    })
  }

  handleSuccess(response) {
    return response
  }

  handleError = (error) => {
    let company_id = getCompanyIdFromString(window.location)
    if (!company_id) {
      console.error(`company_id 缺失 URL: ${window.location}`)
    }
    switch (error.response.status) {
      case 401:
        // 以下页面不跳转
        // 首页
        // 全部商品页
        // 购物车页
        // 商品详情
        if (['home', 'goodList', 'cart', 'goodDetail'].indexOf(router.currentRoute.name) == -1) {
          redirectToWeixinMiniProgramLogin(false, company_id)
        }
        break
      case 400:
        if (error.response.data.code) {
          switch (error.response.data.code) {
            case errors.bindPhoneNeeded:
              if (['home', 'goodList', 'cart', 'goodDetail'].indexOf(router.currentRoute.name) == -1) {
                redirectToWeixinMiniProgramLogin(true, company_id)
              }
              break
          }
        }
        break
      //   case 401:
      //     this.redirectTo(document, '/')
      //     break;
      //   case 404:
      //     this.redirectTo(document, '/404')
      //     break;
      //   default:
      //     this.redirectTo(document, '/500')
      //     break;
    }
    return Promise.reject(error)
  }

  redirectTo = (document, path) => {
    document.location = path
  }

  get(path) {
    return this.service.get(path)
    // return this.service.get(path).then(
    //   (response) => callback(response.status, response.data)
    // );
  }

  patch(path, payload) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload,
    })
  }

  post(path, payload, type = 'json') {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: type,
      data: payload,
    })
  }

  delete(path, payload) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
      data: payload,
    })
  }

  formPost(path, payload) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: qs.stringify(payload),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  }

  reload() {
    let service = axios.create({
      headers: { Authorization: `Bearer ${getAccessToken()}` },
      baseURL: BASE_URL,
    })
    service.interceptors.response.use(this.handleSuccess, this.handleError)
    this.service = service
  }
}

export default new http()
