import http from '../utils/http'
import api from '../utils/api'

export function fetchMyCoupons(id) {
  return http.get(api.coupon.my(id))
}

export function fetchCompanyCoupons(id) {
  return http.get(api.coupon.getByCompany(id))
}

export function couponDetail(code) {
  return http.get(api.coupon.detail(code))
}

/**
 * 领取优惠券
 *
 * @param {coupon_id} id
 */
export function receiveCoupon(id) {
  return http.post(api.coupon.receive(id), {})
}

export function autoReceiveCoupon() {
  return http.post(api.coupon.autoReceive())
}
