import http from '../utils/http'
import api from '../utils/api'

export function getOrders(order_status, id, page) {
  return http.get(api.order.create(id, order_status, page))
}

export function getOrderDetail(order_id) {
  return http.get(api.order.detail(order_id))
}
export function createOrder(id, item) {
  return http.post(api.order.create(id), item)
}

export function getItemDetail(id) {
  return http.get(api.order.item_detail(id))
}

export function wechatPay(order_id) {
  return http.post(api.order.wechatPay(order_id), {
    pay_way: 'mini_program',
  })
}

export function payWithoutMoney(order_id) {
  return http.post(api.order.payWithoutMoney(order_id))
}

export function applyRefund(id, hasProduct, refund_reason, refund_number, proof_images) {
  let form = new FormData()
  proof_images.forEach((c) => {
    form.append('proof_images[]', c.file)
  })
  form.append('return_product_needed', hasProduct)
  form.append('refund_reason', refund_reason)
  form.append('refund_number', refund_number)

  return http.post(api.order.apply_refund(id), form)
}

export function returnProduct(id, no) {
  return http.patch(api.order.return_product(id), {
    refund_ship_express_no: no,
  })
}

export function getOrderNumbers(id) {
  return http.get(api.order.order_numbers(id))
}

export function getStores(id, latitude, longitude, name, page) {
  return http.get(api.order.stores_by_company(id, latitude, longitude, name, page))
}

export function received(id) {
  return http.patch(api.order.received(id))
}

export function cancel(id) {
  return http.patch(api.order.cancel(id))
}

export function express(id) {
  return http.get(api.order.express(id))
}

export function expressList(id) {
  return http.get(api.order.expressList(id))
}
