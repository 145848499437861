<template>
  <div id="good-detail">
    <van-overlay :z-index="2" :show="shareLayer.show">
      <div class="transparent-btn" @click="shareLayer.show = false" id="shareLayer-close">
        <img style="height: 32px" src="@/assets/close1.png" />
      </div>
      <canvas :height="shareLayer.height" :width="shareLayer.width" id="shareImage"></canvas>
      <img id="shareImageDisplay" :height="shareLayer.height" :width="shareLayer.width" :src="shareLayer.data" />
      <span class="usage">长按图片保存到手机</span>
    </van-overlay>

    <div class="transparent-btn" @click="navBack" id="btn-back">
      <img src="@/assets/back.png" />
    </div>

    <div class="transparent-btn" @click="share" id="btn-forward">
      <img src="@/assets/share.png" />
    </div>

    <div id="banner" @click="showImagePreview = true">
      <van-swipe :height="bodyWidth">
        <van-swipe-item v-for="(image, index) in item.image" :key="index">
          <img
            v-if="index == 0 || index == item.image.length - 1"
            :src="image + '?x-oss-process=image/resize,w_' + bodyWidth * 2 + ',h_' + bodyWidth * 2 + ''"
          />
          <img v-else v-lazy="image + '?x-oss-process=image/resize,w_' + bodyWidth * 2 + ',h_' + bodyWidth * 2 + ''" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 限时折扣 -->
    <div id="discount" v-if="isLimit">
      <div class="price_msg">
        <span class="discount_price">
          ¥
          <span>{{ item.time_limit_discount.price }}</span>
        </span>
        <div class="original_price">
          <span>原价</span>
          <s>¥{{ item.price }}</s>
        </div>
      </div>
      <div class="time_msg">
        <div class="time_title">{{ isDiscountBegin ? '距活动结束仅剩' : '距活动开始还有' }}</div>
        <div class="countdown">
          <div class="countdown_hour">{{ countdown.h }}</div>
          <span>:</span>
          <div class="countdown_min">{{ countdown.m }}</div>
          <span>:</span>
          <div class="countdown_sec">{{ countdown.s }}</div>
        </div>
      </div>
    </div>
    <van-skeleton v-if="isLoading" title avatar :row="10" />

    <!-- 测试按钮  -->
    <wx-open-launch-weapp class="openweapp" id="launch-btn" username="gh_a30a456889f9" :path="launchPath">
      <script type="text/wxtag-template">
        <style>.btn { height:34px;padding:0 12px;border:1px solid #dedede;border-radius:16px;margin:5px 0 5px 15px;background:linear-gradient(to right,#ff6034,#ee0a24);color: #fff;}</style>
        <button class="btn">前往小程序</button>
      </script>
    </wx-open-launch-weapp>
    <div v-if="!isLoading">
      <div id="brief" class="wrap">
        <h1>
          {{ item.title }}
          <span style="color: #ff373f; font-size: 14px">{{ purchasedText }}</span>
        </h1>
        <h2>{{ item.memo }}</h2>
        <h4 v-if="!isLimit" class="price">
          ¥ {{ selectedSku.price }}
          <span v-if="selectedSku.origin_price" class="original">
            原价：
            <s>￥{{ selectedSku.origin_price }}</s>
          </span>
        </h4>
      </div>

      <div class="gutter"></div>

      <div id="express_fare" class="row wrap" v-if="this.isCard == 0">
        <label class="left">运费</label>
        <label class="right">{{ expressFare.rule_detail }}</label>
      </div>
      <div v-on:click="onChangeSkuSelected" id="sku" class="row wrap">
        <label class="left">规格</label>
        <label class="right">{{ selectedSku.title }}，{{ amount }} 件</label>
        <i class="right-btn van-icon van-icon-arrow van-cell__right-icon"></i>
      </div>
      <div v-if="item.brand" v-on:click="onClickBrand" id="brand" class="row wrap">
        <label class="left">品牌</label>
        <label class="right">
          <img id="brand-logo" :src="item.brand.logo_url" :alt="item.brand.name_zh" />
          <span id="brand-name">{{ item.brand.name_zh }}</span>
          <i class="right-btn van-icon van-icon-arrow van-cell__right-icon"></i>
        </label>
      </div>
      <!-- 优惠券 -->
      <div v-if="coupons.length > 0" v-on:click="couponsClick" id="coupon" class="wrap">
        <label class="left">优惠</label>
        <div class="content">
          <div class="coupon-label" v-for="(item, index) in coupons" :key="index">{{ item.description }}</div>
        </div>
        <i class="right-btn van-icon van-icon-arrow van-cell__right-icon"></i>
      </div>
      <!-- 服务保障 -->
      <div id="ensure" v-on:click="ensureClick" class="row wrap">
        <label class="left">保障</label>
        <label class="right">
          <span>正品保证 · 门店自提 · 售后无忧</span>
          <i class="right-btn van-icon van-icon-arrow van-cell__right-icon"></i>
        </label>
      </div>
      <van-popup v-model="couponPopupShow" closeable round position="bottom" class="coupon_popup">
        <div class="title">优惠券</div>
        <div class="coupons_box">
          <div v-if="draw_coupon.length > 0" class="draw_coupons">
            <div class="coupon_title">待领取优惠券</div>
            <div class="coupon_content">
              <div class="coupon_item" v-for="(item, index) in draw_coupon" :key="index">
                <div class="coupon_price">
                  <div v-if="item.type == 'fixed'">{{ item.value }}元</div>
                  <div v-else>{{ item.value }}折</div>
                  <span>{{ item.description }}</span>
                </div>
                <div class="coupon_type">
                  <div>{{ item.name }}</div>
                  <span>{{ item.not_before }} - {{ item.not_after }}</span>
                </div>
                <div v-on:click="receiveCoupon(item)" class="coupon_btn_normal">立即领取</div>
              </div>
            </div>
          </div>
          <div v-if="own_coupon.length > 0" class="own_coupons">
            <div class="coupon_title">已领取优惠券</div>
            <div class="coupon_content">
              <div class="coupon_item" v-for="(item, index) in own_coupon" :key="index">
                <div class="coupon_price">
                  <div v-if="item.type == 'fixed'">{{ item.value }}元</div>
                  <div v-else>{{ item.value }}折</div>
                  <span>{{ item.description }}</span>
                </div>
                <div class="coupon_type">
                  <div>{{ item.name }}</div>
                  <span>{{ item.not_before }} - {{ item.not_after }}</span>
                </div>
                <div v-on:click="goToGoodCoupon(item)" class="coupon_btn_own">立即使用</div>
              </div>
            </div>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="ensurePopupShow" round position="bottom" class="ensure_popup">
        <div class="title">服务保障</div>
        <div class="ensure_box">
          <div v-for="(item, index) in ensureList" :key="index" class="ensure_item">
            <img src="@/assets/correct.png" alt="correct" />
            {{ item.title }}
            <div>{{ item.description }}</div>
          </div>
        </div>
        <van-button class="ensure_btn" type="danger" round @click="closeEnsurePopup">我知道了</van-button>
      </van-popup>
      <div class="gutter"></div>

      <div id="compositions" v-if="compositions.length > 0" class="row wrap">
        <label class="left">成分</label>
        <label class="right" id="composition-spans">
          <span v-for="(_composition, index) in compositions_display" :key="index">{{ _composition.title }}</span>
        </label>
        <label class="small" @click="goGoodsCompositions">全部 {{ compositions.length }} 种成分</label>
        <i @click="goGoodsCompositions" class="right-btn van-icon van-icon-arrow van-cell__right-icon"></i>
      </div>
      <div id="efficacy" class="row wrap" v-if="item.tag.solve_problems.length > 0">
        <label class="left">功效</label>
        <label class="right flex_label">
          <div v-for="(_efficacy, index) in item.tag.solve_problems" :key="index">{{ _efficacy }}</div>
        </label>
      </div>
      <div id="type" class="row wrap" v-if="type.length > 0">
        <label class="left">肤质</label>
        <label class="right flex_label">
          <div v-for="(_type, index) in type" :key="index">{{ _type }}</div>
        </label>
      </div>

      <div class="gutter" v-if="type.length > 0"></div>

      <!-- <div id="description" class="wrap">
        <h1>商品描述</h1>
        <span>{{ item.memo }}</span>
      </div>-->

      <div id="detail" class="wrap">
        <h1>商品详情</h1>
        <div id="detail-html" v-html="replaceAllImg(item.description)" v-lazy-container="{ selector: 'img' }"></div>
        <!-- <div id="detail-html" v-html="(item.description)"></div> -->
      </div>
    </div>

    <van-image-preview v-model="showImagePreview" :images="item.image" @change="onImageChange">
      <template v-slot:index>{{ currentImageIndex + 1 }} - {{ item.image.length }}</template>
    </van-image-preview>

    <van-goods-action>
      <van-goods-action-icon icon="wap-home-o" text="首页" @click="goIndex" />
      <van-goods-action-icon icon="cart-o" text="购物车" @click="goCart" />
      <van-goods-action-button
        :disabled="disabledBtn"
        :loading="addingCart"
        type="warning"
        text="加入购物车"
        @click="openSelect(true)"
      />
      <van-goods-action-button :disabled="disabledBtn" type="danger" text="立即购买" @click="openSelect(false)" />
    </van-goods-action>

    <van-sku
      v-model="showSkuModel"
      :sku="sku"
      :goods="skuGoodsInfo"
      :goods-id="item.id"
      :hide-stock="false"
      :quota="maxPurchase"
      :quota-used="purchased"
      :reset-stepper-on-hide="false"
      :reset-selected-sku-on-hide="false"
      :close-on-click-overlay="true"
      :disable-stepper-input="false"
      :show-add-cart-btn="isAllShow"
      :start-sale-num="minPurchase"
      @add-cart="chooseCart"
      @buy-clicked="chooseBuy"
      @sku-selected="chooseSku"
      :buy-text="isAllShow ? '立即购买' : '确定'"
      :custom-stepper-config="customStepperConfig"
    />
  </div>
</template>
<script>
import { goodDetail, getCompositions, getCardDetail, getCouponList } from '../../services/goods'
import { receiveCoupon } from '../../services/coupon'
import { addCart } from '../../services/cart'
import { toastHttpUnKnownError } from '../../utils/errors'
import { Toast } from 'vant'
import { backOrHome } from '../../services/navigator'
import { Encoder } from '@nuintun/qrcode'
import { checkAuthentication } from '../../services/auth'
import { getInfo } from '../../services/me'
import wechat from '../../utils/wechat'
import { getTimer, createTime } from '@/utils/convert'
import {
  shareFlag,
  shareSourceSkuFlag,
  getNewUserId,
  setShareUserId,
  getShareUserId,
  getNewSkuId,
  setShareSkuId,
} from '../../services/share'
import { bind } from '../../services/bind'
import { fetchCompanyExpressFare } from '../../services/express_fare'

const skeletonImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAACF0lEQVR4nO3TMRHAMBDAsCT86f7cEOh5bQcJgRfvmXkW8Op8HQB/ZhAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBYBAIBoFgEAgGgWAQCAaBcAH6TwVuJuIErgAAAABJRU5ErkJggg=='

export default {
  props: ['id'],
  data: function () {
    return {
      company_id: this.$route.query.company_id,
      isCard: 0,
      isLimit: 0,
      isCart: false,
      isAllShow: false,
      addingCart: false,
      item: {
        skus: [],
        title: '',
        description: '',
        price: '',
        image: [skeletonImage],
        brand: {},
        good: {},
        tag: {
          solve_problems: [],
        },
        time_limit_discount: {
          price: null,
        },
      },
      compositions: [],
      type: [],
      skuGoodsInfo: {
        title: '',
        picture: '',
      },
      showSkuModel: false,
      sku: {
        tree: [],
        list: [],
        price: '0.00',
        stock_num: 0,
        collection_id: 0,
        none_sku: false,
        messages: [],
        hide_stock: false,
      },
      compositions_display: [],
      selectedSku: {
        title: '',
      },
      selectedSkuId: 0,
      amount: 1,
      showImagePreview: false,
      currentImageIndex: 0,
      isLoading: true,
      bodyWidth: 0,
      shareLayer: {
        data: skeletonImage,
        width: 400,
        height: 540,
        show: false,
        user: {
          id: null,
          avatar: 'http://fx-bucket-test.oss-cn-shenzhen.aliyuncs.com/images/default_avatar%403x.png',
          nick_name: '未登录',
        },
        good: {
          height: 400,
          width: 400,
        },
        qrCode: {
          Y: 100,
          height: 200,
          width: 200,
        },
      },
      currentUser: {
        id: null,
      },
      coupons: [],
      couponPopupShow: false,
      expressFare: [],
      minPurchase: 0,
      maxPurchase: 0,
      purchased: 0,
      customStepperConfig: {},
      countdown: {},
      isDiscountBegin: true,
      ensurePopupShow: false,
      ensureList: [
        {
          title: '正品保证',
          description: '所有在售商品均由商家或供应商发货，正品保证',
        },
        {
          title: '门店自提',
          description: '支持门店自提，免运费',
        },
        {
          title: '售后无忧',
          description: '支持退货退款，购物无忧',
        },
      ],
      launchPath: `pages/customer/shop/products/detail/detail.html?`,
    }
  },
  mounted: function () {
    // 限时折扣
    const isLimit = this.$route.query.isLimit
    if (isLimit == 1) {
      this.isLimit = isLimit
    }
    if (checkAuthentication()) {
      this.requestUserInfo()
    }
    this.requestForGood()
    this.getCouponList()
    if (this.isCard == 0) {
      this.getCompanyExpressFare(this.company_id)
    }
    document.title = '商品详情'
    this.bodyWidth = document.body.scrollWidth
    this.shareLayer.width = document.body.scrollWidth * 0.8
    this.shareLayer.good.width = this.shareLayer.width * 0.6
    this.shareLayer.good.height = this.shareLayer.good.width
    this.shareLayer.height = this.shareLayer.good.height + 150
    this.shareLayer.qrCode.width = this.shareLayer.width * 0.4
    this.shareLayer.qrCode.height = this.shareLayer.qrCode.width
    this.shareLayer.qrCode.Y = 200 + this.shareLayer.good.height + 20
    this.shareLayer.height = this.shareLayer.qrCode.Y + this.shareLayer.qrCode.height + 20

    // 有分享者标识，则进行设置
    // let shareUserId = getNewUserId(document.URL);
    // let shareSkuId = getNewSkuId(document.URL);
    // if (shareUserId && shareSkuId) {
    //   setShareUserId(shareUserId, document.URL, shareSkuId);
    // }

    let shareUserId = getNewUserId(document.URL)
    if (shareUserId) {
      this.bind(shareUserId)
    }
  },
  methods: {
    navBack: function () {
      backOrHome(this.item.company_id)
    },
    goIndex: function () {
      this.$router.replace(`/?company_id=${this.item.company_id}`)
    },
    goCart: function () {
      this.$router.push(`/cart?company_id=${this.item.company_id}`)
    },
    sliceName: function (name) {
      return name.length > 20 ? `${name.slice(0, 20)}...` : name
    },
    share: function () {
      if (!checkAuthentication()) {
        this.requestUserInfo()
      }
      this.shareLayer.show = true
      let shareImage = document.querySelector('#shareImage')
      if (shareImage.getContext) {
        var ctx = shareImage.getContext('2d')
        // 获得并设置缩放比例，解决高清屏幕绘制图像模糊的问题.
        var getPixelRatio = function (context) {
          var backingStore =
            context.backingStorePixelRatio ||
            context.webkitBackingStorePixelRatio ||
            context.mozBackingStorePixelRatio ||
            context.msBackingStorePixelRatio ||
            context.oBackingStorePixelRatio ||
            context.backingStorePixelRatio ||
            1
          return (window.devicePixelRatio || 1) / backingStore
        }
        var ratio = getPixelRatio(ctx)
        shareImage.style.height = this.shareLayer.height + 'px'
        shareImage.style.width = this.shareLayer.width + 'px'
        shareImage.width = this.shareLayer.width * ratio
        shareImage.height = this.shareLayer.height * ratio

        ctx.scale(ratio, ratio)

        // 绘制背景圆角矩形
        let x = 0
        let y = 0
        let w = this.shareLayer.width
        let h = this.shareLayer.height
        let r = 15

        ctx.fillStyle = 'white'
        ctx.beginPath()
        ctx.moveTo(x + r, y)
        ctx.arcTo(x + w, y, x + w, y + h, r)
        ctx.arcTo(x + w, y + h, x, y + h, r)
        ctx.arcTo(x, y + h, x, y, r)
        ctx.arcTo(x, y, x + w, y, r)
        ctx.closePath()
        ctx.fill()

        let image = new Image()
        image.crossOrigin = 'Anonymous'
        image.src = this.shareLayer.user.avatar

        image.onload = () => {
          ctx.save()
          let r = 20
          let x = 20
          let y = 17
          var d = 2 * r
          var cx = x + r
          var cy = y + r
          ctx.beginPath()
          ctx.arc(cx, cy, r, 0, 2 * Math.PI)
          ctx.clip()
          ctx.drawImage(image, x, y, d, d)
          ctx.restore()
        }

        ctx.fillStyle = '#000'
        let nickname = this.sliceName(this.shareLayer.user.nick_name)

        ctx.font = '16px Arial '
        ctx.fillText(nickname, 70, 42)

        ctx.font = '13px Arial '
        ctx.fillText('发现一个好物，推荐给你呀', 20, 82)
        // QRCode
        const _qrcode = new Encoder()
        _qrcode.write(
          `${location.protocol}//${location.host + location.pathname}#/goods/${this.item.id}?${shareFlag}=${
            this.shareLayer.user.id
          }&${shareSourceSkuFlag}=${this.selectedSkuId}&isCard=${this.isCard}&isLimit=${this.isLimit}`
        )
        _qrcode.make()

        let qrCode = new Image()
        qrCode.crossOrigin = 'Anonymous'
        qrCode.src = _qrcode.toDataURL(5, 0)
        qrCode.onload = () => {
          ctx.drawImage(
            qrCode,
            20,
            this.shareLayer.qrCode.Y - 10,
            this.shareLayer.qrCode.width,
            this.shareLayer.qrCode.height
          )
        }

        // QrCode 右侧文字
        ctx.font = '14px Arial '
        ctx.fillText('长按识别二维码', 30 + this.shareLayer.qrCode.width + 10, this.shareLayer.qrCode.Y + 45)
        ctx.font = '12px Arial '
        ctx.fillStyle = '#999'
        ctx.fillText('超值好货等你来', 30 + this.shareLayer.qrCode.width + 10, this.shareLayer.qrCode.Y + 70)

        // 绘制商品图片
        let goodImage = new Image()
        goodImage.crossOrigin = 'Anonymous'
        goodImage.src = this.item.image[0].includes('https')
          ? this.item.image[0].replace('https://', 'http://')
          : this.item.image[0]
        console.log(this.item)
        goodImage.onload = () => {
          let x = (this.shareLayer.width - this.shareLayer.good.width) / 2
          let y = 108
          ctx.drawImage(goodImage, x, y, this.shareLayer.good.width, this.shareLayer.good.height)
          ctx.beginPath()
          ctx.rect(20, y - 12, this.bodyWidth * 0.72 - 5, this.bodyWidth * 0.72 - 5)
          ctx.strokeStyle = '#FF749D'
          ctx.stroke()
          ctx.fillStyle = '#000'
          ctx.font = '13px Arial'
          ctx.fillText(this.sliceName(this.item.title), 33, this.bodyWidth * 0.72 + 40)
          ctx.fillStyle = '#FF0000'
          ctx.font = '15px Arial'
          ctx.fillText(
            `¥ ${this.isLimit ? this.item.time_limit_discount.price : this.item.price}`,
            33,
            this.bodyWidth * 0.72 + 65
          )
          // 原价
          const createOriginPrice = (price, origin_price) => {
            ctx.fillStyle = '#999'
            ctx.font = '12px Arial'
            const origin_price_x = 33 + 20 + 25 + price.length * (price.length < 7 ? 4 : 5)
            ctx.fillText(`¥ ${origin_price}`, origin_price_x, this.bodyWidth * 0.72 + 65)
            // 删除线
            ctx.strokeStyle = '#999'
            ctx.beginPath()
            ctx.moveTo(origin_price_x, this.bodyWidth * 0.72 + 62)
            ctx.lineTo(origin_price_x + origin_price.length + 20 + 10, this.bodyWidth * 0.72 + 62)
            ctx.stroke()
            ctx.closePath()
          }
          const origin_price = this.item.skus[0].origin_price
          if (this.isLimit) {
            createOriginPrice(this.item.time_limit_discount.price, this.item.price)
          } else if (origin_price) {
            createOriginPrice(this.item.price, origin_price)
          }

          setTimeout(() => {
            this.shareLayer.data = shareImage.toDataURL('image/png')
          }, 100)
        }
      }
    },
    openSelect: function (isCart) {
      this.showSkuModel = true
      this.isCart = isCart
      this.isAllShow = false
    },
    addCart: function () {
      if (this.isCard == 1) {
        Toast('体验项目不能加入购物车')
      } else {
        this.addingCart = true
        addCart(this.selectedSkuId, this.amount)
          .then((resp) => {
            this.addingCart = false
            Toast(resp.data.message)
          })
          .catch((error) => {
            this.addingCart = false
            let message = []
            if (error.response.data.errors) {
              for (let [key, value] of Object.entries(error.response.data.errors)) {
                message.push(value)
              }
            } else if (error.response.data.message) {
              message.push(error.response.data.message)
            }
            Toast(message.join('\n'))
          })
      }
    },
    buy: function () {
      let sku = this.sku.list.filter((item) => {
        return this.selectedSkuId == item.id
      })[0]
      if (sku && sku.stock_num <= 0) {
        Toast('商品已售罄，无法购买。')
      } else {
        if (getShareUserId() !== 'null') {
          setShareSkuId(this.selectedSkuId)
        }
        this.$router.push({
          path: `/order/create?company_id=${this.item.company_id}&isCard=${this.isCard}`,
          query: {
            product_ids: this.item.id,
            sku_ids: this.selectedSkuId,
            amount: this.amount,
          },
        })
      }
    },
    onChangeSkuSelected: function () {
      this.showSkuModel = true
      this.isAllShow = true
      this.isCart = false
    },
    onSkuBuyClicked: function (data) {
      let selectedSkuId = data.selectedSkuComb.id
      let amount = data.selectedNum
      this.showSkuModel = false
      this.selectedSku = this.item.skus.filter((item) => {
        return item.id == selectedSkuId
      })[0]
      this.selectedSkuId = selectedSkuId
      this.amount = amount
      // 修改 SKU 以后需要重新调用 jsconfig 接口刷新 URL 参数
      this.configJSSDK()
    },
    chooseBuy: function (data) {
      this.onSkuBuyClicked(data)
      this.isCart ? this.addCart() : this.buy()
    },
    chooseCart: function (data) {
      this.onSkuBuyClicked(data)
      this.addCart()
    },
    chooseSku: function (data) {
      const { max_purchase, min_purchase, purchased, limit_type } = data.skuValue
      let quotaText = null
      if (max_purchase) {
        quotaText =
          purchased == 0 ? `限购${max_purchase}件` : `限购${max_purchase}件，还可加购${max_purchase - purchased}件`
        this.customStepperConfig.quotaText = quotaText
      } else {
        this.customStepperConfig = {}
      }
      this.purchased = purchased
      this.maxPurchase = max_purchase
      this.minPurchase = min_purchase
    },
    requestForGood: function () {
      this.isLoading = true
      this.isCard = this.$route.query.isCard
      // 跳转小程序地址
      console.log(this.launchPath)
      this.launchPath = this.launchPath + `id=${this.id}&isCard=${this.isCard}&isLimit=${this.isLimit}&from_user=null`
      if (this.isCard == 1) {
        getCardDetail(this.id, this.$route.query).then((res) => {
          this.getDetail(res)
          this.isLoading = false
        })
      } else {
        goodDetail(this.id, this.$route.query)
          .then((res) => {
            this.getDetail(res)
          })
          .catch((error) => {
            console.error(error)
            switch (error.response.status) {
              case 404:
                Toast('未找到商品')
                break
              case 422:
                Toast('商品未上架')
                break
              default:
                toastHttpUnKnownError(error)
                break
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    onImageChange: function (index) {
      this.currentImageIndex = index
    },

    onClickBrand: function () {
      this.$router.push(`/goods/brand/${this.item.brand.id}?company_id=${this.item.company_id}`)
    },

    goGoodsCompositions: function () {
      this.$router.push(`/goods/${this.item.id}/compositions`)
    },
    requestUserInfo: async function () {
      let resp = await getInfo()
      this.shareLayer.user = resp.data
      this.currentUser = resp.data // @TODO: fix redundant data.
    },
    configJSSDK: function () {
      if (typeof wx != 'undefined') {
        // @FIXME: change url every time sku has changed.
        let base = `${document.location.protocol}//${document.location.host}`
        let url = `${base}/#/goods/${this.item.id}?isCard=${this.isCard}&isLimit=${this.isLimit}`
        let shareUserId = this.currentUser.id
        if (shareUserId) {
          url = `${base}/#/goods/${this.item.id}?isCard=${this.isCard}&isLimit=${this.isLimit}&${shareFlag}=${shareUserId}`
        }
        wechat.configJSSDK(document.URL).then(() => {
          wx.ready(() => {
            wechat.configAppMessageShareData(this.item.title, this.item.memo, url, this.item.image_url)
            wechat.configTimelineShareData(this.item.title, url, this.item.image_url)
          })
        })
      }
    },
    getDetail: function (res) {
      let resp = res.data
      let item = resp
      item.tag = item.tag ? item.tag : { solve_problems: [] }
      this.item = item
      // 倒计时
      if (item.time_limit_discount) this.countTime(item)

      this.skuGoodsInfo = {
        title: resp.title,
        picture: resp.image_url,
      }

      this.selectedSkuId = resp.skus.length > 0 ? resp.skus[0].id : resp.id
      this.selectedSku = resp.skus.length > 0 ? resp.skus[0] : {}

      // 规格列表
      let skuTreeValue = resp.skus.map((item) => {
        return {
          id: item.id,
          name: item.title,
          imgUrl: resp.image_url,
          previewImgUrl: resp.image_url,
          min_purchase: item.min_purchase || 1,
          max_purchase: parseInt(item.max_purchase) || 0,
          purchased: parseInt(item.purchased) || 0,
          limit_type: item.limit_type,
        }
      })
      let skuList = resp.skus.map((item) => {
        // 限购大于等于已购，置灰
        const stock_num =
          item.limit_type !== 'none' ? (parseInt(item.max_purchase) == item.purchased ? 0 : item.stock) : item.stock
        return {
          id: item.id,
          price:
            this.isLimit == 1 && item.time_limit_discount ? item.time_limit_discount.price * 100 : item.price * 100,
          s1: item.id,
          stock_num: this.isLimit == 1 && item.time_limit_discount ? item.time_limit_discount.stock : stock_num,
        }
      })
      let stock_num = resp.skus
        .map((item) => {
          return item.stock
        })
        .reduce((a, b) => a + b, 0)
      let sku = {
        tree: [
          {
            k: '',
            v: skuTreeValue,
            k_s: 's1',
          },
        ],
        list: skuList,
        price: this.isLimit == 1 && resp.time_limit_discount ? resp.time_limit_discount.price : resp.price,
        stock_num: stock_num,
        collection_id: resp.id,
      }

      this.type = item.tag.face_types ? Object.values(item.tag.face_types) : []
      this.sku = sku

      this.configJSSDK()
      if (typeof wx != 'undefined') {
        wx.ready(() => {
          wechat.configAppMessageShareData(item.title, item.memo, document.URL, item.image_url)
          wechat.configTimelineShareData(item.title, document.URL, item.image_url)
        })
      }

      if (item.good_id) {
        getCompositions(item.id)
          .then((resp) => {
            let data = resp.data.data
            this.compositions = data
            let toDisplay = data.filter((_item, _, arr) => {
              return _item.active
            })

            this.compositions_display = toDisplay.length > 0 ? toDisplay.slice(0, 1) : data.slice(0, 1)
          })
          .catch((e) => {
            Toast('获取成分表失败')
          })
      }
    },
    getCouponList: function () {
      getCouponList(this.id).then((resp) => {
        this.coupons = resp.data.data
          .filter((item) => item.company_id == this.company_id && item.fe_position !== 'none')
          .map((item) => {
            let { not_after, not_before } = item
            item.not_after = not_after ? getTimer('YYYY.mm.dd', not_after) : ''
            item.not_before = not_before ? getTimer('YYYY.mm.dd', not_before) : ''
            return item
          })
      })
    },
    couponsClick: function () {
      this.couponPopupShow = true
    },
    ensureClick: function () {
      this.ensurePopupShow = true
    },
    closeEnsurePopup() {
      this.ensurePopupShow = false
    },
    receiveCoupon: function ({ id, limit, own }) {
      if (limit - own !== 0) {
        receiveCoupon(id).then((resp) => {
          Toast(resp.data.message)
          this.getCouponList()
        })
      }
    },
    goToGoodCoupon: function (item) {
      this.$router.push({
        path: `/goods/coupon?company_id=${this.item.company_id}`,
        query: { item: JSON.stringify(item) },
      })
    },
    getCompanyExpressFare: function (company_id) {
      fetchCompanyExpressFare(company_id)
        .then((resp) => {
          const expressFare = (this.expressFare = resp.data)
          switch (expressFare.rule) {
            case 'all_same':
              this.expressFare.rule_detail = `同城¥ ${expressFare.fare_inside}，外地¥ ${expressFare.fare_outside}`
              break
            case 'limit_until':
              this.expressFare.rule_detail = `满${expressFare.free_until}包邮`
              break
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 404:
              this.expressFare.rule_detail = `免运费`
              break
          }
        })
    },
    replaceAllImg(html) {
      var newContent = html.replace(/<img[^>]*>/gi, function (str, capture) {
        // eslint-disable-next-line no-useless-escape
        var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
        var url = str.match(srcReg)
        // eslint-disable-next-line no-useless-escape
        var mat = str.replace(/src=\"(.*)\"/gi, "data-src='" + url[1] + "'")
        return mat
      })
      return newContent
    },
    countTime(msg) {
      const {
        time_limit_discount: { started_at, ended_at },
      } = msg
      const startTime = createTime(started_at)
      const endTime = createTime(ended_at)
      let interval = setInterval(() => {
        // 判断活动是否开始
        const now = createTime()
        let difference = 0
        if (endTime - now > 0) {
          this.isDiscountBegin = true
          difference = endTime - now
        }
        if (startTime - now > 0) {
          this.isDiscountBegin = false
          difference = startTime - now
        }
        if (difference) {
          const hour = Math.floor(difference / 1000 / 60 / 60)
          const minute = Math.floor((difference / 1000 / 60) % 60)
          const second = Math.floor((difference / 1000) % 60)
          const obj = {
            h: hour < 10 ? '0' + hour : hour,
            m: minute < 10 ? '0' + minute : minute,
            s: second < 10 ? '0' + second : second,
          }
          this.countdown = obj
        } else {
          const obj = {
            h: '00',
            m: '00',
            s: '00',
          }
          this.countdown = obj
          clearInterval(interval)
        }
      }, 1000)
    },
    async bind(shareUserId) {
      return await bind(shareUserId)
    },
  },
  computed: {
    own_coupon: function () {
      return this.coupons.filter(({ own, limit }) => limit - own == 0)
    },
    draw_coupon: function () {
      return this.coupons.filter(({ own, limit }) => limit - own !== 0)
    },
    purchasedText: function () {
      let text = ''
      for (let i = 0; i < this.item.skus.length; i++) {
        const thisItem = this.item.skus[i]
        if (thisItem.limit_type !== 'none') {
          if (thisItem.max_purchase) {
            text = this.item.skus.length > 1 ? `（限购）` : `（限购${thisItem.max_purchase}件）`
            continue
          }
        }
      }
      return text
    },
    disabledBtn: function () {
      return this.item.skus.length > 1
        ? false
        : parseInt(this.item.skus[0] ? this.item.skus[0].max_purchase : '') ==
          (this.item.skus[0] ? this.item.skus[0].purchased : null)
        ? true
        : false
    },
  },
}
</script>
<style scoped>
#banner img {
  width: 100%;
  height: 100%;
}
.wrap {
  width: 100%;
  padding: 0 15px;
  display: block;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
}

.transparent-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transparent-btn img {
  height: 16px;
}

#btn-back {
  left: 10px;
}

#btn-forward {
  right: 10px;
}
#brief {
  margin-top: 10px;
}

#brief h1 {
  font-size: 18px;
  color: #333;
}
#brief h2 {
  font-size: 14px;
  color: #666;
  font-weight: 400;
}

#brief .price {
  margin-top: 7px;
  margin-bottom: 15px;
  font-size: 24px;
  color: #ff373f;
}
#brief .original {
  color: #999999;
  font-weight: 400;
  font-size: 14px;
  margin-left: 15px;
}
/* 成分 */

#composition-spans {
  width: 60%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#compositions .small {
  color: #858585;
  font-size: 10px;
  float: right;
  position: absolute;
  right: 45px;
}

#compositions span::after {
  content: '、';
}

#compositions span:last-child::after {
  content: '等';
}

/* 功效、肤质 按钮 */
#efficacy span,
#type span {
  margin-right: 10px;
  padding: 6px 10px;
  font-size: 10px;
  border: solid 1px #ff7ca4;
  border-radius: 10px;
  color: #ff7ca4;
}

.gutter {
  background-color: #f5f5f9;
  height: 15px;
}

.row {
  height: 50px;
}

.row label {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
}

.row .left {
  color: #888888;
  margin-right: 15px;
  float: left;
}

.row .right {
  color: #333333;
}

.flex_label {
  display: flex;
  align-items: center;
  overflow: auto;
}
.flex_label > div {
  border: solid 1px #ff7ca4;
  border-radius: 17px;
  height: 20px;
  line-height: 20px;
  padding: 0px 10px;
  color: #ff7ca4;
  margin-right: 10px;
  word-break: keep-all;
  font-size: 12px;
}

#sku {
  border-top: 1px solid #e8e8e8;
}

#brand {
  border-top: 1px solid #e8e8e8;
}

#brand-logo {
  margin-top: 7.5px;
  height: 35px;
  float: left;
}

#brand-name {
  float: left;
  margin-left: 10px;
}

.right-btn {
  float: right;
  height: 50px;
  line-height: 50px;
}

#description {
  margin-bottom: 20px;
}

#description h1 {
  margin: 20px 0 10px 0;
  font-size: 12px;
  color: #333333;
}

#description span {
  color: #666666;
  font-size: 12px;
}

#detail {
  padding-bottom: 55px;
}

#detail h1 {
  margin: 20px 0 10px 0;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
}

#detail-html >>> img {
  width: 100%;
  height: 100%;
  margin-top: -5px;
}

.van-overlay {
  background: rgba(0, 0, 0, 0.6);
}

#shareImage {
  display: none;
}

#shareImageDisplay {
  display: block;
  margin: 60px auto 0;
}

#shareLayer-close {
  position: absolute;
  top: 20px;
  right: 5%;
}

.usage {
  display: block;
  text-align: center;
  color: #dcdcdc;
  font-size: 16px;
  margin-top: 10px;
}
/* 优惠券 */
#coupon {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-top: 1px solid #e8e8e8;
}
#coupon > .left {
  color: #888888;
  margin-right: 15px;
}
#coupon > .content {
  display: flex;
  flex: 1;
  overflow-x: auto;
}
.coupon-label {
  font-size: 12px;
  color: #ff4444;
  border-radius: 2px;
  border: 1px solid rgba(255, 68, 68, 1);
  padding: 0 10px;
  flex-shrink: 0;
  margin-right: 11px;
}
.coupon_popup {
  height: 70%;
  padding: 18px;
  box-sizing: border-box;
}
.coupon_popup > .title {
  font-size: 16px;
  color: #000000;
  text-align: center;
}
.coupons_box {
  height: calc(100% - 32px);
  overflow-y: auto;
}
.coupon_item {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  margin-top: 20px;
  border-radius: 5px;
  background: #fef4f4;
}
.coupon_title {
  color: #858585;
  font-size: 14px;
  margin-top: 15px;
}
.coupon_price {
  line-height: 22px;
  margin-right: 20px;
  text-align: center;
}
.coupon_price > div {
  color: #ff373f;
  font-size: 24px;
  font-weight: 500;
}
.coupon_price > span {
  color: #e02020;
  font-size: 10px;
}
.coupon_type {
  flex: 1;
}
.coupon_type > div {
  color: #000000;
  font-size: 14px;
  margin-bottom: 3px;
}
.coupon_type > span {
  color: #858585;
  font-size: 10px;
}
.coupon_btn_normal {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #fff;
  background: rgba(255, 55, 63, 1);
  border-radius: 12px;
  padding: 0 10px;
}
.coupon_btn_own {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #000;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 12px;
  padding: 0 10px;
}
/* 限时折扣 */
#discount {
  display: flex;
  align-items: center;
  min-height: 50px;
  height: 50px;
}
/* 限时折扣——左边价格栏 */
#discount > .price_msg {
  flex: 1;
  background: #ff373f;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
}
.price_msg > .discount_price {
  font-size: 20px;
  margin-right: 15px;
}
.price_msg > .discount_price > span {
  font-size: 36px;
}
.price_msg > .original_price {
  opacity: 0.6;
}
.price_msg > .original_price > span {
  display: block;
}
/* 限时折扣——右边时间栏 */
#discount > .time_msg {
  padding: 7px 14px;
  background: #ffe7f0;
  color: #ff373f;
  height: 100%;
  box-sizing: border-box;
  font-size: 11px;
}
#discount > .time_msg > .countdown {
  display: flex;
  font-size: 12px;
  color: #fff;
  justify-content: space-around;
  margin-top: 2px;
}
#discount > .time_msg > .countdown > span {
  color: #ff373f;
}
#discount > .time_msg > .countdown > div {
  padding: 1px;
  background: rgba(255, 55, 63, 1);
  border-radius: 2px;
  min-width: 17px;
  text-align: center;
}
/* 保障 */
#ensure {
  border-top: 1px solid #e8e8e8;
}
.ensure_popup {
  padding: 15px 18px;
  box-sizing: border-box;
  color: #333;
  font-size: 16px;
  text-align: center;
}
.ensure_popup > .title {
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid rgba(242, 242, 242, 1);
  text-align: left;
  margin-bottom: 15px;
}
.ensure_item {
  text-align: left;
  margin-bottom: 20px;
}
.ensure_item > img {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.ensure_item > div {
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  margin-top: 10px;
  margin-left: 18px;
}
.ensure_btn {
  width: 100%;
  margin: 60px 0 40px 0;
  font-size: 16px;
}
</style>
